





















import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppDialog from "@/components/common/AppDialog.vue";
import { PropType } from "vue";
import { Project, Comment } from "@/models/Project";

export default Vue.extend(ManagerMixin).extend({
  name: "UpdateCommentDialog",
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    comment: {
      type: Object as PropType<Comment>,
      required: true,
    },
  },
  components: {
    AppDialog,
    AppTextArea,
    AppCard,
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    setContent(): void {
      this.content = this.comment?.content || "";
    },
    async updateComment(): Promise<void> {
      if (this.project.id) {
        await ManagerClient.UpdateCommentCommand({
          projectId: this.project.id,
          commentId: this.comment.id,
          content: this.content,
        });
        this.$emit("commentChanged");
      }
    },
  },
});
