




























import Vue from "@/translate";
import { SelectData, SubSelectData } from "@/models/UI";
import { PropType } from "vue";
import { SelectCustomer } from "@/models/Customer";
import AppSelect from "@/components/common/AppSelect.vue";
import AppGrid from "@/components/common/AppGrid.vue";

export default Vue.extend({
  name: "AppCustomerSelect",
  components: { AppSelect, AppGrid },
  props: {
    errors: Object,
    id: Number,
    value: Number,
    selectCustomer: {
      type: Array as PropType<SelectCustomer[]>,
      default: [],
    },
    grid: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCustomerId: undefined as number | undefined,
    };
  },
  computed: {
    componentType(): string {
      return this.grid ? "AppGrid" : "div";
    },
    customerSelectItems(): SelectData[] {
      return this.selectCustomer.map((x) => {
        return {
          value: x.id,
          text: x.name,
        } as SelectData;
      });
    },
    allRepresentativeSelectItems(): SubSelectData[] {
      return this.selectCustomer.map((x) => {
        return {
          id: x.id,
          data: x.representatives.map((r) => {
            return {
              value: r.id,
              text: r.lastname + ", " + r.firstname,
            } as SelectData;
          }),
        } as SubSelectData;
      });
    },
    representativeSelectItems(): SelectData[] {
      const result = this.allRepresentativeSelectItems.filter(
        (x) => x.id === this.selectedCustomerId
      );
      if (result.length > 0) {
        return result[0].data;
      } else {
        return [];
      }
    },
  },
  methods: {
    findCustomer(representativeId: number): number | undefined {
      const result = this.selectCustomer.filter((c) =>
        c.representatives.some((r) => r.id === representativeId)
      );
      if (result.length > 0) {
        return result[0].id;
      } else {
        return undefined;
      }
    },
    selectRepresentativeId(event: unknown): void {
      this.$emit("selectedCustomerId", this.selectedCustomerId);
      this.$emit("input", event);
    },
  },
  watch: {
    selectCustomer: {
      immediate: true,
      handler() {
        this.selectedCustomerId = this.findCustomer(this.value);
      },
    },
    value: {
      immediate: true,
      handler(val, oldVal) {
        if (oldVal !== val && val !== undefined) {
          this.selectedCustomerId = this.findCustomer(this.value);
        }
      },
    },
    representativeSelectItems: {
      handler(val) {
        if (val !== undefined && val.length === 1) {
          this.selectRepresentativeId(val[0].value);
        }
      },
    },
  },
});
