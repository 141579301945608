import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Error from "@/views/Error.vue";
import Info from "@/views/Info.vue";
import Imprint from "@/views/Imprint.vue";
import DataProtection from "@/views/DataProtection.vue";

import WSettings from "@/views/worker/WSettings.vue";
import WJobArchive from "@/views/worker/WJobArchive.vue";
import WJobList from "@/views/worker/WJobList.vue";
import WorkerJobDetail from "@/views/worker/WJobDetail.vue";
import WorkerVacation from "@/views/worker/WVacation.vue";

import MSettings from "@/views/manager/MSettings.vue";
import MProjectList from "@/views/manager/MProjectList.vue";
import MReceiptCreate from "@/views/manager/MReceiptCreate.vue";
import MProjectCreate from "@/views/manager/MProjectCreate.vue";
import MProjectDetail from "@/views/manager/MProjectDetail.vue";
import MDisputeDetail from "@/views/manager/MDisputeDetail.vue";
import MEdiList from "@/views/manager/MEdiList.vue";
import MEdiFile from "@/views/manager/MEdiFile.vue";
import MReceiptList from "@/views/manager/MReceiptList.vue";
import MReceiptDetail from "@/views/manager/MReceiptDetail.vue";
import MInvoiceList from "@/views/manager/MInvoiceList.vue";
import MInvoiceDetail from "@/views/manager/MInvoiceDetail.vue";
import MInvoiceCreate from "@/views/manager/MInvoiceCreate.vue";
import MInvoiceProduction from "@/views/manager/MInvoiceProduction.vue";

import ASettings from "@/views/admin/ASettings.vue";
import ACustomerList from "@/views/admin/ACustomerList.vue";
import ACustomerCreate from "@/views/admin/ACustomerCreate.vue";
import ACustomerDetail from "@/views/admin/ACustomerDetail.vue";
import ARepresentativeDetail from "@/views/admin/ARepresentativeDetail.vue";
import ARepresentativeCreate from "@/views/admin/ARepresentativeCreate.vue";
import AAffiliationList from "@/views/admin/AAffiliationList.vue";
import AAffiliationDetail from "@/views/admin/AAffiliationDetail.vue";
import AEmailSettings from "@/views/admin/AEmailSettings.vue";

import { NavigationGuardNext, Route } from "vue-router/types/router";
import Store from "@/store";
import { CHANGE_COLOR_SCHEME, REFRESH } from "@/store/actions";
import { Role } from "@/models/User";
import { ColorSchema } from "@/store/types";
import jwt from "jsonwebtoken";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Login,
    async beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
      await refreshFromToken();
      next();
    },
  },
  {
    path: "/reset-pw",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/admin",
    name: "Admin",
    async beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
      await refreshFromToken();
      await Store.dispatch(CHANGE_COLOR_SCHEME, ColorSchema.ADMIN);
      next();
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
    children: [
      {
        path: "settings",
        name: "ASettings",
        component: ASettings,
      },
      {
        path: "customers",
        name: "ACustomerList",
        component: ACustomerList,
      },
      {
        path: "customers/:customerId",
        name: "ACustomerDetail",
        component: ACustomerDetail,
      },
      {
        path: "create-customer",
        name: "CustomerCreate",
        component: ACustomerCreate,
      },
      {
        path: "representative/:representativeId",
        name: "ARepresentativeDetail",
        component: ARepresentativeDetail,
      },
      {
        path: "create-representative/:customerId",
        name: "RepresentativeCreate",
        component: ARepresentativeCreate,
        props: true,
      },
      {
        path: "affiliations",
        name: "AAffiliationList",
        component: AAffiliationList,
      },
      {
        path: "affiliations/:affiliationId",
        name: "AAffiliationDetail",
        component: AAffiliationDetail,
      },
      {
        path: "email-settings",
        name: "EmailSettings",
        component: AEmailSettings,
      },
    ],
  },
  {
    path: "/manager",
    name: "Manager",
    async beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
      const refreshed = await refreshFromToken();
      if (refreshed) {
        await Store.dispatch(CHANGE_COLOR_SCHEME, ColorSchema.MANAGER);
        next();
      } else {
        console.log("Token not reused");
        next({ name: "Home", params: { redirectTo: to.fullPath } });
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "manager" */ "../views/Manager.vue"),
    children: [
      {
        path: "settings",
        name: "MSettings",
        component: MSettings,
      },
      {
        path: "projects",
        name: "MProjectList",
        component: MProjectList,
      },
      {
        path: "projects/:projectId",
        name: "MProjectDetail",
        component: MProjectDetail,
      },
      {
        path: "disputes/:disputeId",
        name: "MDisputeDetail",
        component: MDisputeDetail,
      },
      {
        path: "create-project",
        name: "MProjectCreate",
        component: MProjectCreate,
      },
      {
        path: "invoices",
        name: "MInvoiceList",
        component: MInvoiceList,
      },
      {
        path: "invoices/:invoiceId",
        name: "MInvoiceDetail",
        component: MInvoiceDetail,
      },
      {
        path: "create-invoice",
        name: "MInvoiceCreate",
        component: MInvoiceCreate,
      },
      {
        path: "bills",
        name: "MInvoiceProduction",
        component: MInvoiceProduction,
      },
      {
        path: "edi-list",
        name: "MEdiList",
        component: MEdiList,
      },
      {
        path: "edi-file",
        name: "MEdiFile",
        component: MEdiFile,
      },
      {
        path: "receipts",
        name: "MReceiptList",
        component: MReceiptList,
      },
      {
        path: "receipts/:receiptId",
        name: "MReceiptDetail",
        component: MReceiptDetail,
      },
      {
        path: "create-receipt/:affiliationId",
        name: "MReceiptCreate",
        component: MReceiptCreate,
      },
    ],
  },
  {
    path: "/worker",
    name: "Worker",
    async beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
      await refreshFromToken();
      await Store.dispatch(CHANGE_COLOR_SCHEME, ColorSchema.WORKER);
      next();
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "worker" */ "@/views/Worker.vue"),
    children: [
      {
        path: "settings",
        name: "WSettings",
        component: WSettings,
      },
      {
        path: "archive",
        name: "WorkerArchive",
        component: WJobArchive,
      },
      {
        path: "jobs",
        name: "WorkerJobs",
        component: WJobList,
      },
      {
        path: "jobs/:jobId",
        name: "WorkerJobDetail",
        component: WorkerJobDetail,
      },
      {
        path: "vacation",
        name: "WorkerVacation",
        component: WorkerVacation,
      },
    ],
  },
  {
    path: "/info",
    name: "Info",
    component: Info,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
  },
  {
    path: "/data-protection",
    name: "DataProtection",
    component: DataProtection,
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    name: "ErrorNoRoute",
    component: Error,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

export async function switchRouteFromRole(role: Role): Promise<void> {
  if (role.manager) {
    await router.push({ name: "MProjectList" });
  } else if (role.worker) {
    await router.push({ name: "WorkerJobs" });
  } else if (role.admin) {
    await router.push({ name: "Admin" });
  } else {
    await router.push({ name: "Error" });
  }
}

async function refreshFromToken(): Promise<boolean> {
  try {
    const token = window.localStorage.getItem("user-token");
    if (token && !isTokenExpired(token)) {
      console.log("Using found token for data init");
      await Store.dispatch(REFRESH, { token });
      return new Promise<boolean>((resolve) => {
        resolve(true);
      });
    } else {
      return new Promise<boolean>((resolve) => {
        resolve(false);
      });
    }
  } catch (e) {
    console.error("Error while refreshing token" + e);
    return new Promise<boolean>((resolve) => {
      resolve(false);
    });
  }
}

function isTokenExpired(token: string): boolean {
  const decoded = jwt.decode(token);
  if (decoded && typeof decoded === "object") {
    const now = new Date().getTime() / 1000;
    if (decoded.exp) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return now > decoded.exp;
    }
  }
  return false;
}
