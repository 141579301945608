







import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import ManagerClient from "@/client/manager";
import { DIRTY_DATA } from "@/store/actions";
import { DirtyData } from "@/store/types";
import { Dispute } from "@/models/Job";
import DisputeDetailsCard from "@/components/manager/DisputeDetailsCard.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MDisputeDetail",
  data() {
    return {
      loading: false,
      dispute: {} as Dispute,
    };
  },
  async mounted() {
    await this.loadDispute();
  },
  computed: {
    isDisputeLoaded(): boolean {
      return this.dispute?.id !== undefined;
    },
  },
  methods: {
    async loadDispute(): Promise<void> {
      this.loading = true;
      try {
        const disputeId = Number.parseInt(this.$route.params.disputeId);
        this.dispute = await ManagerClient.DisputeQuery(disputeId);
        await this.$store.dispatch(DIRTY_DATA, {
          base: "dispute",
          data: this.dispute,
        } as DirtyData);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "$route.params.disputeId": {
      handler: function (disputeId: number): void {
        if (this.dispute.id !== disputeId) {
          this.loadDispute();
        }
      },
      deep: true,
    },
  },
  components: { DisputeDetailsCard },
});
