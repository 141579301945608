import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AddProofreadJobDto = {
  projectId: number;
  jobId: number;
  useResult: boolean;
};

export const ENDPOINT = "/project/add-proofread-job";

export default async function AddProofreadJobCommand(dto: AddProofreadJobDto): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
