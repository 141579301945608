



















import Vue from "@/translate";
import ManagerProjectTable from "@/components/manager/ManagerProjectTable.vue";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import ProjectFilterCard from "@/components/manager/ProjectFilterCard.vue";
import { Filter } from "@/models/UI";

export default Vue.extend(ManagerMixin).extend({
  name: "MProjectList",
  data() {
    return {
      filters: [] as Filter[],
    };
  },
  components: {
    AppButton,
    AppCard,
    ManagerProjectTable,
    ProjectFilterCard,
  },
  methods: {
    filterChanged(event: Filter[]): void {
      this.filters = event;
    },
  },
});
