import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateEdiSettingsDto = {
  automaticImport: boolean;
};

export const ENDPOINT = "/edi/change";

export default async function UpdateEdiSettingsCommand(
  dto: UpdateEdiSettingsDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
