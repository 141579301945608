
























































































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import InvoiceMixin from "@/mixins/invoice";
import AppCard from "@/components/common/AppCard.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppJobStateSelect from "@/components/common/AppJobStateSelect.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppTimeSelect from "@/components/common/AppTimeSelect.vue";
import ManagerClient from "@/client/manager";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import { PropType } from "vue";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import DatesMixin from "@/mixins/dates";
import { Invoice } from "@/models/Invoice";
import { SHOW_ERROR } from "@/store/actions";
import AppDownloadButton from "@/components/common/AppDownloadButton.vue";
import { downloadInvoiceFile } from "@/client/client";
import AppSeparator from "@/components/common/AppSeparator.vue";
import AppActionSeparator from "@/components/common/AppActionSeparator.vue";

export default Vue.extend(ManagerMixin)
  .extend(InvoiceMixin)
  .extend(DatesMixin)
  .extend({
    name: "InvoiceDetailsCard",
    props: {
      invoice: {
        type: Object as PropType<Invoice>,
        default: () => {
          return {} as Invoice;
        },
      },
      dense: {
        type: Boolean,
        default: false,
      },
      externalLoading: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      AppActionSeparator,
      AppSeparator,
      AppDownloadButton,
      AppTextArea,
      AppButton,
      AppCard,
      AppGrid,
      AppDateSelect,
      AppTimeSelect,
      AppTextField,
      AppCustomerSelect,
      AppJobStateSelect,
      AppConfirmDialog,
    },
    data() {
      return {};
    },
    computed: {
      downloadUri(): string {
        return downloadInvoiceFile(this.invoice.id);
      },
    },
    methods: {
      async saveInvoice(): Promise<void> {
        try {
          await ManagerClient.UpdateInvoiceCommand({
            id: this.invoice?.id || 0,
            projectRef: this.invoice?.projectRef || "",
            managerRef: this.invoice?.managerRef || "",
            customerRef: this.invoice?.customerRef || "",
            note: this.invoice?.note || "",
          });
          this.$emit("invoiceChanged");
        } catch (e: any) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Bestellung nicht ändern"
          );
        }
      },
      async deleteInvoice(): Promise<void> {
        try {
          await ManagerClient.DeleteInvoiceCommand({
            invoiceId: this.invoice.id,
          });
          await this.$router.push({ name: "MInvoiceList" });
        } catch (e: any) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Bestellung nicht löschen"
          );
        }
      },
      async blockReceipt(): Promise<void> {
        try {
          await ManagerClient.BlockInvoiceCommand({
            invoiceId: this.invoice.id,
          });
          this.$emit("invoiceChanged");
        } catch (e: any) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Bestellung nicht sperren"
          );
        }
      },
      async unblockReceipt(): Promise<void> {
        try {
          await ManagerClient.UnblockInvoiceCommand({
            invoiceId: this.invoice.id,
          });
          this.$emit("invoiceChanged");
        } catch (e: any) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Bestellung nicht entsperren"
          );
        }
      },
      async finishReceipt(): Promise<void> {
        try {
          await ManagerClient.FinishInvoiceCommand({
            invoiceId: this.invoice.id,
          });
          this.$emit("invoiceChanged");
        } catch (e: any) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Bestellung nicht abschließen"
          );
        }
      },
      async confirmReceipt(): Promise<void> {
        try {
          await ManagerClient.ConfirmInvoiceCommand({
            invoiceId: this.invoice.id,
          });
          this.$emit("invoiceChanged");
        } catch (e: any) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Rechnung für Bestellung nicht erzeugen"
          );
        }
      },
    },
  });
