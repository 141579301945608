import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DuplicateJobDto = {
  projectId: number;
  jobId: number;
  targets: string[];
  useInput: boolean;
};

export const ENDPOINT = "/project/duplicate-job";

export default async function DuplicateJobCommand(dto: DuplicateJobDto): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return result.data.id;
}
