
























import Vue from "@/translate";
import { JobFiles } from "@/models/Job";
import AppButton from "@/components/common/AppButton.vue";
import ActionableMixin from "@/mixins/actionable";

export default Vue.extend(ActionableMixin).extend({
  name: "AppDropzone",
  props: ["label", "color", "mini"],
  components: { AppButton },
  data() {
    return {
      loading: false,
      files: {} as JobFiles,
      dragover: false,
    };
  },
  computed: {
    linkColor(): string {
      if (this.color !== undefined) {
        return this.color;
      } else {
        return "inherit";
      }
    },
    linkLabel(): string {
      if (this.mini) {
        return "";
      } else {
        if (this.label) {
          return this.$t(this.label) as string;
        } else {
          return this.$t("dropzone.link") as string;
        }
      }
    },
  },
  mounted() {
    const dropzone = this.$el;
    if (dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener("dragenter", (e) => {
        e.preventDefault();
        this.dragover = true;
      });
      dropzone.addEventListener("dragleave", (e) => {
        e.preventDefault();
        this.dragover = false;
      });
      dropzone.addEventListener("dragover", (e) => {
        e.preventDefault();
        this.dragover = true;
      });
      dropzone.addEventListener("drop", (e) => {
        e.preventDefault();
        const dragEvent = e as DragEvent;
        if (dragEvent.dataTransfer) {
          this.fileSelected(dragEvent.dataTransfer.files);
          this.dragover = false;
        }
      });
    }
  },
  destroyed() {
    const dropzone = this.$el;
    if (dropzone) {
      //dropzone.removeEventListener("dragenter");
      //dropzone.removeEventListener("dragleave");
      // :TODO: remove Listeners
      console.log("TODO: remove Listeners");
    }
  },
  methods: {
    selectFile(): void {
      const fileUpload = this.$el.firstElementChild as HTMLElement;
      if (fileUpload) {
        fileUpload.click();
      }
    },
    fileSelected(fileList: FileList): void {
      this.$emit("file-selected", fileList);
    },
  },
});
