


































































import Vue from "@/translate";
import AppGrid from "@/components/common/AppGrid.vue";
import JobRequirementsCard from "@/components/manager/JobRequirementsCard.vue";
import ProjectDetailsCard from "@/components/manager/ProjectDetailsCard.vue";
import JobListCard from "@/components/manager/JobListCard.vue";
import JobDetailsCard from "@/components/manager/JobDetailsCard.vue";
import ManagerMixin from "@/mixins/manager";
import { Project } from "@/models/Project";
import { Job } from "@/models/Job";
import JobFilesCard from "@/components/manager/JobFilesCard.vue";
import ManagerClient from "@/client/manager";
import { DIRTY_DATA } from "@/store/actions";
import { DirtyData } from "@/store/types";
import DisputeListCard from "@/components/manager/DisputeListCard.vue";
import JobMessagesCard from "@/components/manager/JobMessagesCard.vue";
import JobLogMessagesCard from "@/components/manager/JobLogMessagesCard.vue";
import ProjectCommentsCard from "@/components/manager/ProjectCommentsCard.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MProjectDetail",
  data() {
    return {
      loading: false,
      project: {} as Project,
      job: {} as Job,
    };
  },
  components: {
    ProjectCommentsCard,
    JobLogMessagesCard,
    JobMessagesCard,
    DisputeListCard,
    JobFilesCard,
    AppGrid,
    ProjectDetailsCard,
    JobListCard,
    JobDetailsCard,
    JobRequirementsCard,
  },
  async mounted() {
    await this.loadProject();
  },
  computed: {
    isProjectLoaded(): boolean {
      return this.project?.id !== undefined;
    },
    hasSelectedJob(): boolean {
      return this.job?.id !== undefined;
    },
  },
  methods: {
    async loadProject(): Promise<void> {
      this.loading = true;
      try {
        const projectId = Number.parseInt(this.$route.params.projectId);
        this.project = await ManagerClient.ManagerProjectQuery(projectId);
        await this.$store.dispatch(DIRTY_DATA, {
          base: "project",
          data: this.project,
        } as DirtyData);
        this.job = {} as Job;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async selectJob(jobId: number): Promise<void> {
      this.loading = true;
      this.job = await ManagerClient.JobQuery(jobId);
      await this.$store.dispatch(DIRTY_DATA, {
        base: "job",
        data: this.job,
      } as DirtyData);
      this.loading = false;
    },
    unselectJob(): void {
      this.job = {} as Job;
    },
  },
  watch: {
    "$route.params.projectId": {
      handler: function (projectId: number): void {
        if (this.project.id !== projectId) {
          this.loadProject();
        }
      },
      deep: true,
    },
  },
});
