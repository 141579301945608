




import Vue from "@/translate";
import { PropType } from "vue";
import { Actionable } from "@/models/Action";
import AppSeparator from "@/components/common/AppSeparator.vue";

export default Vue.extend({
  name: "AppActionSeparator",
  components: { AppSeparator },
  props: {
    color: {
      type: String,
      default: "dimgray",
    },
    actionable: {
      type: Object as PropType<Actionable>,
    },
    actions: {
      type: Array as PropType<string[]>,
    },
    noAction: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasAction(): boolean {
      if (this.actions && this.actions.length > 0 && this.actionable) {
        return this.actionable.actions
          ?.filter((x) => this.actions?.includes(x.action))
          .some((x) => x.possible);
      } else {
        return this.noAction;
      }
    },
  },
});
