









































































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppDialog from "@/components/common/AppDialog.vue";
import { PropType } from "vue";
import { Job } from "@/models/Job";
import AppRow from "@/components/common/AppRow.vue";
import AppCol from "@/components/common/AppCol.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppJobTypeSelect from "@/components/common/AppJobTypeSelect.vue";
import AppEffortFactorsSelect from "@/components/manager/AppEffortFactorsSelect.vue";
import { EffortFactors } from "@/models/EffortFactors";
import { SelectData } from "@/models/UI";

interface Efforts {
  preTranslated: number;
  percent_101: number;
  repetition: number;
  exactMatch: number;
  percent_95_99: number;
  percent_85_94: number;
  percent_75_84: number;
  percent_50_74: number;
  fragments?: number;
  notTranslated: number;
}

const fieldOrder: (keyof Efforts)[] = [
  "preTranslated",
  "repetition",
  "percent_101",
  "exactMatch",
  "percent_95_99",
  "percent_85_94",
  "percent_75_84",
  "percent_50_74",
  "fragments",
  "notTranslated",
];

export default Vue.extend(ManagerMixin).extend({
  name: "CalculateEffortDialog",
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  components: {
    AppEffortFactorsSelect,
    AppJobTypeSelect,
    AppGrid,
    AppButton,
    AppTextField,
    AppCol,
    AppRow,
    AppDialog,
    AppTextArea,
    AppCard,
  },
  async mounted(): Promise<void> {
    this.presets = await ManagerClient.SelectEffortFactorsQuery();
    this.applyFactors(this.presets[0].id);
  },
  data() {
    return {
      jobType: "TRANSLATION",
      presets: [] as EffortFactors[],
      factorsType: undefined as number | undefined,
      words: 0,
      input: "",
      value: {
        preTranslated: 0,
        percent_101: 0,
        repetition: 0,
        exactMatch: 0,
        percent_95_99: 0,
        percent_85_94: 0,
        percent_75_84: 0,
        percent_50_74: 0,
        notTranslated: 0,
      } as Efforts,
      factor: {
        preTranslated: 0,
        percent_101: 0,
        repetition: 0,
        exactMatch: 0,
        percent_95_99: 0,
        percent_85_94: 0,
        percent_75_84: 0,
        percent_50_74: 0,
        notTranslated: 0,
      } as Efforts,
    };
  },
  computed: {
    selectedPreset(): EffortFactors {
      return this.presets.filter((x) => x.id == this.factorsType)[0];
    },
    presetData(): SelectData[] {
      return this.presets.map((x) => {
        return {
          value: x.id,
          text: x.name,
        };
      });
    },
  },
  methods: {
    clearContent(): void {
      this.words = 0;
      this.input = "";
      this.value.preTranslated = 0;
      this.value.percent_101 = 0;
      this.value.repetition = 0;
      this.value.exactMatch = 0;
      this.value.percent_95_99 = 0;
      this.value.percent_85_94 = 0;
      this.value.percent_75_84 = 0;
      this.value.percent_50_74 = 0;
      this.value.notTranslated = 0;
    },
    applyFactors(event: number): void {
      this.factorsType = event;
      this.factor.preTranslated = this.selectedPreset.preTranslated;
      this.factor.percent_101 = this.selectedPreset.percent_101;
      this.factor.repetition = this.selectedPreset.repetition;
      this.factor.exactMatch = this.selectedPreset.exactMatch;
      this.factor.percent_95_99 = this.selectedPreset.percent_95_99;
      this.factor.percent_85_94 = this.selectedPreset.percent_85_94;
      this.factor.percent_75_84 = this.selectedPreset.percent_75_84;
      this.factor.percent_50_74 = this.selectedPreset.percent_50_74;
      this.factor.notTranslated = this.selectedPreset.notTranslated;
    },
    splitInput(event: string): void {
      const parts = event.split("\t");
      for (let i = 0; i < Math.min(parts.length, fieldOrder.length); i++) {
        this.value[fieldOrder[i]] = parseInt(parts[i]);
      }
      this.calculateWords();
    },
    calculateWords(): void {
      const total =
        this.value.preTranslated * this.factor.preTranslated +
        this.value.percent_101 * this.factor.percent_101 +
        this.value.repetition * this.factor.repetition +
        this.value.exactMatch * this.factor.exactMatch +
        this.value.percent_95_99 * this.factor.percent_95_99 +
        this.value.percent_85_94 * this.factor.percent_85_94 +
        this.value.percent_75_84 * this.factor.percent_75_84 +
        this.value.percent_50_74 * this.factor.percent_50_74 +
        this.value.notTranslated * this.factor.notTranslated;
      this.words = Math.round(total * 10) / 1000;
    },
    async updateEffort(): Promise<void> {
      await ManagerClient.UpdateJobEffortCommand({
        id: this.job.id,
        orderUnit: "WORDS",
        effort: this.words,
      });
      this.$emit("jobChanged");
    },
  },
});
