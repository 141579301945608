import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { WorkerJob } from "@/models/Job";

export const ENDPOINT = (): string => `/job/archive`;

export default async function WorkerArchiveQuery(): Promise<WorkerJob[]> {
  const result = await TranslateClient.get(
    UrlBuilder.wq(ENDPOINT()).toString()
  );
  return result.data;
}
