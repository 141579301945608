import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Invoice } from "@/models/Invoice";

export const ENDPOINT = (invoiceId: number): string => `/invoice/${invoiceId}`;

export default async function managerInvoiceQuery(
  invoiceId: number
): Promise<Invoice> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(invoiceId)).toString()
  );
  return result.data as Invoice;
}
