import { PropType } from "vue";
import { Actionable } from "@/models/Action";

export default {
  props: {
    action: {
      type: String,
    },
    actionable: {
      type: Object as PropType<Actionable>,
    },
    noAction: {
      type: Boolean,
      default: true,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasAction(this: {
      action?: string;
      actionable?: Actionable;
      valid: boolean;
      noAction: boolean;
    }): boolean {
      if (this.action && this.actionable) {
        const action = this.actionable.actions?.find(
          (x) => x.action === this.action
        );
        return action !== undefined && action.possible;
      } else {
        return this.noAction;
      }
    },
  },
};
