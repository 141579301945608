import { Invoice } from "@/models/Invoice";

export default {
  methods: {
    invoiceTypeKey(this: Vue, invoice: Invoice): string {
      if (invoice?.type) {
        return "invoice.invoiceType." + invoice.type;
      }
      return "";
    },
    invoiceTypeLabel(this: Vue, invoice: Invoice): string {
      if (invoice?.type) {
        return this.$t("invoice.invoiceType." + invoice.type) as string;
      }
      return "";
    },
    invoiceStateLabel(this: Vue, invoice: Invoice): string {
      if (invoice?.state) {
        return this.$t("invoice.invoiceState." + invoice.state) as string;
      }
      return "";
    },
  },
};
