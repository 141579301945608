




































































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import { adminScheme } from "@/mixins/admin";
import AdminClient from "@/client/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppButton from "@/components/common/AppButton.vue";
import { Admin } from "@/models/Admin";
import { ColorSchema } from "@/store/types";
import AppColorSelectCard from "@/components/common/AppColorSelectCard.vue";
import AppColorDemoCard from "@/components/common/AppColorDemoCard.vue";

export default Vue.extend(AdminMixin).extend({
  name: "ASettings",
  data() {
    return {
      admin: {} as Admin,
      adminScheme: adminScheme,
      schema: ColorSchema.ADMIN,
    };
  },
  components: {
    AppColorDemoCard,
    AppColorSelectCard,
    AppGrid,
    AppTextField,
    AppCard,
    AppButton,
  },
  async mounted() {
    await this.loadAdmin();
  },
  methods: {
    async loadAdmin(): Promise<void> {
      this.admin = await AdminClient.AdminQuery(this.adminId);
    },
    async updateAdmin(): Promise<void> {
      try {
        await AdminClient.UpdateAdminCommand(this.admin);
        await this.loadAdmin();
      } catch (e: any) {
        // TODO: handle error
      }
    },
  },
});
