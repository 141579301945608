















import Vue from "@/translate";
import AppDialog from "@/components/common/AppDialog.vue";
import ActionableMixin from "@/mixins/actionable";

export default Vue.extend(ActionableMixin).extend({
  name: "AppConfirmDialog",
  props: ["label", "icon", "color"],
  components: {
    AppDialog,
  },
  methods: {
    performAction(): void {
      this.$emit("performAction");
    },
  },
});
