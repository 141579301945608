import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CopyFilesDto = {
  jobId: number;
  source: string;
  target: string;
};

export const ENDPOINT = "/job/copy-files";

export default async function CopyFilesCommand(
  dto: CopyFilesDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
