import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AddJobDto = {
  projectId: number;
};

export const ENDPOINT = "/project/add-job";

export default async function AddJobCommand(dto: AddJobDto): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
