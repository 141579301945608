































import Vue from "@/translate";
import ColorsMixin from "@/mixins/color-scheme";
import * as date_fns from "date-fns";

export default Vue.extend(ColorsMixin).extend({
  name: "app-footer",
  computed: {
    backendVersion(): string {
      return this.$store.state.build.version || "?";
    },
    dataVersion(): string {
      const data = this.$store.state.build.data || "?";
      if ("?" !== data) {
        return date_fns
          .format(new Date(data), this.$store.state.dateFormat, {
            locale: this.$store.state.locale,
          })
          .replaceAll(" ", "\u00A0");
      }
      return data;
    },
    frontendVersion(): string {
      return process.env.VUE_APP_PACKAGE_VERSION || "?";
    },
  },
});
