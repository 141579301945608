
























































import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import AppCard from "@/components/common/AppCard.vue";
import JobMixin from "@/mixins/job";
import WorkerClient from "@/client/worker";
import AppDownload from "@/components/common/AppDownload.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import { DownloadJobType } from "@/client/client";

export default Vue.extend(WorkerMixin)
  .extend(JobMixin)
  .extend({
    name: "WorkerJobDetailsCard",
    props: ["job"],
    data() {
      return {
        messages: [],
        inputType: DownloadJobType.INPUT,
      };
    },
    methods: {
      async acceptJob(): Promise<void> {
        await WorkerClient.AcceptJobCommand({ jobId: this.job.id });
        this.$emit("jobChanged");
      },
      async declineJob(): Promise<void> {
        await WorkerClient.DeclineJobCommand({ jobId: this.job.id });
        await this.$router.push({ name: "WorkerJobs" });
      },
    },
    components: {
      AppConfirmDialog,
      AppDownload,
      AppCard,
    },
  });
