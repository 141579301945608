

















































































































import { JobStatesColors } from "@/basic/jobstates";
import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import JobMixin from "@/mixins/job";
import ManagerClient from "@/client/manager";
import { SHOW_ERROR } from "@/store/actions";
import { Project, Comment } from "@/models/Project";
import { Filter, TableColData } from "@/models/UI";
import { PropType } from "vue";
import AppLangInfo from "@/components/common/AppLangInfo.vue";
import { DefaultPagination, Pagination } from "@/models/Query";
import AppSelect from "@/components/common/AppSelect.vue";
import AppPagination from "@/components/common/AppPagination.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import JobTable from "@/components/manager/JobTable.vue";
import { Job } from "@/models/Job";
import AssignJobDialog from "@/components/manager/AssignJobDialog.vue";
import CommentsTable from "@/components/manager/CommentsTable.vue";
import AppDetailsButton from "@/components/common/AppDetailsButton.vue";
import AppButton from "@/components/common/AppButton.vue";

export default Vue.extend(ManagerMixin)
  .extend(JobMixin)
  .extend({
    name: "ManagerProjectTable",
    components: {
      AppButton,
      AppDetailsButton,
      CommentsTable,
      AssignJobDialog,
      JobTable,
      AppPagination,
      AppSelect,
      AppLangInfo,
      AppIcon,
    },
    props: {
      filters: {
        type: Array as PropType<Filter[]>,
        default: () => [],
      },
    },
    data() {
      return {
        colors: JobStatesColors,
        selected: -1,
        projects: [] as Project[],
        jobs: [] as Job[],
        comments: [] as Comment[],
        pagination: DefaultPagination,
      };
    },
    computed: {
      loading(): boolean {
        return this.$store.state.loading;
      },
      headers(): TableColData[] {
        return [
          {
            text: this.$t("project.customerNumberShort") as string,
            value: "customerNumber",
            align: "left",
            sortable: true,
          },
          {
            text: this.$t("project.entered") as string,
            value: "entered",
            align: "left",
            sortable: true,
          },
          {
            text: this.$t("project.due") as string,
            value: "due",
            align: "left",
            sortable: true,
          },
          {
            text: this.$t("project.jobStateShort") as string,
            value: "jobStatus",
            align: "left",
            sortable: true,
          },
          {
            text: this.$t("project.description") as string,
            value: "description",
            align: "left",
            sortable: true,
          },
          {
            text: this.$t("project.sourceShort") as string,
            value: "source",
            align: "left",
            sortable: true,
          },
          {
            text: this.$t("project.targetShort") as string,
            value: "target",
            align: "left",
            sortable: true,
          },
          {
            text: this.$t("project.attention") as string,
            value: "comments",
            align: "left",
            sortable: true,
          },
          {
            text: this.$t("project.actions") as string,
            value: "actions",
            align: "left",
            sortable: false,
          },
        ];
      },
    },
    methods: {
      async loadComments(projectId: number): Promise<void> {
        this.comments = await ManagerClient.CommentsQuery(projectId);
      },
      changePagination(event: Pagination): void {
        this.pagination = event;
        this.loadProjects();
      },
      async loadProjects(): Promise<void> {
        try {
          const result = await ManagerClient.ManagerProjectsQuery(
            this.filters,
            this.pagination
          );
          this.projects = result.content;
          this.pagination.totalElements = result.totalElements;
          this.pagination.totalPages = result.totalPages;
        } catch (e) {
          await this.$store.dispatch(SHOW_ERROR, "Konnte Projekte nicht laden");
        }
      },
      async loadJobs(projectId: number): Promise<void> {
        try {
          this.jobs = await ManagerClient.JobsQuery(projectId);
        } catch (e) {
          await this.$store.dispatch(SHOW_ERROR, "Konnte Jobs nicht laden");
        }
      },
      async toggleShowJobDetails(
        projectId: number,
        loadComments = false
      ): Promise<void> {
        if (this.selected === projectId) {
          this.selected = -1;
          this.comments = [];
        } else {
          this.selected = projectId;
          await this.loadJobs(projectId);
          if (loadComments) {
            await this.loadComments(projectId);
          } else {
            this.comments = [];
          }
        }
      },
    },
    watch: {
      filters: {
        immediate: true,
        handler() {
          this.loadProjects();
        },
      },
    },
  });
