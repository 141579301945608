









































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AdminClient from "@/client/admin";
import { SHOW_ERROR } from "@/store/actions";
import { Customer, RepresentativeListItem } from "@/models/Customer";
import { TableColData } from "@/models/UI";
import { PropType } from "vue";

export default Vue.extend(AdminMixin).extend({
  name: "RepresentativeTable",
  props: {
    customer: {
      type: Object as PropType<Customer> | undefined,
      required: true,
    },
    includeDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      representatives: [] as RepresentativeListItem[],
    };
  },
  computed: {
    loading(): boolean {
      return this.$store.state.loading;
    },
    headers(): TableColData[] {
      return [
        {
          text: this.$t("representative.name") as string,
          value: "name",
          align: "left",
        },
        {
          text: this.$t("representative.phone") as string,
          value: "phone",
          align: "left",
        },
        {
          text: this.$t("representative.email") as string,
          value: "email",
          align: "left",
        },
        {
          text: this.$t("representative.disabled") as string,
          value: "disabled",
          align: "left",
        },
        {
          text: this.$t("representative.actions") as string,
          value: "actions",
          align: "left",
        },
      ];
    },
  },
  methods: {
    async loadRepresentatives(): Promise<void> {
      try {
        if (this.customer?.id) {
          this.representatives = await AdminClient.RepresentativeListQuery(
            this.customer.id,
            this.includeDisabled
          );
        }
      } catch (e) {
        await this.$store.dispatch(
          SHOW_ERROR,
          "Konnte Projektleiter nicht laden"
        );
      }
    },
  },
  watch: {
    customer: {
      immediate: true,
      handler() {
        this.loadRepresentatives();
      },
    },
    includeDisabled: {
      handler() {
        this.loadRepresentatives();
      },
    },
  },
});
