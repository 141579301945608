import { Store } from "vuex";
import { RootState } from "@/store/types";

export default {
  props: {
    ignoreDark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainColor(this: { $store: Store<RootState> }): string {
      return this.$store.state.colorScheme.mainColor;
    },
    sideColor(this: { $store: Store<RootState> }): string {
      return this.$store.state.colorScheme.sideColor;
    },
    dark(this: { ignoreDark: boolean; $store: Store<RootState> }): boolean {
      if (this.ignoreDark) {
        return false;
      }
      return this.$store.state.colorScheme.dark;
    },
  },
};
