






import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import EmailSettingsCard from "@/components/admin/EmailSettingsCard.vue";

export default Vue.extend(AdminMixin).extend({
  name: "AEmailSettings",
  data() {
    return {
      includeDisabled: false,
    };
  },
  components: {
    EmailSettingsCard,
  },
  methods: {},
});
