import Colors from "./colors";
import VueI18n from "@/i18n";

export enum JobStates {
  JOBSTATE_ENTERED,
  JOBSTATE_INQUIRED,
  JOBSTATE_INQUIRED_REJECTED,
  JOBSTATE_INQUIRED_ACCEPTED,
  JOBSTATE_DISTRIBUTED,
  JOBSTATE_ACCEPTED,
  JOBSTATE_RETURNED,
  JOBSTATE_FINISHED,
}

export const JobStatesColors = {
  JOBSTATE_ENTERED: Colors.OPEN,
  JOBSTATE_INQUIRED: Colors.INQUIRED,
  JOBSTATE_INQUIRED_REJECTED: "#black",
  JOBSTATE_INQUIRED_ACCEPTED: Colors.INQUIRED,
  JOBSTATE_DISTRIBUTED: Colors.INQUIRED,
  JOBSTATE_ACCEPTED: "#black",
  JOBSTATE_RETURNED: Colors.RETURNED,
  JOBSTATE_FINISHED: Colors.RETURNED,
} as Record<string, string>;

type JobStateSelectData = {
  text: string;
  value: string;
};

const JobStateSelect = [] as JobStateSelectData[];
for (const state in JobStates) {
  const isValueProperty = parseInt(state, 10) >= 0;
  if (isValueProperty) {
    JobStateSelect.push({
      value: JobStates[state],
      text: VueI18n.t("common.jobStates." + JobStates[state]) as string,
    });
  }
}
export { JobStateSelect };
