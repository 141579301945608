























import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import { SelectData } from "@/models/UI";
import { PropType } from "vue";

export default Vue.extend(FormElementMixin).extend({
  name: "AppEffortFactorsSelect",
  props: {
    asAction: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<SelectData[]>,
      required: true,
    },
  },
});
