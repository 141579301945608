import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { CustomerListItem } from "@/models/Customer";

export const ENDPOINT = (): string => `/customer/`;

export default async function customersQuery(includeDisabled = false): Promise<CustomerListItem[]> {
  const builder = UrlBuilder.aq(ENDPOINT()).addParams("includeDisabled", includeDisabled);
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
