import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DisableCustomerDto = {
  customerId: number;
};

export const ENDPOINT = "/customer/disable";

export default async function DisableCustomerCommand(
  dto: DisableCustomerDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
