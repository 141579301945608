import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { RepresentativeListItem } from "@/models/Customer";

export const ENDPOINT = (customerId: number): string => `/customer/${customerId}/representatives`;

export default async function representativeListQuery(customerId: number, includeDisabled = false): Promise<RepresentativeListItem[]> {
  const builder = UrlBuilder.aq(ENDPOINT(customerId)).addParams("includeDisabled", includeDisabled);
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
