import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Filter } from "@/models/UI";
import { Project } from "@/models/Project";
import { PagedResult, Pagination } from "@/models/Query";

export const ENDPOINT = (): string => `/project/`;

export default async function managerProjectsQuery(
  filters: Filter[],
  pagination: Pagination
): Promise<PagedResult<Project>> {
  const builder = UrlBuilder.mq(ENDPOINT());
  filters.forEach((x) => {
    builder.addParams(x.name, x.value);
  });
  builder.addPagination(pagination);
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
