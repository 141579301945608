
















import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import ColorsMixin from "@/mixins/color-scheme";

export default Vue.extend(FormElementMixin)
  .extend(ColorsMixin)
  .extend({
    name: "AppCheckbox",
    props: {
      asAction: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        model: false,
      };
    },
    methods: {
      handleEvent(event: any): void {
        this.$emit("input", event === true);
      },
    },
    watch: {
      value: {
        handler(val: boolean) {
          this.model = val;
        },
        immediate: true,
      },
    },
  });
