




























import Vue from "@/translate";
import AppGrid from "@/components/common/AppGrid.vue";
import AdminMixin from "@/mixins/admin";
import AdminClient from "@/client/admin";
import { Address } from "@/models/Common";
import CustomerDetailsCard from "@/components/admin/CustomerDetailsCard.vue";
import AddressDetailsCard from "@/components/admin/AddressDetailsCard.vue";
import { Affiliation } from "@/models/Affiliation";
import AffiliationDetailsCard from "@/components/admin/AffiliationDetailsCard.vue";
import AppButton from "@/components/common/AppButton.vue";

export default Vue.extend(AdminMixin).extend({
  name: "AAffiliationDetail",
  data() {
    return {
      affiliation: {} as Affiliation,
      address: {} as Address,
    };
  },
  components: {
    AppButton,
    AffiliationDetailsCard,
    AddressDetailsCard,
    CustomerDetailsCard,
    AppGrid,
  },
  async mounted() {
    await this.loadAffiliation();
  },
  computed: {
    isAffiliationLoaded(): boolean {
      return this.affiliation?.id !== undefined;
    },
    hasAddress(): boolean {
      return this.address?.id !== undefined;
    },
  },
  methods: {
    async createAddress(): Promise<void> {
      await AdminClient.CreateAffiliationAddressCommand({
        affiliationId: this.affiliation.id,
      });
      await this.loadAffiliation();
    },
    async loadAffiliation(): Promise<void> {
      try {
        const affiliationId = Number.parseInt(this.$route.params.affiliationId);
        this.affiliation = await AdminClient.AffiliationQuery(affiliationId);
        if (this.affiliation.addressId) {
          this.address = await AdminClient.AddressQuery(
            this.affiliation.addressId
          );
        } else {
          this.address = {} as Address;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
});
