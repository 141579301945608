import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AssignWorkerDto = {
  jobId: number;
  workerId: number;
  inquire: boolean;
};

export const ENDPOINT = "/job/assign-worker";

export default async function AssignWorkerCommand(
  dto: AssignWorkerDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
