import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateAffiliationAddressDto = {
  affiliationId: number;
};

export const ENDPOINT = "/address/create-affiliation-address";

export default async function CreateAffiliationAddressCommand(
  dto: CreateAffiliationAddressDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.ac(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
