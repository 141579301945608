import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DisableRepresentativeDto = {
  representativeId: number;
};

export const ENDPOINT = "/representative/disable";

export default async function DisableRepresentativeCommand(
  dto: DisableRepresentativeDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
