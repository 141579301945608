import Login from "../auth/Login";
import UserByNameQuery from "../auth/UserByNameQuery";
import UserByTokenQuery from "../auth/UserByTokenQuery";

import BuildDetailsQuery from "./BuildDetailsQuery";
import CreateProjectCommand from "./CreateProjectCommand";
import UpdateProjectCommand from "./UpdateProjectCommand";
import UpdateJobCommand from "./UpdateJobCommand";
import UploadPlunetCommand from "./UploadPlunetCommand";
import AssignWorkerCommand from "./AssignWorkerCommand";
import UpdateJobRequirementsCommand from "./UpdateJobRequirementsCommand";
import ModifyJobRequirementsCommand from "./ModifyJobRequirementsCommand";
import ManagerProjectsQuery from "./ManagerProjectsQuery";
import PossibleWorkersQuery from "./PossibleWorkersQuery";
import ManagerProjectQuery from "./ManagerProjectQuery";
import JobRequirementsQuery from "./JobRequirementsQuery";
import SelectCustomerQuery from "./SelectCustomerQuery";
import JobFilesQuery from "./JobFilesQuery";
import AddInputCommand from "./AddInputCommand";
import ClearFileCommand from "./ClearFileCommand";
import AddResultCommand from "./AddResultCommand";
import DeleteProjectCommand from "./DeleteProjectCommand";
import DeleteJobCommand from "./DeleteJobCommand";
import JobsQuery from "./JobsQuery";
import JobQuery from "./JobQuery";
import AddJobCommand from "./AddJobCommand";
import RootQuery from "./RootQuery";
import DuplicateJobCommand from "./DuplicateJobCommand";
import AddProofreadJobCommand from "./AddProofreadJobCommand";
import AllWorkersQuery from "./AllWorkersQuery";
import FinishJobCommand from "./FinishJobCommand";
import ManagerQuery from "./ManagerQuery";
import UpdateManagerCommand from "./UpdateManagerCommand";
import UploadEDICommand from "./UploadEDICommand";
import ManagerInvoicesQuery from "./ManagerInvoicesQuery";
import ManagerInvoiceQuery from "./ManagerInvoiceQuery";
import UpdateInvoiceCommand from "./UpdateInvoiceCommand";
import DeleteInvoiceCommand from "./DeleteInvoiceCommand";
import UpdateInvoicePositionCommand from "./UpdateInvoicePositionCommand";
import ResetInvoicePositionCommand from "./ResetInvoicePositionCommand";
import DeleteInvoicePositionCommand from "./DeleteInvoicePositionCommand";
import EDIJobsQuery from "./EDIJobsQuery";
import AbandonJobCommand from "./AbandonJobCommand";
import AbandonProjectCommand from "./AbandonProjectCommand";
import DisputeJobCommand from "./DisputeJobCommand";
import DisputesQuery from "./DisputesQuery";
import DisputeQuery from "./DisputeQuery";
import UpdateDisputeCommand from "./UpdateDisputeCommand";
import CloseDisputeCommand from "./CloseDisputeCommand";
import DeleteDisputeCommand from "./DeleteDisputeCommand";
import UpdateCommentCommand from "./UpdateCommentCommand";
import DeleteCommentCommand from "./DeleteCommentCommand";
import JobMessagesQuery from "./JobMessagesQuery";
import CreateJobMessageCommand from "./CreateJobMessageCommand";
import UpdateJobMessageCommand from "./UpdateJobMessageCommand";
import DeleteJobMessageCommand from "./DeleteJobMessageCommand";
import JobLogMessagesQuery from "./JobLogMessagesQuery";
import UpdateEdiSettingsCommand from "./UpdateEdiSettingsCommand";
import RetryEDIJobCommand from "./RetryEDIJobCommand";
import DeleteEDIJobCommand from "./DeleteEDIJobCommand";
import EDIPreviewQuery from "./EDIPreviewQuery";
import EDISearchQuery from "./EDISearchQuery";
import EDIAssignCommand from "./EDIAssignCommand";
import AddInvoicePositionCommand from "./AddInvoicePositionCommand";
import EdiOrdersQuery from "./EdiOrdersQuery";
import UnassignedJobsQuery from "./UnassignedJobsQuery";
import SelectAffiliationsQuery from "./SelectAffiliationsQuery";
import ReceiptsQuery from "./ReceiptsQuery";
import ReceiptQuery from "./ReceiptQuery";
import DeleteReceiptCommand from "./DeleteReceiptCommand";
import UpdateReceiptCommand from "./UpdateReceiptCommand";
import AffiliationQuery from "./AffiliationQuery";
import CreateReceiptCommand from "./CreateReceiptCommand";
import ConfirmReceiptCommand from "./ConfirmReceiptCommand";
import UnassignedReceiptJobsQuery from "./UnassignedReceiptJobsQuery";
import AddReceiptPositionCommand from "./AddReceiptPositionCommand";
import UpdateReceiptPositionCommand from "./UpdateReceiptPositionCommand";
import DeleteReceiptPositionCommand from "./DeleteReceiptPositionCommand";
import BlockInvoiceCommand from "./BlockInvoiceCommand";
import UnblockInvoiceCommand from "./UnblockInvoiceCommand";
import FinishInvoiceCommand from "./FinishInvoiceCommand";
import ConfirmInvoiceCommand from "./ConfirmInvoiceCommand";
import CreateInvoiceCommand from "./CreateInvoiceCommand";
import CommentsQuery from "./CommentsQuery";
import AddCommentCommand from "./AddCommentCommand";
import SelectEffortFactorsQuery from "./SelectEffortFactorsQuery";
import UpdateJobEffortCommand from "./UpdateJobEffortCommand";
import CopyFilesCommand from "./CopyFilesCommand";
import SelectProjectQuery from "./SelectProjectQuery";

export default {
  // Auth
  Login,
  UserByNameQuery,
  UserByTokenQuery,

  // Command
  CreateProjectCommand,
  UpdateProjectCommand,
  UpdateJobCommand,
  DeleteProjectCommand,
  UploadPlunetCommand,
  UpdateJobRequirementsCommand,
  ModifyJobRequirementsCommand,
  AssignWorkerCommand,
  DeleteJobCommand,
  AddInputCommand,
  ClearFileCommand,
  AddResultCommand,
  AddJobCommand,
  AddProofreadJobCommand,
  DuplicateJobCommand,
  FinishJobCommand,
  UpdateManagerCommand,
  UploadEDICommand,
  CreateInvoiceCommand,
  UpdateInvoiceCommand,
  DeleteInvoiceCommand,
  UpdateInvoicePositionCommand,
  DeleteInvoicePositionCommand,
  ResetInvoicePositionCommand,
  AbandonJobCommand,
  AbandonProjectCommand,
  DisputeJobCommand,
  UpdateDisputeCommand,
  CloseDisputeCommand,
  DeleteDisputeCommand,
  AddCommentCommand,
  UpdateCommentCommand,
  DeleteCommentCommand,
  CreateJobMessageCommand,
  UpdateJobMessageCommand,
  DeleteJobMessageCommand,
  UpdateEdiSettingsCommand,
  RetryEDIJobCommand,
  DeleteEDIJobCommand,
  EDIAssignCommand,
  AddInvoicePositionCommand,
  CreateReceiptCommand,
  UpdateReceiptCommand,
  ConfirmReceiptCommand,
  DeleteReceiptCommand,
  AddReceiptPositionCommand,
  UpdateReceiptPositionCommand,
  DeleteReceiptPositionCommand,
  BlockInvoiceCommand,
  UnblockInvoiceCommand,
  FinishInvoiceCommand,
  ConfirmInvoiceCommand,
  UpdateJobEffortCommand,
  CopyFilesCommand,

  // Query
  BuildDetailsQuery,
  RootQuery,
  ManagerQuery,
  ManagerProjectsQuery,
  ManagerProjectQuery,
  SelectCustomerQuery,
  JobRequirementsQuery,
  PossibleWorkersQuery,
  AllWorkersQuery,
  JobsQuery,
  JobQuery,
  JobFilesQuery,
  ManagerInvoicesQuery,
  ManagerInvoiceQuery,
  EdiOrdersQuery,
  EDIJobsQuery,
  EDIPreviewQuery,
  EDISearchQuery,
  DisputesQuery,
  DisputeQuery,
  JobMessagesQuery,
  JobLogMessagesQuery,
  UnassignedJobsQuery,
  SelectAffiliationsQuery,
  AffiliationQuery,
  ReceiptsQuery,
  ReceiptQuery,
  UnassignedReceiptJobsQuery,
  CommentsQuery,
  SelectEffortFactorsQuery,
  SelectProjectQuery,
};
