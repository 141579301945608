































































































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import { Customer, CustomerPrice } from "@/models/Customer";
import { TableColData } from "@/models/UI";
import { PropType } from "vue";
import AppJobTypeSelect from "@/components/common/AppJobTypeSelect.vue";
import AppOrderUnitSelect from "@/components/common/AppOrderUnitSelect.vue";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppButton from "@/components/common/AppButton.vue";
import AdminClient from "@/client/admin";
import AppDialog from "@/components/common/AppDialog.vue";
import CustomerPriceDialog from "@/components/admin/CustomerPriceDialog.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";

export default Vue.extend(AdminMixin).extend({
  name: "CustomerPriceTable",
  components: {
    AppConfirmDialog,
    CustomerPriceDialog,
    AppDialog,
    AppButton,
    AppTextField,
    AppLangSelect,
    AppOrderUnitSelect,
    AppJobTypeSelect,
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
    },
    prices: {
      type: Array as PropType<CustomerPrice[]>,
    },
  },
  data() {
    return {
      jobType: "",
      orderUnit: "",
      source: "",
      target: "",
      filteredPrices: [] as CustomerPrice[],
      editedItem: {} as CustomerPrice,
    };
  },
  computed: {
    hasFilter(): boolean {
      return (
        this.orderUnit != "" ||
        this.jobType != "" ||
        this.source != "" ||
        this.target != ""
      );
    },
    headers(): TableColData[] {
      return [
        {
          text: this.$t("job.jobType") as string,
          value: "jobType",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("job.orderUnit") as string,
          value: "orderUnit",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("job.source") as string,
          value: "source",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("job.target") as string,
          value: "target",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("customer.price") as string,
          value: "price",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("customer.actions") as string,
          value: "actions",
          align: "left",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    clearFilter(): void {
      this.filteredPrices = this.prices;
      this.orderUnit = "";
      this.jobType = "";
      this.source = "";
      this.target = "";
    },
    applyFilters(): void {
      let filtered = this.prices;
      if (this.jobType != "") {
        filtered = filtered.filter((x) => x.jobType == this.jobType);
      }
      if (this.orderUnit != "") {
        filtered = filtered.filter((x) => x.orderUnit == this.orderUnit);
      }
      if (this.source != "") {
        filtered = filtered.filter((x) => x.source == this.source);
      }
      if (this.target != "") {
        filtered = filtered.filter((x) => x.target == this.target);
      }
      this.filteredPrices = filtered;
    },
    async savePrice(price: CustomerPrice): Promise<void> {
      await AdminClient.UpdateCustomerPriceCommand({
        ...price,
      });
      this.$emit("pricesChanged");
    },
    async deletePrice(price: CustomerPrice): Promise<void> {
      await AdminClient.DeleteCustomerPriceCommand({
        customerPriceId: price.id,
      });
      this.$emit("pricesChanged");
    },
  },
  watch: {
    prices: {
      handler(val) {
        this.filteredPrices = val;
        this.applyFilters();
      },
      immediate: true,
    },
  },
});
