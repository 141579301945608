import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { ManagerRoot } from "@/models/ManagerRoot";

export const ENDPOINT = "/";

export default async function RootQuery(): Promise<ManagerRoot> {
  const result = await TranslateClient.get(UrlBuilder.a(ENDPOINT).toString());
  return result.data;
}
