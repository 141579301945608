
























































import { JobStatesColors } from "@/basic/jobstates";
import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import JobMixin from "@/mixins/job";
import ManagerClient from "@/client/manager";
import { SHOW_ERROR } from "@/store/actions";
import { Filter, TableColData } from "@/models/UI";
import { PropType } from "vue";
import AppLangInfo from "@/components/common/AppLangInfo.vue";
import { DefaultPagination } from "@/models/Query";
import AppSelect from "@/components/common/AppSelect.vue";
import AppPagination from "@/components/common/AppPagination.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import { Receipt } from "@/models/Affiliation";
import * as date_fns from "date-fns";

export default Vue.extend(ManagerMixin)
  .extend(JobMixin)
  .extend({
    name: "ReceiptsTable",
    components: { AppPagination, AppSelect, AppLangInfo, AppIcon },
    props: {
      filters: {
        type: Array as PropType<Filter[]>,
        default: () => [],
      },
    },
    data() {
      return {
        colors: JobStatesColors,
        selected: [],
        receipts: [] as Receipt[],
        pagination: DefaultPagination,
      };
    },
    computed: {
      loading(): boolean {
        return this.$store.state.loading;
      },
      headers(): TableColData[] {
        return [
          {
            text: this.$t("receipt.id") as string,
            value: "id",
            align: "left",
            sortable: false,
          },
          {
            text: this.$t("receipt.affiliation") as string,
            value: "affiliation",
            align: "left",
            sortable: false,
          },
          {
            text: this.$t("receipt.receiptDate") as string,
            value: "receiptDate",
            align: "left",
            sortable: false,
          },
          {
            text: this.$t("receipt.checked") as string,
            value: "checked",
            align: "left",
            sortable: false,
          },
          {
            text: this.$t("receipt.positionsCount") as string,
            value: "positionsCount",
            align: "left",
            sortable: false,
          },
          {
            text: this.$t("receipt.sum") as string,
            value: "receiptDate",
            align: "left",
            sortable: false,
          },
          {
            text: this.$t("receipt.actions") as string,
            value: "actions",
            align: "left",
            sortable: false,
          },
        ];
      },
    },
    methods: {
      receiptDateLabel(receipt: Receipt): string {
        if (receipt && receipt.receiptDate) {
          return date_fns
            .format(
              new Date(receipt.receiptDate),
              this.$store.state.dateFormat,
              {
                locale: this.$store.state.locale,
              }
            )
            .replaceAll(" ", "\u00A0");
        }
        return "-";
      },
      changePagination(event: any): void {
        this.pagination = event;
        this.loadReceipts();
      },
      async loadReceipts(): Promise<void> {
        try {
          const result = await ManagerClient.ReceiptsQuery(
            this.filters,
            this.pagination
          );
          this.receipts = result.content;
          this.pagination.totalElements = result.totalElements;
          this.pagination.totalPages = result.totalPages;
        } catch (e) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Rechnungen nicht laden"
          );
        }
      },
    },
    watch: {
      filters: {
        immediate: true,
        handler() {
          this.loadReceipts();
        },
      },
    },
  });
