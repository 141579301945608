import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Job } from "@/models/Job";
import { toLocal } from "@/utils/dates";

export const ENDPOINT = (jobId: number): string => `/job/${jobId}`;

export default async function JobQuery(jobId: number): Promise<Job> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(jobId)).toString()
  );
  const job = result.data;
  return {
    ...job,
    entered: toLocal(job.entered),
    due: toLocal(job.due),
  };
}
