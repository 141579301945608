


























import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppDialog from "@/components/common/AppDialog.vue";
import { PropType } from "vue";
import { JobMessage } from "@/models/Job";
import AppCheckbox from "@/components/common/AppCheckbox.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "UpdateJobMessageDialog",
  props: {
    message: {
      type: Object as PropType<JobMessage>,
    },
  },
  components: {
    AppCheckbox,
    AppDialog,
    AppTextArea,
    AppCard,
  },
  data() {
    return {
      content: "",
      global: false,
    };
  },
  methods: {
    init(): void {
      this.content = this.message?.content || "";
      this.global = this.message.global || false;
    },
    async updateMessage(): Promise<void> {
      if (this.message.id) {
        await ManagerClient.UpdateJobMessageCommand({
          messageId: this.message.id,
          content: this.content,
          global: this.global,
        });
        this.$emit("messagesChanged");
      }
    },
  },
});
