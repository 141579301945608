












import Vue from "@/translate";
import AppButton from "@/components/common/AppButton.vue";
import { performDownload } from "@/client/client";
import ActionableMixin from "@/mixins/actionable";

export default Vue.extend(ActionableMixin).extend({
  name: "AppDownloadButton",
  props: {
    i18nLabel: {
      type: String,
    },
    icon: {
      type: String,
      required: true,
    },
    uri: {
      type: String,
    },
  },
  components: {
    AppButton,
  },
  data() {
    return {};
  },
  methods: {
    download(): void {
      performDownload("app-download-button:" + this.i18nLabel, this.uri);
    },
  },
});
