import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { toUTC } from "@/utils/dates";

export type UpdateDisputeDto = {
  id: number;

  entered: string;
  description: string;

  disputeType: string;
  cause: string;

  shortMeasure: string;
  longMeasure: string;

  measureDone: boolean;
  justlyDisputed: boolean;
};

export const ENDPOINT = "/dispute/update";

export default async function UpdateDisputeCommand(
  dto: UpdateDisputeDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), {
    ...dto,
    entered: toUTC(dto.entered),
  });
}
