import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Receipt } from "@/models/Affiliation";

export const ENDPOINT = (receiptId: number): string =>
  `/affiliations/receipts/${receiptId}`;

export default async function getReceiptQuery(
  receiptId: number
): Promise<Receipt> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(receiptId)).toString()
  );
  return result.data as Receipt;
}
