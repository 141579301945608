import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateRepresentativeDto = {
  customerId: number;
  firstname: string;
  lastname: string;
  middlename: string;
  phone: string;
  phone2: string;
  mobile: string;
  telefax: string;
  email: string;
  web: string;
};

export const ENDPOINT = "/representative/create";

export default async function CreateRepresentativeCommand(
  dto: CreateRepresentativeDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.ac(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
