



















import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import { SelectData } from "@/models/UI";
import { PropType } from "vue";
import { WorkerSelect } from "@/client/manager/PossibleWorkersQuery";

export default Vue.extend(FormElementMixin).extend({
  name: "AppWorkerSelect",
  props: {
    workers: {
      type: Array as PropType<WorkerSelect[]>,
      required: true,
    },
  },
  computed: {
    possibleWorkersSelect(): SelectData[] {
      return this.workers.map((x) => {
        const color = x.rating ? x.rating : "inherit";
        let text = x.lastname + " " + x.firstname;
        if (x.utilization !== undefined) {
          text += " (" + x.utilization + "%)";
        }
        return {
          value: x.workerId,
          text,
          color,
        };
      });
    },
  },
});
