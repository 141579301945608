













































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import { PropType } from "vue";
import { Invoice } from "@/models/Invoice";
import ManagerClient from "@/client/manager";
import { EdiOrder } from "@/models/Edi";
import { downloadEdiFile, DownloadEdiType } from "@/client/client";

export default Vue.extend(ManagerMixin).extend({
  name: "EdiOrderCard",
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
    },
  },
  data() {
    return {
      open: false,
      ediOrders: [] as EdiOrder[],
    };
  },
  computed: {
    first(): EdiOrder | undefined {
      if (this.ediOrders && this.ediOrders.length > 0) {
        return this.ediOrders[0];
      }
      return undefined;
    },
    rest(): EdiOrder[] {
      return this.ediOrders.slice(1);
    },
  },
  components: {
    AppCard,
  },
  methods: {
    downloadEdiOrderURL(filename: string): string {
      return downloadEdiFile(filename, DownloadEdiType.ORDER);
    },
    async loadEdiOrders(): Promise<void> {
      try {
        this.ediOrders = (
          await ManagerClient.EdiOrdersQuery(this.invoice.id)
        ).reverse();
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    invoice: {
      handler() {
        this.loadEdiOrders();
      },
      immediate: true,
    },
  },
});
