import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteJobMessageDto = {
  messageId: number;
};

export const ENDPOINT = "/job/delete-message";

export default async function DeleteJobMessageCommand(
  dto: DeleteJobMessageDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
