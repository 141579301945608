









































































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AdminClient from "@/client/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppMailEventSelect from "@/components/common/AppMailEventSelect.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import { EmailSetting } from "@/models/EmailSettings";
import AppToggle from "@/components/common/AppToggle.vue";

export default Vue.extend(AdminMixin).extend({
  name: "EmailSettingsCard",
  async created() {
    await this.loadData();
  },
  components: {
    AppToggle,
    AppGrid,
    AppCheckbox,
    AppTextField,
    AppCard,
    AppButton,
    AppMailEventSelect,
  },
  data() {
    return {
      sendMails: true,
      settings: [] as EmailSetting[],
      add: {} as EmailSetting,
    };
  },
  computed: {},
  methods: {
    async loadData() {
      const result = await AdminClient.EmailSettingsQuery();
      this.sendMails = result.sendMails;
      this.settings = result.settings;
    },
    async toggleSendMails(value: boolean): Promise<void> {
      this.sendMails = value;
      await AdminClient.UpdateSendMailsCommand({
        sendMails: this.sendMails,
      });
      await this.loadData();
    },
    async createMailSetting(): Promise<void> {
      await AdminClient.CreateEmailSettingCommand(this.add);
      await this.loadData();
      this.add = {} as EmailSetting;
    },
    async saveMailSetting(index: number): Promise<void> {
      await AdminClient.UpdateEmailSettingCommand(this.settings[index]);
      await this.loadData();
    },
    async deleteMailSetting(index: number): Promise<void> {
      await AdminClient.DeleteEmailSettingCommand({
        emailSettingId: this.settings[index].id,
      });
      await this.loadData();
    },
  },
});
