

















import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import ColorsMixin from "@/mixins/color-scheme";

export default Vue.extend(FormElementMixin)
  .extend(ColorsMixin)
  .extend({
    name: "AppToggle",
    props: {
      asAction: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleEvent(event: any): void {
        this.$emit("input", event === true);
      },
    },
  });
