import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteCustomerPriceDto = {
  customerPriceId: number;
};

export const ENDPOINT = "/customer/delete-price";

export default async function DeleteCustomerCommand(
  dto: DeleteCustomerPriceDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
