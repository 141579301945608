













































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppButton from "@/components/common/AppButton.vue";
import { EdiPosition, EdiPreview } from "@/models/Edi";
import AppFileSize from "@/components/common/AppFileSize.vue";
import AppDateLabel from "@/components/common/AppDateLabel.vue";
import AppToggle from "@/components/common/AppToggle.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import AppLabeledData from "@/components/common/AppLabeledData.vue";
import { PropType } from "vue";
import { TableColData } from "@/models/UI";

export default Vue.extend(ManagerMixin).extend({
  name: "EdiPositionsCard",
  props: {
    filename: {
      type: String,
      required: true,
    },
    preview: {
      type: Object as PropType<EdiPreview>,
    },
  },
  components: {
    AppLabeledData,
    AppConfirmDialog,
    AppToggle,
    AppDateLabel,
    AppFileSize,
    AppCard,
    AppIcon,
    AppButton,
  },
  data: function () {
    return {};
  },
  computed: {
    count(): number {
      if (this.preview.positions) {
        return this.preview.positions.length;
      }
      return 0;
    },
    title(): string {
      return (
        this.$t("edi.preview.positions") +
        " " +
        this.count +
        ", " +
        this.$t("edi.preview.sum") +
        " " +
        this.preview.sum +
        "€"
      );
    },
    headers(): TableColData[] {
      return [
        {
          text: this.$t("edi.positions.position") as string,
          value: "position",
          align: "left",
        },
        {
          text: this.$t("edi.positions.type") as string,
          value: "type",
          align: "left",
        },
        {
          text: this.$t("edi.positions.desc") as string,
          value: "desc",
          align: "left",
        },
        {
          text: this.$t("edi.positions.details") as string,
          value: "details",
          align: "left",
        },
        {
          text: this.$t("edi.positions.language") as string,
          value: "",
          align: "left",
        },
        {
          text: this.$t("edi.positions.quantity") as string,
          value: "",
          align: "left",
        },
        {
          text: this.$t("edi.positions.price") as string,
          value: "price",
          align: "left",
        },
      ];
    },
  },
  methods: {
    typeLabel(pos: EdiPosition): string {
      return this.$t("edi.positions.typeValues." + pos.type) as string;
    },
    typeInfo(pos: EdiPosition): string {
      return this.$t("edi.positions.typeInfos." + pos.type) as string;
    },
    sourceLabel(pos: EdiPosition): string {
      return this.$t("common.languages." + pos.source) as string;
    },
    targetLabel(pos: EdiPosition): string {
      return this.$t("common.languages." + pos.target) as string;
    },
  },
});
