import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AddInvoicePositionDto = {
  invoiceId: number;
  active: boolean;
  jobId?: number;

  position: number;
  type: string;

  worker?: string;
  source: string;
  target: string;

  unitType: string;
  unit: number;

  positionPrice: number;
};

export const ENDPOINT = "/invoice/position/add";

export default async function AddInvoicePositionCommand(dto: AddInvoicePositionDto): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
