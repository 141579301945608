import Login from "./auth/Login";
import UserByNameQuery from "./auth/UserByNameQuery";
import UserByTokenQuery from "./auth/UserByTokenQuery";
import ResetPasswordCommand from "./auth/ResetPasswordCommand";
import PasswordForgottenCommand from "./auth/PasswordForgottenCommand";

export default {
  // Auth
  Login,
  UserByNameQuery,
  UserByTokenQuery,

  PasswordForgottenCommand,
  ResetPasswordCommand,
};
