







































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import AppButton from "@/components/common/AppButton.vue";
import AppWorkerSelect from "@/components/common/AppWorkerSelect.vue";
import AppDialog from "@/components/common/AppDialog.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppSelect from "@/components/common/AppSelect.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppTextArea from "@/components/common/AppTextArea.vue";
import { now } from "@/utils/dates";
import AppTimeSelect from "@/components/common/AppTimeSelect.vue";
import { DisputeTypesSelect } from "@/basic/disputetypes";

export default Vue.extend(ManagerMixin).extend({
  name: "DisputeJobDialog",
  props: ["job"],
  components: {
    AppTimeSelect,
    AppTextArea,
    AppDateSelect,
    AppSelect,
    AppButton,
    AppDialog,
    AppGrid,
    AppWorkerSelect,
    AppCard,
  },
  data() {
    return {
      items: DisputeTypesSelect,
      entered: "",
      disputeType: "DEADLINE",
      description: "",
    };
  },
  methods: {
    setNow(): void {
      this.entered = now();
    },
    async disputeJob(): Promise<void> {
      if (this.job.id) {
        const id = await ManagerClient.DisputeJobCommand({
          jobId: this.job.id,
          entered: this.entered,
          disputeType: this.disputeType,
          description: this.description,
        });
        await this.$router.push({
          name: "MDisputeDetail",
          params: { disputeId: id },
        });
      }
    },
  },
});
