const r_factor = 20;
const g_factor = 20;
const b_factor = 20;

export function colorShade(color: string, amount: number): string {
  color = color.replace(/^#/, "");
  if (color.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  const [r1, g1, b1] = [
    parseInt(color.substring(0, 2), 16) + amount * r_factor,
    parseInt(color.substring(2, 4), 16) + amount * g_factor,
    parseInt(color.substring(4, 6), 16) + amount * b_factor,
  ];

  const r2 = Math.max(Math.min(255, r1), 0).toString(16);
  const g2 = Math.max(Math.min(255, g1), 0).toString(16);
  const b2 = Math.max(Math.min(255, b1), 0).toString(16);

  const rr = (r2.length < 2 ? "0" : "") + r2;
  const gg = (g2.length < 2 ? "0" : "") + g2;
  const bb = (b2.length < 2 ? "0" : "") + b2;

  return `#${rr}${gg}${bb}`;
}
