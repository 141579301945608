import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type LoginDto = {
  username: string;
  password: string;
};

export type RefreshDto = {
  token: string;
};

export type LoginData = {
  rememberMe: boolean;
  redirectTo?: string;
} & LoginDto;

export const ENDPOINT = "/login";

export default async function login(dto: LoginDto): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.fromBaseUrl(ENDPOINT).toString(),
    dto
  );
  return await result.headers.authorization;
}
