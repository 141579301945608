import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { SelectProject } from "@/models/Project";

export const ENDPOINT = (): string => `/project/select`;

export default async function selectProjectQuery(): Promise<SelectProject[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT()).toString()
  );
  return await result.data;
}
