var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.projects,"loading":_vm.loading,"item-key":"id","dense":"","data-cy":"MANAGER_PROJECT_TABLE","disable-pagination":true,"disable-filtering":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{staticClass:"table__dense",class:{ abandoned: props.item.abandoned },style:({ color: _vm.colors[props.item.jobState] }),attrs:{"data-cy":"MANAGER_JOB_LINE"},on:{"click":function($event){return _vm.toggleShowJobDetails(props.item.id, true)}}},[_c('td',{staticClass:"text-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.customerNumber)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.id))])])],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.enteredLabel(props.item)))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.dueLabel(props.item)))]),(!props.item.abandoned)?_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(_vm.jobStateLabel(props.item))+" ")]):_c('td',{staticClass:"text-xs-left"},[_c('AppIcon',{attrs:{"i18nLabel":"project.abandoned","icon":"mdi-cancel","color":"#a8a8a8"}})],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.description))]),_c('td',{staticClass:"text-xs-left"},[_c('AppLangInfo',{attrs:{"lang":props.item.source}})],1),_c('td',{staticClass:"text-xs-left"},[_c('AppLangInfo',{attrs:{"lang":props.item.target}})],1),_c('td',{staticClass:"text-xs-left"},[_c('div',{staticClass:"d-flex"},[_c('AppButton',{attrs:{"empty-if":props.item.comments == 0,"icon":"mdi-comment-alert-outline","i18n-label":"project.comments","alt-label":': ' + props.item.comments,"ignore-dark":""}}),_c('span',[_vm._v(" ")]),_c('AppButton',{attrs:{"empty-if":!props.item.openDisputes,"icon":"mdi-flash","i18n-label":"project.openDisputes","color":"red"}})],1)]),_c('td',{staticClass:"text-xs-right"},[_c('AppDetailsButton',{attrs:{"data-cy":"TO_PROJECT_DETAILS","to":{
            name: 'MProjectDetail',
            params: { projectId: props.item.id },
          }}})],1)]),(_vm.selected === props.item.id)?_c('tr',[_c('td',{attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$t("project.jobs")))]),_c('td',{attrs:{"colspan":"8"}},[_c('JobTable',{attrs:{"jobs":_vm.jobs},scopedSlots:_vm._u([{key:"actions",fn:function(jobprops){return [_c('AssignJobDialog',{attrs:{"job":jobprops.item,"ignore-dark":""},on:{"projectChanged":function($event){return _vm.loadJobs(props.item.id)}}}),_c('v-btn',{attrs:{"icon":"","to":{
                name: 'MProjectDetail',
                params: { projectId: props.item.id, jobId: jobprops.item.id },
              }}},[_c('v-icon',[_vm._v("mdi-page-next-outline")])],1)]}}],null,true)})],1)]):_vm._e(),(_vm.selected === props.item.id && _vm.comments.length > 0)?_c('tr',[_c('td',{attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$t("project.comments")))]),_c('td',{attrs:{"colspan":"8"}},[_c('CommentsTable',{attrs:{"comments":_vm.comments}})],1)]):_vm._e()]}},{key:"footer",fn:function(){return [_c('AppPagination',{attrs:{"pagination":_vm.pagination},on:{"pagination":_vm.changePagination}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }