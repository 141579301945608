
















































import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import RequirementMixin from "@/mixins/requirements";
import JobMixin from "@/mixins/job";
import AppCard from "@/components/common/AppCard.vue";
import WorkerClient from "@/client/worker";
import AppDropzone from "@/components/common/AppDropzone.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import AppActionable from "@/components/common/AppActionable.vue";

export default Vue.extend(WorkerMixin)
  .extend(RequirementMixin)
  .extend(JobMixin)
  .extend({
    name: "WorkerJobDeliveryCard",
    props: ["job"],
    data() {
      return {
        checked: {
          TRANSLATE: false,
          PROOFREAD: false,
          APPROVE: false,
          VERIFY: false,
          LAYOUT: false,
          CHECK_100_PERCENT: false,
          CHECK_101_PERCENT: false,
          CHECK_XLT_SEGMENTS: false,
          CONSIDER_COMMENTS: false,
          NOTE_README: false,
          NOTE_REF_FOLDER: false,
          CONSIDER_GLOSSARY: false,
          CONSIDER_TERMINOLOGIES: false,
          EDIT_CORRECTION_COMMENTS: false,
          REVIEW_CORRECTION_COMMENTS: false,
          PERFORM_CORRECTION: false,
          LOCALIZE_LINKS: false,
          PRI_CHECK_RESOURCES: false,
          PMD_INSTRUCTIONS: false,
          PMD_LIST: false,
          MEDAPS_LINKS: false,
          MEDAPS_STYLE_GUIDE: false,
          MEDAPS_TERMINOLOGIES: false,
          VECTRON_LOCK: false,
          VECTRON_MQXLIFF: false,
          CORRECT: false,
          QA_REPORT: false,
          QA_LOCKED: false,
          QA_CHECK: false,
          DELIVER_MQBACK: false,
          DELIVER_XLZS: false,
          DELIVER_QA_REPORT: false,
          DELIVER_PMD: false,
          DELIVER_VECTRON: false,
          PASSOLO_BLUE: false,
          PASSOLO_ALL: false,
        } as Record<string, boolean>,
        file: null as File | null,
      };
    },
    computed: {
      allFullfilled(): boolean {
        return (
          this.jobRequirements
            .map((x) => this.checked[x.identifier])
            .find((x) => x === false) === undefined
        );
      },
      isValid(): boolean {
        return this.file !== null && this.allFullfilled;
      },
    },
    methods: {
      async deliverJob(): Promise<void> {
        await WorkerClient.DeliverJobCommand({
          jobId: this.job.id,
          requirementsChecked: this.requirementsChecked(),
          file: this.file!,
        });
        await this.$router.push({ name: "WorkerJobs" });
      },
      requirementsChecked(): string[] {
        return Object.keys(this.checked).filter(
          (x) => this.checked[x] === true
        );
      },
      async loadRequirements() {
        this.jobRequirements = (
          await WorkerClient.WorkerJobRequirementsQuery(this.job.id)
        ).filter((x) => x.required === true);
        Object.keys(this.checked).forEach((x) => {
          this.checked[x] = false;
        });
      },
      fileSelected(event: FileList): void {
        if (event.length > 0) {
          this.file = event.item(0);
        }
      },
    },
    components: {
      AppActionable,
      AppDropzone,
      AppCard,
      AppConfirmDialog,
    },
    watch: {
      job() {
        this.loadRequirements();
      },
    },
  });
