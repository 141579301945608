



































































































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppButton from "@/components/common/AppButton.vue";
import AssignJobDialog from "@/components/manager/AssignJobDialog.vue";
import ManagerClient from "@/client/manager";
import { UpdateJobDto } from "@/client/manager/UpdateJobCommand";
import { JobTypeSelect } from "@/basic/jobtypes";
import { JobStateSelect } from "@/basic/jobstates";
import DuplicateJobDialog from "@/components/manager/DuplicateJobDialog.vue";
import ProofreadJobDialog from "@/components/manager/ProofreadJobDialog.vue";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppTimeSelect from "@/components/common/AppTimeSelect.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppJobTypeSelect from "@/components/common/AppJobTypeSelect.vue";
import AppJobStateSelect from "@/components/common/AppJobStateSelect.vue";
import AppOrderUnitSelect from "@/components/common/AppOrderUnitSelect.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import AppSeparator from "@/components/common/AppSeparator.vue";
import AppRow from "@/components/common/AppRow.vue";
import AppCol from "@/components/common/AppCol.vue";
import CalculateEffortDialog from "@/components/manager/CalculateEffortDialog.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "JobDetailsCard",
  props: ["project", "job"],
  components: {
    CalculateEffortDialog,
    AppCol,
    AppRow,
    AppSeparator,
    AppConfirmDialog,
    AppOrderUnitSelect,
    AppTextField,
    AppLangSelect,
    ProofreadJobDialog,
    DuplicateJobDialog,
    AppCard,
    AppGrid,
    AppButton,
    AssignJobDialog,
    AppJobTypeSelect,
    AppJobStateSelect,
    AppTimeSelect,
    AppDateSelect,
  },
  data() {
    return {
      jobTypes: JobTypeSelect,
      jobStates: JobStateSelect,
    };
  },
  computed: {
    workerName(): string {
      return this.job.worker
        ? this.job.worker.lastname + " " + this.job.worker.firstname
        : "";
    },
  },
  methods: {
    async saveJob(): Promise<void> {
      await ManagerClient.UpdateJobCommand({
        id: this.job.id,
        due: this.job.due,
        source: this.job.source,
        target: this.job.target,
        jobType: this.job.jobType,
        orderUnit: this.job.orderUnit,
        effort: this.job.effort,
      } as UpdateJobDto);
      this.unselectJob();
      this.projectChanged();
    },
    projectChanged(): void {
      this.$emit("projectChanged");
    },
    unselectJob(): void {
      this.$emit("unselectJob");
    },
    async finishJob(): Promise<void> {
      await ManagerClient.FinishJobCommand({ jobId: this.job.id });
      this.projectChanged();
    },
    async deleteJob(): Promise<void> {
      await ManagerClient.DeleteJobCommand({ jobId: this.job.id });
      this.projectChanged();
    },
    async abandonJob(): Promise<void> {
      await ManagerClient.AbandonJobCommand({ jobId: this.job.id });
      this.projectChanged();
    },
  },
});
