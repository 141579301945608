import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type EnableCustomerDto = {
  customerId: number;
};

export const ENDPOINT = "/customer/enable";

export default async function EnableCustomerCommand(
  dto: EnableCustomerDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
