import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { EdiOrder } from "@/models/Edi";
import { toLocal } from "@/utils/dates";

export const ENDPOINT = (id: number): string => `/invoice/${id}/edi-orders`;

export default async function EdiOrdersQuery(id: number): Promise<EdiOrder[]> {
  const builder = UrlBuilder.mq(ENDPOINT(id));
  const result = await TranslateClient.get(builder.toString());
  const data = result.data;
  data.forEach((x: EdiOrder) => {
    x.received = toLocal(x.received) || x.received;
  });
  return data;
}
