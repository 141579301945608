import Vue from "vue";
import Vuex from "vuex";
import app from "@/main";
import Client from "@/client";
import router, { switchRouteFromRole } from "@/router";
import {
  CHANGE_LANGUAGE,
  LOGIN,
  LOGOUT,
  REFRESH,
  RESET_ERROR,
  SHOW_ERROR,
  ROOT_ACTIONS,
  DIRTY_FIELD,
  DIRTY_DATA,
  DIRTY_ARRAY,
  RESET_SPLASH,
  CHANGE_LOADING,
  CHANGE_COLOR_SCHEME,
  TEST_COLOR_SCHEME,
} from "@/store/actions";
import {
  _CHANGE_LANGUAGE,
  _CHANGE_TOKEN,
  _CHANGE_USER,
  _SET_COLOR_SCHEME,
  _SET_SPLASH_ERROR,
  _SET_VALIDATION_ERRORS,
  _UPDATE_DIRTY,
  _UPDATE_LOADING,
  _UPDATE_ROOT_ACTIONS,
} from "@/store/mutations";
import {
  AppError,
  DirtyArray,
  DirtyData,
  DirtyField,
  ColorSchema,
  RootState,
  ColorSchemeState,
} from "@/store/types";
import { LoginData, RefreshDto } from "@/client/auth/Login";
import { User } from "@/models/User";
import worker from "@/store/worker/worker.module";
import { Action } from "@/models/Action";
import { adminScheme } from "@/mixins/admin";
import { managerScheme } from "@/mixins/manager";
import { workerScheme } from "@/mixins/worker";
import de from "date-fns/locale/de/index.js";
import en from "date-fns/locale/en-US/index.js";
import es from "date-fns/locale/es/index.js";
import { ManagerRoot } from "@/models/ManagerRoot";
Vue.use(Vuex);

const languageValues = ["de", "en", "es"];
const datetimeFormats = [
  "dd.MM.yyyy HH:mm",
  "MM/dd/yyyy hh:mm a",
  "dd/MM/yyyy hh:mm a",
];
const dateFormats = ["dd.MM.yyyy", "MM/dd/yyyy", "dd/MM/yyyy"];
const timeFormats = ["HH:mm", "hh:mm a", "hh:mm a"];
const locales = [de, en, es];

function getColorScheme(
  schema: ColorSchema,
  fallback: ColorSchemeState
): ColorSchemeState {
  const dark = localStorage.getItem(schema + ".dark");
  return {
    mainColor:
      localStorage.getItem(schema + ".mainColor") ?? fallback.mainColor,
    sideColor:
      localStorage.getItem(schema + ".sideColor") ?? fallback.sideColor,
    dark: dark ? dark === "true" : fallback.dark,
  };
}

const store = new Vuex.Store({
  state: {
    user: undefined as undefined | number,
    token: "",
    root: [] as Action[],
    newCounter: 0,
    openCounter: 0,
    allCounter: 0,
    dirtyChecks: {} as Record<string, string | number | boolean>,
    splashError: "",
    errors: {},
    loading: false,
    languageIndex: 0,
    timeFormat: timeFormats[0],
    dateFormat: dateFormats[0],
    datetimeFormat: datetimeFormats[0],
    locale: de,
    colorScheme: workerScheme,
    build: {
      data: "",
      version: "",
    },
  } as RootState,
  mutations: {
    [_UPDATE_ROOT_ACTIONS]: (state: RootState, payload: ManagerRoot): void => {
      state.root = payload.actions;
      state.newCounter = payload.newCounter;
      state.openCounter = payload.openCounter;
      state.allCounter = payload.totalCounter;
      state.abandonedCounter = payload.abandonedCounter;
      state.build.version = payload.build.version;
      state.build.data = payload.build.data;
    },
    [_CHANGE_LANGUAGE]: (state) => {
      state.languageIndex = (state.languageIndex + 1) % languageValues.length;
      state.timeFormat = timeFormats[state.languageIndex];
      state.dateFormat = dateFormats[state.languageIndex];
      state.datetimeFormat = datetimeFormats[state.languageIndex];
      state.locale = locales[state.languageIndex];
      app.$i18n.locale = languageValues[state.languageIndex];
    },
    [_CHANGE_USER]: (state, payload: User | undefined) => {
      state.user = payload;
    },
    [_CHANGE_TOKEN]: (state, payload: string) => {
      if (payload.startsWith("Bearer ")) {
        state.token = payload.substring(7);
      } else {
        state.token = payload;
      }
    },
    [_SET_SPLASH_ERROR]: (state, error: string) => {
      state.splashError = error;
    },
    [_SET_VALIDATION_ERRORS]: (state, errors: Record<string, string>) => {
      state.errors = errors;
    },
    [_UPDATE_DIRTY]: (state, data: DirtyField) => {
      Vue.set(state.dirtyChecks, data.key, data.value);
    },
    [_UPDATE_LOADING]: (state, loading: boolean) => {
      state.loading = loading;
    },
    [_SET_COLOR_SCHEME]: (state, schema: ColorSchemeState) => {
      state.colorScheme = schema;
    },
  },
  actions: {
    [CHANGE_COLOR_SCHEME]({ commit }, schema: ColorSchema) {
      if (schema === ColorSchema.ADMIN) {
        commit(_SET_COLOR_SCHEME, getColorScheme(schema, adminScheme));
      } else if (schema === ColorSchema.MANAGER) {
        commit(_SET_COLOR_SCHEME, getColorScheme(schema, managerScheme));
      } else {
        commit(_SET_COLOR_SCHEME, getColorScheme(schema, workerScheme));
      }
    },
    [TEST_COLOR_SCHEME]({ commit }, schema: ColorSchemeState) {
      commit(_SET_COLOR_SCHEME, schema);
    },
    [CHANGE_LOADING]({ commit }, loading: boolean) {
      commit(_UPDATE_LOADING, loading);
    },
    [DIRTY_FIELD]({ commit }, data: DirtyField) {
      commit(_UPDATE_DIRTY, data);
    },
    [DIRTY_DATA]({ commit }, data: DirtyData) {
      Object.entries(data.data).forEach((entry) => {
        if (entry[0] !== "id" && entry[0] !== "actions") {
          commit(_UPDATE_DIRTY, {
            key: `${data.base}.${data.data.id}.${entry[0]}`,
            value: entry[1],
          } as DirtyField);
        }
      });
    },
    [DIRTY_ARRAY]({ dispatch }, array: DirtyArray) {
      array.data.forEach((data) => {
        dispatch(DIRTY_DATA, {
          base: array.base,
          data: data,
        } as DirtyData);
      });
    },
    [ROOT_ACTIONS]({ commit }, payload: ManagerRoot) {
      commit(_UPDATE_ROOT_ACTIONS, payload);
    },
    [CHANGE_LANGUAGE]({ commit }) {
      commit(_CHANGE_LANGUAGE);
    },
    async [LOGIN]({ commit }, payload: LoginData) {
      try {
        const token = await Client.Login({
          username: payload.username,
          password: payload.password,
        });
        commit(_CHANGE_TOKEN, token);
        const user = await Client.UserByNameQuery(payload.username);
        commit(_CHANGE_USER, user);
        if (payload.rememberMe) {
          localStorage.setItem("user-token", token.replace("Bearer ", ""));
        }
        if (payload.redirectTo) {
          await router.push({ path: payload.redirectTo });
        } else {
          await switchRouteFromRole(user.role);
        }
      } catch (e) {
        console.log(e);
        commit(_SET_SPLASH_ERROR, "Fehler beim Anmelden...");
      }
    },
    async [REFRESH]({ state, commit }, payload: RefreshDto) {
      if (!state.user) {
        try {
          commit(_CHANGE_TOKEN, payload.token);
          const user = await Client.UserByTokenQuery(payload.token);
          commit(_CHANGE_USER, user);
        } catch (e) {
          commit(_SET_SPLASH_ERROR, "Fehler beim Erneuern der Anmeldung...");
        }
      }
    },
    async [LOGOUT]({ commit }) {
      window.localStorage.removeItem("user-token");
      await router.push({ name: "Home" });
      commit(_CHANGE_USER, undefined);
    },
    [SHOW_ERROR]({ commit }, payload: AppError) {
      if (payload.errorMessage) {
        commit(_SET_SPLASH_ERROR, payload.errorMessage);
      }
      if (payload.errorKey === "VALIDATION_ERROR") {
        commit(_SET_VALIDATION_ERRORS, payload.errors);
      }
    },
    [RESET_ERROR]({ commit }) {
      commit(_SET_SPLASH_ERROR, "");
      commit(_SET_VALIDATION_ERRORS, {});
    },
    [RESET_SPLASH]({ commit }) {
      commit(_SET_SPLASH_ERROR, "");
    },
  },
  modules: {
    worker,
  },
});

export default store;
