






















import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import { OrderUnitSelect } from "@/basic/orderunits";

export default Vue.extend(FormElementMixin).extend({
  name: "AppOrderUnitSelect",
  props: ["asAction"],
  data() {
    return {
      orderUnits: OrderUnitSelect,
    };
  },
});
