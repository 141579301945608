


























import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppDialog from "@/components/common/AppDialog.vue";
import { PropType } from "vue";
import { Job } from "@/models/Job";
import AppCheckbox from "@/components/common/AppCheckbox.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "CreateJobMessageDialog",
  props: {
    job: {
      type: Object as PropType<Job>,
    },
  },
  components: {
    AppCheckbox,
    AppDialog,
    AppTextArea,
    AppCard,
  },
  data() {
    return {
      message: "",
      global: false,
    };
  },
  methods: {
    init(): void {
      this.message = "";
      this.global = false;
    },
    async createMessage(): Promise<void> {
      if (this.job.id) {
        await ManagerClient.CreateJobMessageCommand({
          jobId: this.job.id,
          content: this.message,
          global: this.global,
        });
        this.$emit("messagesChanged");
      }
    },
  },
});
