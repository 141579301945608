import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteJobDto = {
  jobId: number;
};

export const ENDPOINT = "/job/delete";

export default async function DeleteJobCommand(
  dto: DeleteJobDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
