











import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import { Route } from "vue-router/types/router";
import EdiPreviewCard from "@/components/manager/EdiPreviewCard.vue";
import { EdiPreview } from "@/models/Edi";
import ManagerClient from "@/client/manager";
import EdiPositionsCard from "@/components/manager/EdiPositionsCard.vue";
import EdiProjectCard from "@/components/manager/EdiProjectCard.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MEdiFile",
  data() {
    return {
      filename: "",
      preview: {} as EdiPreview,
    };
  },
  methods: {
    async loadPreview(): Promise<void> {
      this.preview = await ManagerClient.EDIPreviewQuery(this.filename);
    },
  },

  watch: {
    $route: {
      async handler(val: Route) {
        const filename = val.query?.filename;
        if (Array.isArray(filename)) {
          this.filename = filename[0] || "";
        } else {
          this.filename = filename;
        }
        await this.loadPreview();
      },
      immediate: true,
    },
  },
  components: {
    EdiProjectCard,
    EdiPositionsCard,
    EdiPreviewCard,
  },
});
