




































































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppButton from "@/components/common/AppButton.vue";
import { EdiPreview } from "@/models/Edi";
import AppFileSize from "@/components/common/AppFileSize.vue";
import AppDateLabel from "@/components/common/AppDateLabel.vue";
import AppToggle from "@/components/common/AppToggle.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import AppLabeledData from "@/components/common/AppLabeledData.vue";
import * as date_fns from "date-fns";
import { PropType } from "vue";

export default Vue.extend(ManagerMixin).extend({
  name: "EdiPreviewCard",
  props: {
    filename: {
      type: String,
      required: true,
    },
    preview: {
      type: Object as PropType<EdiPreview>,
    },
  },
  components: {
    AppLabeledData,
    AppConfirmDialog,
    AppToggle,
    AppDateLabel,
    AppFileSize,
    AppCard,
    AppIcon,
    AppButton,
  },
  data: function () {
    return {
      details: false,
    };
  },
  computed: {
    title(): string {
      if (this.preview.test) {
        return (
          this.$t("edi.preview.testMarker") +
          " " +
          this.filename +
          " (" +
          this.preview.type +
          ") " +
          this.$t("edi.preview.testMarker")
        );
      }
      return this.filename + " (" + this.preview.type + ")";
    },
    creationDate(): string {
      if (this.preview.date && this.preview.time) {
        return date_fns.format(
          new Date(
            Number.parseInt(this.preview.date.substring(0, 4)),
            Number.parseInt(this.preview.date.substring(4, 6)),
            Number.parseInt(this.preview.date.substring(6, 8)),
            Number.parseInt(this.preview.time.substring(0, 2)),
            Number.parseInt(this.preview.time.substring(2, 4))
          ),
          this.$store.state.datetimeFormat,
          {
            locale: this.$store.state.locale,
          }
        );
      }
      return "";
    },
    deliveryDate(): string {
      if (this.preview.delivery) {
        return date_fns.format(
          new Date(
            Number.parseInt(this.preview.delivery.substring(0, 4)),
            Number.parseInt(this.preview.delivery.substring(4, 6)),
            Number.parseInt(this.preview.delivery.substring(6, 8))
          ),
          this.$store.state.dateFormat,
          {
            locale: this.$store.state.locale,
          }
        );
      }
      return "";
    },
  },
});
