




import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import EdiListCard from "@/components/manager/EdiListCard.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MEdiList",
  data() {
    return {};
  },
  components: {
    EdiListCard,
  },
});
