


































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import InvoiceMixin from "@/mixins/invoice";
import AppCard from "@/components/common/AppCard.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppJobStateSelect from "@/components/common/AppJobStateSelect.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppTimeSelect from "@/components/common/AppTimeSelect.vue";
import InvoicePositionLine from "@/components/manager/InvoicePositionLine.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import { PropType } from "vue";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import DatesMixin from "@/mixins/dates";
import { Invoice } from "@/models/Invoice";
import { ReceiptPosition } from "@/models/Affiliation";

export default Vue.extend(ManagerMixin)
  .extend(InvoiceMixin)
  .extend(DatesMixin)
  .extend({
    name: "InvoicePositionsCard",
    props: {
      invoice: {
        type: Object as PropType<Invoice>,
        default: () => {
          return {} as Invoice;
        },
      },
      dense: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      AppTextArea,
      AppButton,
      AppCard,
      AppIcon,
      AppGrid,
      AppDateSelect,
      AppTimeSelect,
      AppTextField,
      AppCustomerSelect,
      AppJobStateSelect,
      AppConfirmDialog,
      InvoicePositionLine,
    },
    data() {
      return {};
    },
    computed: {
      matchingSum(): boolean {
        return this.invoice.sum === this.invoice.ediSum;
      },
      diffColor(): string {
        return this.invoice?.diffSum > 0 ? "green" : "red";
      },
    },
    methods: {
      findJoined(jobId: number | undefined): ReceiptPosition | undefined {
        return this.invoice?.joined.find((x) => x.jobId === jobId);
      },
    },
  });
