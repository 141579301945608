


























import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import ManagerClient from "@/client/manager";
import AppDialog from "@/components/common/AppDialog.vue";
import AppRadioButton from "@/components/common/AppRadioButton.vue";
import { PropType } from "vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import { now } from "@/utils/dates";

export default Vue.extend(ManagerMixin).extend({
  name: "BatchConfirmDialog",
  props: {
    selected: {
      type: Array as PropType<number[]>,
    },
  },
  components: {
    AppDateSelect,
    AppRadioButton,
    AppDialog,
  },
  data() {
    return {
      receiptDate: "",
      progress: 0,
      percent: 0,
    };
  },
  computed: {
    valid(): boolean {
      return this.selected && this.selected.length > 0;
    },
  },
  methods: {
    reset(): void {
      this.receiptDate = now();
      this.progress = 0;
      this.percent = 0;
    },
    next(): void {
      this.progress++;
      this.percent = (this.progress / (this.selected?.length || 1)) * 100;
    },
    async confirmDisputes(): Promise<void> {
      try {
        for (let id of this.selected) {
          await ManagerClient.ConfirmInvoiceCommand({
            invoiceId: id,
            receiptDate: this.receiptDate,
          });
        }
      } catch (e: any) {
        // TODO: handle error
        console.log(e);
      }
    },
  },
});
