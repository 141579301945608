import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { DeleteDto } from "@/models/Query";

export const ENDPOINT = "/affiliations/delete-receipt";

export default async function DeleteReceiptCommand(
  dto: DeleteDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
