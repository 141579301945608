import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Customer } from "@/models/Customer";

export const ENDPOINT = (customerId: number): string => `/customer/${customerId}`;

export default async function customerQuery(customerId: number): Promise<Customer> {
  const builder = UrlBuilder.aq(ENDPOINT(customerId));
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
