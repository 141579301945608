






































































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppJobStateSelect from "@/components/common/AppJobStateSelect.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppTimeSelect from "@/components/common/AppTimeSelect.vue";
import ManagerClient from "@/client/manager";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import { PropType } from "vue";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import { Invoice, InvoicePosition } from "@/models/Invoice";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import { SHOW_ERROR } from "@/store/actions";
import { ReceiptPosition } from "@/models/Affiliation";

export default Vue.extend(ManagerMixin).extend({
  name: "InvoicePositionsCard",
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
    },
    position: {
      type: Object as PropType<InvoicePosition>,
      default: () => {
        return {} as InvoicePosition;
      },
    },
    joined: {
      type: Object as PropType<ReceiptPosition>,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppLangSelect,
    AppCheckbox,
    AppTextArea,
    AppButton,
    AppCard,
    AppGrid,
    AppDateSelect,
    AppTimeSelect,
    AppTextField,
    AppCustomerSelect,
    AppJobStateSelect,
    AppConfirmDialog,
  },
  data() {
    return {};
  },
  computed: {
    labels(): Record<string, string> {
      if (this.showLabels) {
        return {
          active: "active",
          position: "position",
          type: "type",
          worker: "worker",
          source: "source",
          target: "target",
          unit: "unit",
          unitType: "unitType",
          positionPrice: "positionPrice",
          totalPrice: "totalPrice",
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    async addPosition(): Promise<void> {
      try {
        await ManagerClient.AddInvoicePositionCommand({
          invoiceId: this.invoice.id,
          jobId: this.position.jobId,
          active: this.position.active,
          position: this.position.position,
          type: this.position.type,
          worker: this.position.description,
          source: this.position.source,
          target: this.position.target,
          unitType: this.position.unitType,
          unit: this.position.unit,
          positionPrice: this.position.positionPrice,
        });
        this.$emit("update-invoice");
      } catch (e: any) {
        await this.$store.dispatch(
          SHOW_ERROR,
          "Konnte Bestellposition nicht hinzufügen"
        );
      }
    },
    async updatePosition(): Promise<void> {
      try {
        await ManagerClient.UpdateInvoicePositionCommand({
          id: this.position.id!,
          active: this.position.active,
          position: this.position.position,
          type: this.position.type,
          worker: this.position.description,
          source: this.position.source,
          target: this.position.target,
          unit: this.position.unit,
          unitType: this.position.unitType,
          positionPrice: this.position.positionPrice,
        });
        this.$emit("update-invoice");
      } catch (e: any) {
        await this.$store.dispatch(
          SHOW_ERROR,
          "Konnte Bestellposition nicht ändern"
        );
      }
    },
    async deletePosition(): Promise<void> {
      try {
        await ManagerClient.DeleteInvoicePositionCommand({
          positionId: this.position.id!,
        });
        this.$emit("update-invoice");
      } catch (e: any) {
        await this.$store.dispatch(
          SHOW_ERROR,
          "Konnte Bestellposition nicht löschen"
        );
      }
    },
    async resetPosition(): Promise<void> {
      try {
        await ManagerClient.ResetInvoicePositionCommand({
          positionId: this.position.id!,
        });
        this.$emit("update-invoice");
      } catch (e: any) {
        await this.$store.dispatch(
          SHOW_ERROR,
          "Konnte Bestellposition nicht zurücksetzen"
        );
      }
    },
  },
});
