












































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import ManagerClient from "@/client/manager";
import { Dispute } from "@/models/Job";
import { TableColData } from "@/models/UI";
import * as date_fns from "date-fns";
import DisputeJobDialog from "@/components/manager/DisputeJobDialog.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "DisputeListCard",
  props: ["job"],
  components: {
    DisputeJobDialog,
    AppCard,
    AppButton,
  },
  data() {
    return {
      selected: undefined as undefined | Dispute,
      disputes: [] as Dispute[],
    };
  },
  computed: {
    loading(): boolean {
      return this.$store.state.loading;
    },
    headers(): TableColData[] {
      return [
        {
          text: this.$t("dispute.entered") as string,
          value: "entered",
          align: "left",
        },
        {
          text: this.$t("dispute.disputeType") as string,
          value: "disputeType",
          align: "left",
        },
        {
          text: this.$t("dispute.measureDone") as string,
          value: "measureDone",
          align: "left",
        },
        {
          text: this.$t("dispute.justlyDisputed") as string,
          value: "justlyDisputed",
          align: "left",
        },
        {
          text: this.$t("dispute.finished") as string,
          value: "finished",
          align: "left",
        },
        {
          text: this.$t("project.actions") as string,
          value: "actions",
          align: "left",
        },
      ];
    },
  },
  methods: {
    async loadDisputes(): Promise<void> {
      this.disputes = await ManagerClient.DisputesQuery(this.job.id);
    },
    enteredLabel(dispute: Dispute): string {
      return this.dateLabel(dispute.entered);
    },
    finishedLabel(dispute: Dispute): string {
      return this.dateLabel(dispute.finished);
    },
    dateLabel(date: string): string {
      if (date) {
        return date_fns.format(
          new Date(date),
          this.$store.state.datetimeFormat,
          {
            locale: this.$store.state.locale,
          }
        );
      }
      return "";
    },
    unselectJob(): void {
      this.$emit("unselectJob");
    },
  },
  watch: {
    job: {
      immediate: true,
      async handler() {
        await this.loadDisputes();
      },
    },
  },
});
