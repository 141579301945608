






















import Vue from "@/translate";
import { RESET_SPLASH } from "@/store/actions";

export default Vue.extend({
  name: "AppError",
  computed: {
    hasError(): boolean {
      return this.$store.state.splashError !== "";
    },
    errorText(): boolean {
      return this.$store.state.splashError;
    },
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    resetError(): void {
      this.show = false;
      this.$nextTick(() => {
        this.$store.dispatch(RESET_SPLASH);
      });
    },
  },
});
