























































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AdminClient from "@/client/admin";
import AppTextField from "@/components/common/AppTextField.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import { UpdateCustomerDto } from "@/client/admin/UpdateCustomerCommand";
import { PropType } from "vue";
import { Customer } from "@/models/Customer";

export default Vue.extend(AdminMixin).extend({
  name: "CustomerDetailsCard",
  props: {
    customer: {
      type: Object as PropType<Customer>,
    },
  },
  components: {
    AppGrid,
    AppCheckbox,
    AppTextField,
    AppCard,
    AppButton,
  },
  methods: {
    async saveCustomer(): Promise<void> {
      await AdminClient.UpdateCustomerCommand({
        id: this.customer.id,
        name: this.customer.name,
        noVAT: this.customer.noVAT,
      } as UpdateCustomerDto);
      this.$emit("customerChanged");
    },
    async deleteCustomer(): Promise<void> {
      await AdminClient.DeleteCustomerCommand({
        customerId: this.customer.id,
      });
      await this.$router.push({ name: "ACustomerList" });
    },
    async disableCustomer(): Promise<void> {
      await AdminClient.DisableCustomerCommand({
        customerId: this.customer.id,
      });
      this.$emit("customerChanged");
    },
    async enableCustomer(): Promise<void> {
      await AdminClient.EnableCustomerCommand({
        customerId: this.customer.id,
      });
      this.$emit("customerChanged");
    },
  },
});
