import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteCommentDto = {
  projectId: number;
  commentId: number;
};

export const ENDPOINT = "/project/delete-comment";

export default async function DeleteCommentCommand(
  dto: DeleteCommentDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
