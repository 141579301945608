import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateCustomerDto = {
  id: number;
  name: string;
  noVAT: boolean;
};

export const ENDPOINT = "/customer/update";

export default async function UpdateCustomerCommand(
  dto: UpdateCustomerDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
