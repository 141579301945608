import { Module } from "vuex";
import { WorkerState } from "@/store/worker/worker.types";
import { JobStats } from "@/models/worker/JobStats";
import { SAVE_STATS } from "@/store/worker/worker.actions";
import { _SAVE_STATS } from "@/store/worker/worker.mutations";

export default {
  namespaced: false,
  state: {
    stats: undefined,
  } as WorkerState,
  getters: {},
  mutations: {
    [_SAVE_STATS]: (state, payload: JobStats) => {
      state.stats = payload;
    },
  },
  actions: {
    async [SAVE_STATS]({ commit }, payload: JobStats) {
      await commit(_SAVE_STATS, payload);
    },
  },
} as Module<WorkerState, unknown>;
