























































































import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import { workerScheme } from "@/mixins/worker";
import WorkerClient from "@/client/worker";
import AppCard from "@/components/common/AppCard.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppButton from "@/components/common/AppButton.vue";
import { Worker } from "@/models/Worker";
import AppColorCard from "@/components/common/AppColorSelectCard.vue";
import { ColorSchema } from "@/store/types";
import AppColorSelectCard from "@/components/common/AppColorSelectCard.vue";
import AppColorDemoCard from "@/components/common/AppColorDemoCard.vue";

export default Vue.extend(WorkerMixin).extend({
  name: "WSettings",
  data() {
    return {
      worker: {} as Worker,
      workerScheme: workerScheme,
      schema: ColorSchema.WORKER,
    };
  },
  components: {
    AppColorDemoCard,
    AppColorSelectCard,
    AppColorCard,
    AppGrid,
    AppTextField,
    AppCard,
    AppButton,
  },
  async mounted() {
    await this.loadWorker();
  },
  methods: {
    async loadWorker(): Promise<void> {
      this.worker = await WorkerClient.WorkerQuery(this.workerId);
    },
    async updateWorker(): Promise<void> {
      try {
        await WorkerClient.UpdateWorkerCommand(this.worker);
        await this.loadWorker();
      } catch (e: any) {
        // TODO: handle error
      }
    },
  },
});
