






















import Vue from "@/translate";
import { PropType } from "vue";
import { JobRequirement } from "@/models/Job";

export default Vue.extend({
  name: "JobRequirementBlock",
  props: {
    category: {
      type: String,
      required: true,
    },
    requirements: {
      type: Array as PropType<JobRequirement[]>,
    },
    showRequiredOnly: {
      type: Boolean,
      default: false,
    },
  },
});
