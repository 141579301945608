import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export const ENDPOINT = "/affiliations/confirm-receipt";

export type ConfirmReceiptDto = {
  receiptId: number;
};

export default async function ConfirmReceiptCommand(
  dto: ConfirmReceiptDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
