import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { EdiPreview } from "@/models/Edi";

export const ENDPOINT = (filename: string): string =>
  `/edi/preview?filename=${filename}`;

export default async function EDIPreviewQuery(
  filename: string
): Promise<EdiPreview> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(filename)).toString()
  );
  return result.data;
}
