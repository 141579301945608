import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type RetryEDIJobDto = {
  filename: string;
};

export const ENDPOINT = "/edi/retry";

export default async function RetryEDIJobCommand(
  dto: RetryEDIJobDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
