import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type FinishInvoiceDto = {
  invoiceId: number;
};

export const ENDPOINT = "/invoice/finish";

export default async function FinishInvoiceCommand(
  dto: FinishInvoiceDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
