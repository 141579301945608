import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateCustomerPriceDto = {
  customerId: number;
  jobType: string;
  orderUnit: string;
  source: string;
  target: string;
  price: number;
};

export const ENDPOINT = "/customer/create-price";

export default async function CreateCustomerPriceCommand(
  dto: CreateCustomerPriceDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.ac(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
