



































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AdminClient from "@/client/admin";
import { SHOW_ERROR } from "@/store/actions";
import { TableColData } from "@/models/UI";
import { AffiliationListItem } from "@/models/Affiliation";

export default Vue.extend(AdminMixin).extend({
  name: "AffiliationTable",
  props: {
    includeDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      affiliations: [] as AffiliationListItem[],
    };
  },
  mounted() {
    this.loadAffiliations();
  },
  computed: {
    loading(): boolean {
      return this.$store.state.loading;
    },
    headers(): TableColData[] {
      return [
        {
          text: this.$t("affiliation.name") as string,
          value: "name",
          align: "left",
        },
        {
          text: this.$t("affiliation.address") as string,
          value: "address",
          align: "left",
        },
        {
          text: this.$t("affiliation.disabled") as string,
          value: "disabled",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("affiliation.actions") as string,
          value: "actions",
          align: "left",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async loadAffiliations(): Promise<void> {
      try {
        this.affiliations = await AdminClient.AffiliationsQuery(
          this.includeDisabled
        );
      } catch (e) {
        await this.$store.dispatch(
          SHOW_ERROR,
          "Konnte Lieferanten nicht laden"
        );
      }
    },
  },
  watch: {
    includeDisabled: {
      handler() {
        this.loadAffiliations();
      },
    },
  },
});
