import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateJobMessageDto = {
  jobId: number;
  content: string;
  global: boolean;
};

export const ENDPOINT = "/job/create-message";

export default async function AddJobCommand(
  dto: CreateJobMessageDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
