






import Vue from "@/translate";

export default Vue.extend({
  name: "AppCol",
  props: {
    cols: {
      type: Number,
      default: undefined,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
});
