

































































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppButton from "@/components/common/AppButton.vue";
import { Filter, SelectData } from "@/models/UI";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppWorkerSelect from "@/components/common/AppWorkerSelect.vue";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import { InvoiceState, InvoiceType } from "@/models/Invoice";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppSelect from "@/components/common/AppSelect.vue";
import ManagerClient from "@/client/manager";
import AppCenterToggle from "@/components/common/AppCenterToggle.vue";
import AppSeparator from "@/components/common/AppSeparator.vue";
import AppRow from "@/components/common/AppRow.vue";
import AppCol from "@/components/common/AppCol.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "InvoiceFilterCard",
  components: {
    AppCol,
    AppRow,
    AppSeparator,
    AppCenterToggle,
    AppSelect,
    AppDateSelect,
    AppCustomerSelect,
    AppWorkerSelect,
    AppCheckbox,
    AppButton,
    AppCard,
    AppTextField,
    AppLangSelect,
  },
  props: {
    full: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      before: "",
      customer: "",
      customerSelectItems: [] as SelectData[],
      typeInvoice: false,
      typeCredit: false,
      projectRef: "",
      invoiceNumber: "",
      receiptNumber: "",
      stateBlocked: false,
      stateRegular: false,
      stateChargeable: false,
      stateCharged: false,
      filters: [] as Filter[],
    };
  },
  computed: {
    hasFilter(): boolean {
      return this.filters.length > 0;
    },
    hasExtendedFilter(): boolean {
      return (
        this.filters
          .filter((x) => x.name != "before")
          .filter((x) => x.name != "customer")
          .filter((x) => x.name != "projectRef")
          .filter((x) => x.name != "invoiceNumber")
          .filter((x) => x.name != "receiptNumber")
          .filter((x) => x.name != "state")
          .filter((x) => x.name != "type").length > 0
      );
    },
  },
  async created() {
    if (this.full) {
      const customers = await ManagerClient.SelectCustomerQuery();
      this.customerSelectItems = customers.map((x) => {
        return {
          value: x.name,
          text: x.name,
        };
      });
      this.stateChargeable = true;
    }
  },
  methods: {
    clearFilter(): void {
      this.customer = "";
      this.before = "";
      this.typeInvoice = false;
      this.typeCredit = false;
      this.projectRef = "";
      this.invoiceNumber = "";
      this.receiptNumber = "";
      this.stateBlocked = false;
      this.stateRegular = false;
      this.stateChargeable = false;
      this.stateCharged = false;
      this.filters = [] as Filter[];
      this.filterChanged();
    },
    filterChanged(): void {
      this.filters = [] as Filter[];
      if (this.before !== "") {
        this.filters.push({
          name: "before",
          value: this.before,
        });
      }
      if (this.customer !== "") {
        this.filters.push({
          name: "customer",
          value: this.customer,
        });
      }
      if (this.typeInvoice || this.typeCredit) {
        const result = [];
        if (this.typeInvoice) {
          result.push(InvoiceType.INVOICE);
        }
        if (this.typeCredit) {
          result.push(InvoiceType.CREDIT);
        }
        this.filters.push({
          name: "type",
          value: result.join(","),
        });
      }
      if (this.projectRef !== "") {
        this.filters.push({
          name: "projectRef",
          value: this.projectRef,
        });
      }
      if (this.invoiceNumber !== "") {
        this.filters.push({
          name: "invoiceNumber",
          value: this.invoiceNumber,
        });
      }
      if (this.receiptNumber !== "") {
        this.filters.push({
          name: "receiptNumber",
          value: this.receiptNumber,
        });
      }
      if (
        this.stateBlocked ||
        this.stateRegular ||
        this.stateChargeable ||
        this.stateCharged
      ) {
        const result = [];
        if (this.stateBlocked) {
          result.push(InvoiceState.BLOCKED);
        }
        if (this.stateRegular) {
          result.push(InvoiceState.REGULAR);
        }
        if (this.stateChargeable) {
          result.push(InvoiceState.CHARGEABLE);
        }
        if (this.stateCharged) {
          result.push(InvoiceState.CHARGED);
        }
        this.filters.push({
          name: "state",
          value: result.join(","),
        });
      }
      this.$emit("filterChanged", this.filters);
      this.open = false;
    },
    expandFilter(): void {
      this.open = true;
    },
    collapseFilter(): void {
      this.open = false;
    },
  },
});
