






import Vue from "@/translate";
import ActionableMixin from "@/mixins/actionable";

export default Vue.extend(ActionableMixin).extend({
  name: "AppActionable",
  computed: {
    disabledByAction(): boolean {
      return !this.hasAction;
    },
  },
});
