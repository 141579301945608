import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DisputeJobDto = {
  jobId: number;
  entered: string;
  disputeType: string;
  description: string;
};

export const ENDPOINT = "/job/dispute";

export default async function DisputeJobCommand(
  dto: DisputeJobDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
