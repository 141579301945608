













































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AdminClient from "@/client/admin";
import { SHOW_ERROR } from "@/store/actions";
import { CustomerListItem } from "@/models/Customer";
import { TableColData } from "@/models/UI";

export default Vue.extend(AdminMixin).extend({
  name: "CustomerTable",
  props: {
    includeDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      customers: [] as CustomerListItem[],
    };
  },
  mounted() {
    this.loadCustomers();
  },
  computed: {
    loading(): boolean {
      return this.$store.state.loading;
    },
    headers(): TableColData[] {
      return [
        {
          text: this.$t("representative.name") as string,
          value: "name",
          align: "left",
        },
        {
          text: this.$t("customer.address") as string,
          value: "address",
          align: "left",
        },
        {
          text: this.$t("customer.receiptAddress") as string,
          value: "receiptAddressId",
          align: "left",
        },
        {
          text: this.$t("customer.disabled") as string,
          value: "disabled",
          align: "left",
        },
        {
          text: this.$t("customer.actions") as string,
          value: "actions",
          align: "left",
        },
      ];
    },
  },
  methods: {
    async loadCustomers(): Promise<void> {
      try {
        this.customers = await AdminClient.CustomersQuery(this.includeDisabled);
      } catch (e) {
        await this.$store.dispatch(
          SHOW_ERROR,
          "Konnte Auftraggeber nicht laden"
        );
      }
    },
  },
  watch: {
    includeDisabled: {
      handler() {
        this.loadCustomers();
      },
    },
  },
});
