import { Store } from "vuex";
import { RootState } from "@/store/types";
import { JobStats } from "@/models/worker/JobStats";
import { CHANGE_LANGUAGE } from "@/store/actions";

const mainColor = "#1fe06f";
const sideColor = "#d2f9e2";
const dark = false;
export const workerScheme = { mainColor, sideColor, dark };

export default {
  methods: {
    async switchLang(this: { $store: Store<RootState> }): Promise<void> {
      await this.$store.dispatch(CHANGE_LANGUAGE);
    },
  },
  computed: {
    timezone(): string {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    mainColor(this: { $store: Store<RootState> }): string {
      return this.$store.state.colorScheme.mainColor;
    },
    sideColor(this: { $store: Store<RootState> }): string {
      return this.$store.state.colorScheme.sideColor;
    },
    dark(this: { $store: Store<RootState> }): boolean {
      return this.$store.state.colorScheme.dark;
    },
    userId(this: { $store: Store<RootState> }): number {
      return this.$store.state.user?.id || -1;
    },
    username(this: { $store: Store<RootState> }): string {
      return this.$store.state.user?.login || "";
    },
    workerId(this: { $store: Store<RootState> }): number {
      return this.$store.state.user?.role.workerId || -1;
    },
    stats(this: { $store: Store<RootState> }): JobStats | undefined {
      // :TODO: entfernen
      return undefined; //this.$store.state.worker.stats;
    },
  },
};
