import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Affiliation } from "@/models/Affiliation";

export const ENDPOINT = (): string => `/affiliations/select`;

export default async function SelectAffiliationsQuery(): Promise<Affiliation[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT()).toString()
  );
  return await result.data;
}
