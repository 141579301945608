import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Job } from "@/models/Job";

export const ENDPOINT = (id: number): string =>
  `/invoice/${id}/unassigned-jobs`;

export default async function unassignedJobsQuery(id: number): Promise<Job[]> {
  const builder = UrlBuilder.mq(ENDPOINT(id));
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
