import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type WorkerSelect = {
  workerId: number;
  firstname: string;
  lastname: string;
  rating?: string;
  utilization?: number;
};

export const ENDPOINT = (jobId: number): string =>
  `/project/possible-workers/${jobId}`;

export default async function PossibleWorkersQuery(
  jobId: number
): Promise<WorkerSelect[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(jobId)).toString()
  );
  return await result.data;
}
