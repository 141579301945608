



















import Vue from "@/translate";
import { CHANGE_LANGUAGE } from "@/store/actions";
import LanguageIcon from "@/components/LanguageIcon.vue";

export default Vue.extend({
  name: "LanguageSwitch",
  components: { LanguageIcon },
  methods: {
    switchLang() {
      this.$store.dispatch(CHANGE_LANGUAGE);
    },
  },
  computed: {
    currentLanguage(): string {
      return this.$i18n.locale;
    },
  },
});
