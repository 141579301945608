



























import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";

export default Vue.extend(FormElementMixin).extend({
  name: "AppTextArea",
  props: {
    rows: {
      type: Number,
      default: 3,
    },
    counter: {
      type: Number,
    },
  },
});
