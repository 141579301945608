




























import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import AppButton from "@/components/common/AppButton.vue";
import AppJobTypeSelect from "@/components/common/AppJobTypeSelect.vue";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppDialog from "@/components/common/AppDialog.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "ProofreadJobDialog",
  props: ["project", "job"],
  components: {
    AppCheckbox,
    AppLangSelect,
    AppJobTypeSelect,
    AppButton,
    AppCard,
    AppDialog,
  },
  data() {
    return {
      dialog: false,
      useResult: false,
    };
  },
  computed: {
    hasResult(): boolean {
      return this.job.result !== null && this.job.result !== "";
    },
  },
  methods: {
    async showDialog(): Promise<void> {
      this.dialog = true;
    },
    closeDialog(): void {
      this.dialog = false;
    },
    async proofreadJob(): Promise<void> {
      const jobId = await ManagerClient.AddProofreadJobCommand({
        projectId: this.project.id,
        jobId: this.job.id,
        useResult: this.useResult,
      });
      this.dialog = false;
      this.$emit("projectChanged");
      this.$emit("selectJob", jobId);
    },
  },
});
