
























import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";

export default Vue.extend(FormElementMixin).extend({
  name: "AppTextField",
  props: ["type", "asAction", "counter"],
  methods: {
    emitInputEvent(event: string): void {
      this.$emit("input", this.type !== "number" ? event : parseInt(event));
    },
    emitSubmitValue(): void {
      this.$emit("submit");
    },
  },
});
