import Login from "../auth/Login";
import UserByNameQuery from "../auth/UserByNameQuery";
import UserByTokenQuery from "../auth/UserByTokenQuery";

import UpdateAdminCommand from "./UpdateAdminCommand";
import AdminQuery from "./AdminQuery";
import CreateCustomerCommand from "./CreateCustomerCommand";
import UpdateCustomerCommand from "./UpdateCustomerCommand";
import DeleteCustomerCommand from "./DeleteCustomerCommand";

import RootQuery from "./RootQuery";
import AddressQuery from "./AddressQuery";
import CustomersQuery from "./CustomersQuery";
import CustomerQuery from "./CustomerQuery";
import CustomerPriceListQuery from "./CustomerPriceListQuery";
import RepresentativeQuery from "./RepresentativeQuery";
import CreateReceiptAddressCommand from "./CreateReceiptAddressCommand";
import UpdateAddressCommand from "./UpdateAddressCommand";
import DeleteAddressCommand from "./DeleteAddressCommand";
import RepresentativeListQuery from "./RepresentativeListQuery";
import DeleteRepresentativeCommand from "./DeleteRepresentativeCommand";
import UpdateRepresentativeCommand from "./UpdateRepresentativeCommand";
import EnableRepresentativeCommand from "./EnableRepresentativeCommand";
import DisableRepresentativeCommand from "./DisableRepresentativeCommand";
import EnableCustomerCommand from "./EnableCustomerCommand";
import DisableCustomerCommand from "./DisableCustomerCommand";
import CreateRepresentativeCommand from "./CreateRepresentativeCommand";
import EmailSettingsQuery from "./EmailSettingsQuery";
import CreateEmailSettingCommand from "./CreateEmailSettingCommand";
import UpdateEmailSettingCommand from "./UpdateEmailSettingCommand";
import DeleteEmailSettingCommand from "./DeleteEmailSettingCommand";
import UpdateSendMailsCommand from "./UpdateSendMailsCommand";
import CreateCustomerPriceCommand from "./CreateCustomerPriceCommand";
import UpdateCustomerPriceCommand from "./UpdateCustomerPriceCommand";
import DeleteCustomerPriceCommand from "./DeleteCustomerPriceCommand";
import AffiliationsQuery from "./AffiliationsQuery";
import AffiliationQuery from "./AffiliationQuery";
import DisableAffiliationCommand from "./DisableAffiliationCommand";
import EnableAffiliationCommand from "./EnableAffiliationCommand";
import UpdateAffiliationCommand from "./UpdateAffiliationCommand";
import DeleteAffiliationCommand from "./DeleteAffiliationCommand";
import CreateAffiliationAddressCommand from "./CreateAffiliationAddressCommand";

export default {
  // Auth
  Login,
  UserByNameQuery,
  UserByTokenQuery,

  // Command
  UpdateAdminCommand,
  CreateCustomerCommand,
  UpdateCustomerCommand,
  DeleteCustomerCommand,
  EnableCustomerCommand,
  DisableCustomerCommand,
  CreateReceiptAddressCommand,
  UpdateAddressCommand,
  DeleteAddressCommand,
  UpdateRepresentativeCommand,
  DeleteRepresentativeCommand,
  EnableRepresentativeCommand,
  DisableRepresentativeCommand,
  CreateRepresentativeCommand,
  CreateEmailSettingCommand,
  UpdateEmailSettingCommand,
  DeleteEmailSettingCommand,
  UpdateSendMailsCommand,
  CreateCustomerPriceCommand,
  UpdateCustomerPriceCommand,
  DeleteCustomerPriceCommand,
  EnableAffiliationCommand,
  DisableAffiliationCommand,
  UpdateAffiliationCommand,
  DeleteAffiliationCommand,
  CreateAffiliationAddressCommand,

  // Query
  AdminQuery,
  RootQuery,
  CustomersQuery,
  CustomerQuery,
  AddressQuery,
  RepresentativeListQuery,
  RepresentativeQuery,
  EmailSettingsQuery,
  CustomerPriceListQuery,
  AffiliationsQuery,
  AffiliationQuery,
};
