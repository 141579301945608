import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type EnableAffiliationDto = {
  affiliationId: number;
};

export const ENDPOINT = "/affiliation/enable";

export default async function EnableAffiliationCommand(
  dto: EnableAffiliationDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
