import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { toUTC } from "@/utils/dates";

export type UpdateJobEffortDto = {
  id: number;

  orderUnit: string;
  effort: number;
};

export const ENDPOINT = "/job/update-effort";

export default async function UpdateJobEffortCommand(
  dto: UpdateJobEffortDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
