






























































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AdminClient from "@/client/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import { CreateCustomerDto } from "@/client/admin/CreateCustomerCommand";
import AppGrid from "@/components/common/AppGrid.vue";

export default Vue.extend(AdminMixin).extend({
  name: "ACustomerCreate",
  data() {
    return {
      customer: {
        name: "",
        noVAT: false,
      } as CreateCustomerDto,
    };
  },
  components: {
    AppGrid,
    AppTextField,
    AppCard,
    AppButton,
    AppCheckbox,
  },
  methods: {
    async createCustomer(): Promise<void> {
      try {
        const id = await AdminClient.CreateCustomerCommand(this.customer);
        await this.$router.push({
          name: "ACustomerDetail",
          params: { customerId: id },
        });
      } catch (e: any) {
        // TODO: handle error
      }
    },
  },
});
