









import Vue from "@/translate";
import ColorsMixin from "@/mixins/color-scheme";

export default Vue.extend(ColorsMixin).extend({
  name: "AppIcon",
  props: {
    i18nLabel: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
  },
  computed: {
    label(): string {
      return this.$t(this.i18nLabel) as string;
    },
  },
});
