import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteInvoicePositionDto = {
  positionId: number;
};

export const ENDPOINT = "/invoice/position/delete";

export default async function DeleteInvoicePositionCommand(
  dto: DeleteInvoicePositionDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
