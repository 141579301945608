import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteInvoiceDto = {
  invoiceId: number;
};

export const ENDPOINT = "/invoice/delete";

export default async function DeleteInvoiceCommand(
  dto: DeleteInvoiceDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
