import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateCommentDto = {
  projectId: number;
  commentId: number;
  content: string;
};

export const ENDPOINT = "/project/update-comment";

export default async function UpdateCommentCommand(
  dto: UpdateCommentDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
