













































































































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AdminClient from "@/client/admin";
import AppTextField from "@/components/common/AppTextField.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import { Representative } from "@/models/Customer";
import { SHOW_ERROR } from "@/store/actions";
import { UpdateRepresentativeDto } from "@/client/admin/UpdateRepresentativeCommand";

export default Vue.extend(AdminMixin).extend({
  name: "RepresentativeDetailsCard",
  components: {
    AppGrid,
    AppCheckbox,
    AppTextField,
    AppCard,
    AppButton,
  },
  data() {
    return {
      representative: {} as Representative,
    };
  },
  async mounted() {
    await this.loadRepresentative();
  },
  computed: {
    isRepresentativeLoaded(): boolean {
      return this.representative?.id !== undefined;
    },
  },
  methods: {
    async loadRepresentative(): Promise<void> {
      try {
        const representativeId = Number.parseInt(
          this.$route.params.representativeId
        );
        this.representative = await AdminClient.RepresentativeQuery(
          representativeId
        );
      } catch (e) {
        await this.$store.dispatch(
          SHOW_ERROR,
          "Konnte Projektleiter nicht laden"
        );
      }
    },
    async saveRepresentative(): Promise<void> {
      await AdminClient.UpdateRepresentativeCommand({
        id: this.representative.id,
        firstname: this.representative.firstname,
        lastname: this.representative.lastname,
        middlename: this.representative.middlename,
        phone: this.representative.phone,
        phone2: this.representative.phone2,
        mobile: this.representative.mobile,
        telefax: this.representative.telefax,

        email: this.representative.email,
        web: this.representative.web,
      } as UpdateRepresentativeDto);
    },
    async deleteRepresentative(): Promise<void> {
      await AdminClient.DeleteRepresentativeCommand({
        representativeId: this.representative.id,
      });
      await this.$router.push({
        name: "ACustomerDetail",
        params: { customerId: this.representative.customerId.toString() },
      });
    },
    async disableRepresentative(): Promise<void> {
      await AdminClient.DisableRepresentativeCommand({
        representativeId: this.representative.id,
      });
      await this.loadRepresentative();
    },
    async enableRepresentative(): Promise<void> {
      await AdminClient.EnableRepresentativeCommand({
        representativeId: this.representative.id,
      });
      await this.loadRepresentative();
    },
  },
});
