import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { toUTC } from "@/utils/dates";

export type UpdateJobDto = {
  id: number;
  due: string;

  source: string;
  target: string;
  jobType: string;

  orderUnit: string;
  effort: number;
};

export const ENDPOINT = "/job/update";

export default async function UpdateJobCommand(
  dto: UpdateJobDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), {
    ...dto,
    due: toUTC(dto.due),
  });
}
