import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { EdiSearch } from "@/models/Edi";

export const ENDPOINT = (searchString: string, docId: string): string =>
  `/edi/search?searchString=${searchString}&docId=${docId}`;

export default async function EDISearchQuery(
  searchString: string,
  docId: string
): Promise<EdiSearch> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(searchString, docId)).toString()
  );
  return result.data;
}
