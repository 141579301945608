



















































































import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import DatesMixin from "@/mixins/dates";
import AppCard from "@/components/common/AppCard.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppButton from "@/components/common/AppButton.vue";
import WorkerClient from "@/client/worker";
import { DIRTY_ARRAY, SHOW_ERROR } from "@/store/actions";
import { Vacation } from "@/models/Vacation";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppActionable from "@/components/common/AppActionable.vue";
import { DirtyArray } from "@/store/types";
import AppTextField from "@/components/common/AppTextField.vue";

export default Vue.extend(WorkerMixin)
  .extend(DatesMixin)
  .extend({
    name: "VacationsCard",
    data() {
      return {
        add: {
          start: "",
          stop: "",
        },
        vacations: [] as Vacation[],
        loading: false,
      };
    },
    async mounted() {
      await this.loadVacations();
    },
    computed: {
      canCreate(): boolean {
        return (
          this.add.start !== "" &&
          this.add.stop !== "" &&
          this.add.start <= this.add.stop
        );
      },
    },
    methods: {
      async loadVacations(): Promise<void> {
        this.loading = true;
        try {
          this.vacations = await WorkerClient.VacationsQuery();
          await this.$store.dispatch(DIRTY_ARRAY, {
            base: "vacation",
            data: this.vacations,
          } as DirtyArray);
        } catch (e) {
          await this.$store.dispatch(SHOW_ERROR, "Konnte Urlaube nicht laden");
        } finally {
          this.loading = false;
        }
      },
      async createVacation(): Promise<void> {
        this.loading = true;
        try {
          await WorkerClient.CreateVacationCommand({
            workerId: this.workerId,
            start: this.addMorning(this.add.start)!,
            stop: this.addMidnight(this.add.stop)!,
            reporter: this.username,
          });
          await this.loadVacations();
          this.add.start = "";
          this.add.stop = "";
        } catch (e) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Urlaube nicht speichern"
          );
        } finally {
          this.loading = false;
        }
      },
      async deleteVacation(vacation: Vacation): Promise<void> {
        this.loading = true;
        try {
          await WorkerClient.DeleteVacationCommand({
            vacationId: vacation.id,
          });
          await this.loadVacations();
        } finally {
          this.loading = false;
        }
      },
      async updateVacation(vacation: Vacation): Promise<void> {
        this.loading = true;
        try {
          await WorkerClient.UpdateVacationCommand({
            vacationId: vacation.id,
            start: vacation.start,
            stop: vacation.stop,
            reporter: this.username,
          });
          await this.loadVacations();
        } finally {
          this.loading = false;
        }
      },
    },
    components: {
      AppTextField,
      AppActionable,
      AppDateSelect,
      AppCard,
      AppGrid,
      AppButton,
    },
  });
