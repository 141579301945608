




































import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import * as date_fns from "date-fns";
import { mergeDate } from "@/utils/dates";

export default Vue.extend(FormElementMixin).extend({
  name: "AppDateSelect",
  data() {
    return {
      show: false,
      date: "",
      fullDate: "",
      formattedDate: "",
    };
  },
  methods: {
    formatDate(date: string): string {
      if (!date) return "";
      return date_fns.format(new Date(date), this.$store.state.dateFormat, {
        locale: this.$store.state.locale,
      });
    },
    getDate(date: string): string {
      if (!date) return "";
      return date_fns.format(new Date(date), "yyyy-MM-dd", {
        locale: this.$store.state.locale,
      });
    },
  },
  watch: {
    date(val) {
      this.formattedDate = this.formatDate(val);
      const merged = mergeDate(this.fullDate, val);
      this.$emit("input", merged);
    },
    value: {
      immediate: true,
      handler(val) {
        this.fullDate = val;
        this.date = this.getDate(val);
      },
    },
  },
});
