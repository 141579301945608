import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type ClearFileDto = {
  jobId: number;
  fileId: number;
};

export const INPUT_ENDPOINT = "/job/clear-input";
export const RESULT_ENDPOINT = "/job/clear-result";

export default async function ClearFileCommand(
  type: string,
  dto: ClearFileDto
): Promise<void> {
  const endpoint = type === "input" ? INPUT_ENDPOINT : RESULT_ENDPOINT;
  await TranslateClient.post(UrlBuilder.mc(endpoint).toString(), dto);
}
