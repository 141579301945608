























































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import { managerScheme } from "@/mixins/manager";
import ManagerClient from "@/client/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppButton from "@/components/common/AppButton.vue";
import { Manager } from "@/models/Manager";
import AppColorCard from "@/components/common/AppColorSelectCard.vue";
import { ColorSchema } from "@/store/types";
import AppColorSelectCard from "@/components/common/AppColorSelectCard.vue";
import AppColorDemoCard from "@/components/common/AppColorDemoCard.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MSettings",
  data() {
    return {
      manager: {} as Manager,
      managerScheme: managerScheme,
      schema: ColorSchema.MANAGER,
    };
  },
  components: {
    AppColorDemoCard,
    AppColorSelectCard,
    AppColorCard,
    AppGrid,
    AppTextField,
    AppCard,
    AppButton,
  },
  async mounted() {
    await this.loadManager();
  },
  methods: {
    async loadManager(): Promise<void> {
      this.manager = await ManagerClient.ManagerQuery(this.managerId);
    },
    async updateManager(): Promise<void> {
      try {
        await ManagerClient.UpdateManagerCommand(this.manager);
        await this.loadManager();
      } catch (e: any) {
        // TODO: handle error
      }
    },
  },
});
