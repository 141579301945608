























import Vue from "@/translate";
import { LanguagesSelect } from "@/basic/languages";
import FormElementMixin from "@/mixins/form-element";

export default Vue.extend(FormElementMixin).extend({
  name: "AppLangSelect",
  props: ["asAction"],
  data() {
    return {
      languages: LanguagesSelect,
    };
  },
});
