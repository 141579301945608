




import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import VacationsCard from "@/components/worker/VacationsCard.vue";

export default Vue.extend(WorkerMixin).extend({
  name: "WVacation",
  components: {
    VacationsCard,
  },
});
