
























import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AffiliationTable from "@/components/admin/AfiliationTable.vue";

export default Vue.extend(AdminMixin).extend({
  name: "AAffiliationList",
  data() {
    return {
      includeDisabled: false,
    };
  },
  components: {
    AffiliationTable,
    AppButton,
    AppCard,
    AppCheckbox,
  },
  methods: {},
});
