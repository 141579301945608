import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Worker } from "@/models/Worker";

export const ENDPOINT = (workerId: number): string => `/settings/${workerId}`;

export default async function WorkerQuery(workerId: number): Promise<Worker> {
  const result = await TranslateClient.get(
    UrlBuilder.wq(ENDPOINT(workerId)).toString()
  );
  return result.data;
}
