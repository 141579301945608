import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { toUTC } from "@/utils/dates";

export type UpdateVacationDto = {
  vacationId: number;
  start: string;
  stop: string;
  reporter: string;
};

export const ENDPOINT = "/vacation/update";

export default async function UpdateVacationCommand(
  dto: UpdateVacationDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.wc(ENDPOINT).toString(), {
    ...dto,
    start: toUTC(dto.start),
    stop: toUTC(dto.stop),
  });
}
