














import Vue from "@/translate";
import SimpleLayout from "@/layouts/SimpleLayout.vue";

export default Vue.extend({
  name: "Error",
  components: {
    SimpleLayout,
  },
});
