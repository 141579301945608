














import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import JobMixin from "@/mixins/job";
import WorkerClient from "@/client/worker";
import { SHOW_ERROR } from "@/store/actions";
import { Job } from "@/models/Job";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import WorkerJobTable from "@/components/worker/WorkerJobTable.vue";

export default Vue.extend(WorkerMixin)
  .extend(JobMixin)
  .extend({
    name: "WorkerInquiredCard",
    data() {
      return {
        jobs: [] as Job[],
      };
    },
    computed: {
      hasJobs(): boolean {
        return this.jobs.length > 0;
      },
    },
    async mounted() {
      await this.loadJobs();
    },
    methods: {
      async loadJobs(): Promise<void> {
        try {
          this.jobs = await WorkerClient.WorkerInquiriesQuery();
        } catch (e) {
          await this.$store.dispatch(SHOW_ERROR, "Konnte Jobs nicht laden");
        }
      },
    },
    components: {
      AppCard,
      AppButton,
      WorkerJobTable,
    },
  });
