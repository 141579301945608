import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export const ENDPOINT = "/edi/import";

export default async function UploadEdiCommand(file: File): Promise<string> {
  const formData = new FormData();
  formData.append("file", file);
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return await result.data.id;
}
