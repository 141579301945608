import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateRepresentativeDto = {
  id: number;
  firstname: string;
  lastname: string;
  middlename: string;
  phone: string;
  phone2: string;
  mobile: string;
  telefax: string;
  email: string;
  web: string;
};

export const ENDPOINT = "/representative/update";

export default async function UpdateRepresentativeCommand(
  dto: UpdateRepresentativeDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
