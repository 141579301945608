import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Comment } from "@/models/Project";

export const ENDPOINT = (projectId: number): string =>
  `/project/${projectId}/comments`;

export default async function CommentsQuery(projectId: number): Promise<Comment[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(projectId)).toString()
  );
  return result.data;
}
