import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type ConfirmInvoiceDto = {
  invoiceId: number;
  receiptDate?: string;
};

export const ENDPOINT = "/invoice/confirm";

export default async function ConfirmInvoiceCommand(
  dto: ConfirmInvoiceDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
