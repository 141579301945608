import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateReceiptAddressDto = {
  customerId: number;
};

export const ENDPOINT = "/address/create-receipt-address";

export default async function CreateReceiptAddressCommand(
  dto: CreateReceiptAddressDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.ac(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
