











import Vue from "@/translate";
import { MailEventSelect } from "@/basic/mailevents";
import FormElementMixin from "@/mixins/form-element";

export default Vue.extend(FormElementMixin).extend({
  name: "AppEventSelect",
  data() {
    return {
      events: MailEventSelect,
    };
  },
});
