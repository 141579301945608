import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateInvoiceDto = {
  projectId: number;
  invoiceType: "INVOICE" | "CREDIT";
  note?: string;
};

export const ENDPOINT = "/invoice/create";

export default async function CreateInvoiceCommand(
  dto: CreateInvoiceDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return result.data.id;
}
