

























































































import Vue from "@/translate";
import ColorsMixin from "@/mixins/color-scheme";
import SimpleLayout from "@/layouts/SimpleLayout.vue";
import LanguageSwitch from "@/components/LanguageSwitch.vue";
import { LOGIN } from "@/store/actions";
import { LoginData } from "@/client/auth/Login";
import AppButton from "@/components/common/AppButton.vue";
import AuthClient from "@/client";
import PasswordForgottenCommand from "@/client/auth/PasswordForgottenCommand";

export default Vue.extend(ColorsMixin).extend({
  name: "Login",
  data() {
    return {
      autologin: true,
      username: "" as string,
      password: "" as string,
      reset: false,
    };
  },
  computed: {
    title(): string {
      return this.reset ? "app.reset.title" : "app.login.title";
    },
    valid(): boolean {
      return this.username !== "" && this.password !== "";
    },
    resetValid(): boolean {
      return this.username !== "";
    },
    redirectTo(): string | undefined {
      if (this.$route.params.redirectTo) {
        return this.$route.params.redirectTo;
      }
      return undefined;
    },
  },
  methods: {
    performLogin(): void {
      const data = {
        username: this.username,
        password: this.password,
        rememberMe: this.autologin,
        redirectTo: this.redirectTo,
      } as LoginData;
      this.$store.dispatch(LOGIN, data);
    },
    async passwordForgotten(): Promise<void> {
      await AuthClient.PasswordForgottenCommand({
        login: this.username,
      });
      await this.$router.push({
        name: "ResetPassword",
        query: { login: this.username },
      });
    },
  },
  components: {
    AppButton,
    LanguageSwitch,
    SimpleLayout,
  },
});
