import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Representative } from "@/models/Customer";

export const ENDPOINT = (representativeId: number): string =>
  `/customer/representative/${representativeId}`;

export default async function representativeQuery(
  representativeId: number
): Promise<Representative> {
  const builder = UrlBuilder.aq(ENDPOINT(representativeId));
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
