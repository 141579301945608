import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateProjectDto = {
  referenceNumber: string;
  customerNumber: string;
  description: string;
  representativeId: number;
};

export const ENDPOINT = "/project/create";

export default async function CreateProjectCommand(
  dto: CreateProjectDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
