import { Position } from "@/models/Common";
import { ReceiptPosition } from "@/models/Affiliation";

export enum InvoiceType {
  INVOICE = "INVOICE",
  CREDIT = "CREDIT",
}

export enum InvoiceState {
  BLOCKED = "BLOCKED",
  REGULAR = "REGULAR",
  CHARGEABLE = "CHARGEABLE",
  CHARGED = "CHARGED",
}

export type Invoice = {
  id: number;
  type: string;
  state: string;
  projectId: number;
  positions: InvoicePosition[];
  positionsCount: number;

  joined: ReceiptPosition[];
  joinedSum: number;
  diffSum: number;

  projectRef: string;
  customerRef: string;
  managerRef: string;

  invoiceNumber: string;
  receiptNumber: string;
  deliveryDate: string;
  receiptDate: string;

  sum: string;
  ediSum: string;
  note: string;

  mailed: string;
};

export type InvoicePosition = Position;
