import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DisableAffiliationDto = {
  affiliationId: number;
};

export const ENDPOINT = "/affiliation/disable";

export default async function DisableAffiliationCommand(
  dto: DisableAffiliationDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
