

















import Vue from "@/translate";

export default Vue.extend({
  name: "LanguageIcon",
  computed: {
    currentLanguage(): string {
      return this.$i18n.locale;
    },
  },
});
