import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Dispute } from "@/models/Job";
import { toLocal } from "@/utils/dates";

export const ENDPOINT = (disputeId: number): string => `/dispute/${disputeId}`;

export default async function DisputeQuery(
  disputeId: number
): Promise<Dispute> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(disputeId)).toString()
  );

  const dispute = result.data;
  return {
    ...dispute,
    entered: toLocal(dispute.entered),
    finished: toLocal(dispute.finished),
  };
}
