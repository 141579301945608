import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateCustomerPriceDto = {
  id: number;
  jobType: string;
  orderUnit: string;
  source: string;
  target: string;
  price: number;
};

export const ENDPOINT = "/customer/update-price";

export default async function UpdateCustomerPriceCommand(
  dto: UpdateCustomerPriceDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
