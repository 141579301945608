import * as date_fns from "date-fns";
import * as date_fns_tz from "date-fns-tz";

const ISO = "yyyy-MM-dd'T'HH:mm:ssXXX";

export function nowDate(
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  const now = new Date();
  return date_fns_tz.formatInTimeZone(now, timezone, "yyyy-MM-dd");
}

export function now(
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  const now = new Date();
  return date_fns_tz.formatInTimeZone(
    now,
    timezone,
    "yyyy-MM-dd'T'HH:mm:ssXXX"
  );
}

export function toLocal(
  utc: string | undefined,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
): string | undefined {
  if (utc) {
    return date_fns_tz.formatInTimeZone(
      utc,
      timezone,
      "yyyy-MM-dd'T'HH:mm:ssXXX"
    );
  }
  return utc;
}

export function toUTC(
  local: string | undefined,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
): string | undefined {
  if (local) {
    const origin = new Date(local);
    const utc = date_fns_tz.zonedTimeToUtc(origin, timezone);
    return utc.toISOString();
  }
  return local;
}

export function addMorning(
  local: string | undefined,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
): string | undefined {
  if (local !== undefined) {
    const morning = date_fns.startOfDay(new Date(local));
    return date_fns_tz.formatInTimeZone(morning, timezone, ISO);
  }
  return undefined;
}

export function addMidnight(
  local: string | undefined,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
): string | undefined {
  if (local !== undefined) {
    const midnight = date_fns.endOfDay(new Date(local)).setMilliseconds(0);
    return date_fns_tz.formatInTimeZone(midnight, timezone, ISO);
  }
  return undefined;
}

export function mergeDate(
  iso: string | undefined,
  date: string | undefined
): string | undefined {
  if (date !== undefined) {
    if (iso !== undefined && iso !== null) {
      return date + iso.substring(date.length);
    } else {
      return date + "T00:00:00Z";
    }
  }
  return undefined;
}

export function mergeTime(
  iso: string | undefined,
  time: string | undefined
): string | undefined {
  if (iso !== undefined && time !== undefined) {
    return iso.substring(0, 11) + time + iso.substring(16);
  }
  return iso;
}
