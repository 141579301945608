import VueI18n from "@/i18n";

export enum JobTypes {
  TRANSLATION,
  PROOFREADING,
  APPROVE,
  VERIFICATION,
  TRANSLATION_PROOFREAD_NC,
  TRANSLATION_PROOFREAD_AC,
  LAYOUT,
}

export type JobTypesType = {
  TRANSLATION: number;
  PROOFREADING: number;
  APPROVE: number;
  VERIFICATION: number;
  TRANSLATION_PROOFREAD_NC: number;
  TRANSLATION_PROOFREAD_AC: number;
  LAYOUT: number;
};

export const JobTypesFactors = {
  TRANSLATION: 1,
  PROOFREADING: 0.3,
  APPROVE: 0.2,
  VERIFICATION: 1.2,
  TRANSLATION_PROOFREAD_NC: 1,
  TRANSLATION_PROOFREAD_AC: 1.3,
  LAYOUT: 1,
} as JobTypesType;

type JobTypeSelectData = {
  text: string;
  value: string;
};

const JobTypeSelect = [] as JobTypeSelectData[];
for (const type in JobTypes) {
  const isValueProperty = parseInt(type, 10) >= 0;
  if (isValueProperty) {
    JobTypeSelect.push({
      value: JobTypes[type],
      text: VueI18n.t("common.jobTypes." + JobTypes[type]) as string,
    });
  }
}
export { JobTypeSelect };
