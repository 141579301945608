export const CHANGE_LANGUAGE = "Sprache wechseln";
export const LOGIN = "Anmelden";
export const REFRESH = "Anmeldung erneuern";
export const LOGOUT = "Abmelden";
export const SHOW_ERROR = "Fehler anzeigen";
export const RESET_ERROR = "Fehler zurücksetzen";
export const RESET_SPLASH = "Splash-Fehler zurücksetzen";
export const ROOT_ACTIONS = "Globale Aktionen";
export const DIRTY_FIELD = "Zwischenspeicher für einzelnes Feld";
export const DIRTY_DATA = "Zwischenspeicher für ein Object";
export const DIRTY_ARRAY = "Zwischenspeicher für eine Liste von Objekten";
export const CHANGE_LOADING = "Laden starten bzw. beenden";
export const CHANGE_COLOR_SCHEME = "FarbScheme wechseln";
export const TEST_COLOR_SCHEME = "FarbSchema testen";
