








































































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AdminClient from "@/client/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import { PropType } from "vue";
import { Customer } from "@/models/Customer";
import { CreateRepresentativeDto } from "@/client/admin/CreateRepresentativeCommand";

export default Vue.extend(AdminMixin).extend({
  name: "ARepresentativeCreate",
  props: {
    customer: {
      type: Object as PropType<Customer>,
    },
  },
  data() {
    return {
      representative: {} as CreateRepresentativeDto,
    };
  },
  components: {
    AppGrid,
    AppTextField,
    AppCard,
    AppButton,
    AppCheckbox,
  },
  methods: {
    async createRepresentative(): Promise<void> {
      try {
        this.representative.customerId = this.customer.id;
        const id = await AdminClient.CreateRepresentativeCommand(
          this.representative
        );
        await this.$router.push({
          name: "ARepresentativeDetail",
          params: { representativeId: id },
        });
      } catch (e: any) {
        // TODO: handle error
      }
    },
  },
});
