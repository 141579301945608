import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AcceptJobDto = {
  jobId: number;
};

export const ENDPOINT = "/job/accept";

export default async function AcceptJobCommand(
  dto: AcceptJobDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.wc(ENDPOINT).toString(), dto);
}
