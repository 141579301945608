import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteEmailSettingDto = {
  emailSettingId: number;
};

export const ENDPOINT = "/email-settings/delete";

export default async function DeleteCustomerCommand(
  dto: DeleteEmailSettingDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
