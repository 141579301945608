import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type ResetPasswordDto = {
  login: string;
};

export const ENDPOINT = "/password-forgotten";

export default async function PasswordForgottenCommand(
  dto: ResetPasswordDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.uc(ENDPOINT).toString(), dto);
}
