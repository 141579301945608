




























import Vue from "@/translate";
import AppButton from "@/components/common/AppButton.vue";
import { downloadJobFile } from "@/client/client";
import { PropType } from "vue";
import { Job } from "@/models/Job";
import ManagerClient from "@/client/manager";

export default Vue.extend({
  name: "AppDownload",
  props: {
    fileId: {
      type: Number,
    },
    fileName: {
      type: String,
    },
    job: {
      type: Object as PropType<Job>,
    },
    action: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
    downloaded: {
      type: Boolean,
    },
    workerId: {
      type: Number,
    },
    noClear: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppButton,
  },
  data() {
    return {};
  },
  methods: {
    download(): string {
      return downloadJobFile(
        this.job.id,
        this.fileId,
        this.type,
        this.workerId
      );
    },
    async clearFile(): Promise<void> {
      await ManagerClient.ClearFileCommand(this.type, {
        jobId: this.job.id,
        fileId: this.fileId,
      });
      this.$emit("filesChanged");
    },
  },
});
