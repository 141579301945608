








import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import WorkerInfoCard from "@/components/worker/WorkerInfoCard.vue";
import WorkerInquiredCard from "@/components/worker/WorkerInquiredCard.vue";
import WorkerActiveCard from "@/components/worker/WorkerActiveCard.vue";

export default Vue.extend(WorkerMixin).extend({
  name: "WJobList",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  components: {
    WorkerInfoCard,
    WorkerActiveCard,
    WorkerInquiredCard,
  },
});
