

























import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import InvoiceMixin from "@/mixins/invoice";
import AppCard from "@/components/common/AppCard.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppJobStateSelect from "@/components/common/AppJobStateSelect.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppTimeSelect from "@/components/common/AppTimeSelect.vue";
import InvoicePositionLine from "@/components/manager/InvoicePositionLine.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import DatesMixin from "@/mixins/dates";
import { PropType } from "vue";
import { Invoice, InvoicePosition } from "@/models/Invoice";
import ManagerClient from "@/client/manager";
import { Job } from "@/models/Job";

export default Vue.extend(ManagerMixin)
  .extend(InvoiceMixin)
  .extend(DatesMixin)
  .extend({
    name: "AddInvoicePositionCard",
    props: {
      invoice: {
        type: Object as PropType<Invoice>,
      },
    },
    data() {
      return {
        unassigned: [] as Job[],
        position: {
          invoiceId: 0,
          id: undefined,
          jobId: undefined,
          active: true,
          position: 1,
          type: "",
          description: "",
          source: "",
          target: "",
          unit: 1,
          unitType: "",
          positionPrice: 1,
          totalPrice: 1,
        },
      };
    },
    computed: {
      unassignedPositions(): InvoicePosition[] {
        return this.unassigned.map((x) => {
          return {
            invoiceId: this.invoice.id,
            id: undefined,
            jobId: x.id,
            active: true,
            position: 0,
            type: this.$t("common.jobTypes." + x.jobType) as string,
            description: x.worker?.firstname + " " + x.worker?.lastname,
            source: x.source.replace("_", "-"),
            target: x.target.replace("_", "-"),
            unit: x.effort,
            unitType: this.$t("common.orderUnits." + x.orderUnit) as string,
            positionPrice: 0,
          };
        });
      },
    },
    components: {
      AppTextArea,
      AppButton,
      AppCard,
      AppGrid,
      AppDateSelect,
      AppTimeSelect,
      AppTextField,
      AppCustomerSelect,
      AppJobStateSelect,
      AppConfirmDialog,
      InvoicePositionLine,
    },
    methods: {
      async loadUnassignedJobs(): Promise<void> {
        this.unassigned = await ManagerClient.UnassignedJobsQuery(
          this.invoice.id
        );
      },
      resetFields(): void {
        this.position = {
          invoiceId: 0,
          id: undefined,
          jobId: undefined,
          active: true,
          position: 1,
          type: "",
          description: "",
          source: "",
          target: "",
          unit: 1,
          unitType: "",
          positionPrice: 1,
          totalPrice: 1,
        };
      },
      updateInvoice(event: any): void {
        this.resetFields();
        this.$emit("update-invoice", event);
      },
    },
    watch: {
      invoice: {
        immediate: true,
        handler(val: Invoice) {
          if (val && val.id) {
            this.loadUnassignedJobs();
          }
        },
      },
    },
  });
