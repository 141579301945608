import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteVacationDto = {
  vacationId: number;
};

export const ENDPOINT = "/vacation/delete";

export default async function DeleteVacationCommand(
  dto: DeleteVacationDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.wc(ENDPOINT).toString(), dto);
}
