import VueI18n from "@/i18n";

export enum MailEvents {
  JOB_INQUIRED,
  JOB_ACCEPTED,
  JOB_REJECTED,
  JOB_RESULT,
  JOB_DOWNLOADED,
  VACATION,
}

type MailEventData = {
  text: string;
  value: string;
};

const MailEventSelect = [] as MailEventData[];
for (const state in MailEvents) {
  const isValueProperty = parseInt(state, 10) >= 0;
  if (isValueProperty) {
    MailEventSelect.push({
      value: MailEvents[state],
      text: VueI18n.t("mailEventTypes." + MailEvents[state]) as string,
    });
  }
}
export { MailEventSelect };
