import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CloseDisputeDto = {
  disputeId: number;
  justlyDisputed?: boolean;
};

export const ENDPOINT = "/dispute/close";

export default async function UpdateDisputeCommand(
  dto: CloseDisputeDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
