import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateEmailSettingDto = {
  id: number;
  active: boolean;
  email: string;
  eventType: string;
};

export const ENDPOINT = "/email-settings/update";

export default async function UpdateEmailSettingCommand(
  dto: UpdateEmailSettingDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
