























import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ReceiptPositionLine from "@/components/manager/ReceiptPositionLine.vue";
import AppButton from "@/components/common/AppButton.vue";
import { PropType } from "vue";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import DatesMixin from "@/mixins/dates";
import { Receipt } from "@/models/Affiliation";
import { InvoicePosition } from "@/models/Invoice";

export default Vue.extend(ManagerMixin)
  .extend(DatesMixin)
  .extend({
    name: "ReceiptPositionsCard",
    props: {
      receipt: {
        type: Object as PropType<Receipt>,
        default: () => {
          return {} as Receipt;
        },
      },
      dense: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      diffColor(): string {
        return this.receipt?.diffSum > 0 ? "green" : "red";
      },
    },
    methods: {
      findJoined(jobId: number | undefined): InvoicePosition | undefined {
        return this.receipt?.joined.find((x) => x.jobId === jobId);
      },
    },
    components: {
      AppTextArea,
      AppButton,
      AppCard,
      AppConfirmDialog,
      ReceiptPositionLine,
    },
  });
