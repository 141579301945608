




import Vue from "@/translate";

export default Vue.extend({
  name: "AppSeparator",
  props: {
    color: {
      type: String,
      default: "dimgray",
    },
  },
});
