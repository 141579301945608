import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type BlockInvoiceDto = {
  invoiceId: number;
};

export const ENDPOINT = "/invoice/block";

export default async function BlockInvoiceCommand(
  dto: BlockInvoiceDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
