







































import Vue from "@/translate";
import AppDialog from "@/components/common/AppDialog.vue";
import ActionableMixin from "@/mixins/actionable";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppOrderUnitSelect from "@/components/common/AppOrderUnitSelect.vue";
import AppJobTypeSelect from "@/components/common/AppJobTypeSelect.vue";
import { PropType } from "vue";
import { Customer, CustomerPrice } from "@/models/Customer";
import { Actionable } from "@/models/Action";

export default Vue.extend(ActionableMixin).extend({
  name: "CustomerPriceDialog",
  props: {
    actionable: {
      type: Object as PropType<Actionable>,
    },
    customer: {
      type: Object as PropType<Customer>,
    },
    price: {
      type: Object as PropType<CustomerPrice>,
    },
    action: {
      type: String,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  components: {
    AppJobTypeSelect,
    AppOrderUnitSelect,
    AppTextField,
    AppLangSelect,
    AppDialog,
  },
  data() {
    return {
      editedItem: {} as CustomerPrice,
    };
  },
  computed: {
    isValid(): boolean {
      const hasData =
        this.editedItem.jobType != "" && this.editedItem.orderUnit != "";
      return hasData && this.editedItem.price > 0;
    },
  },
  methods: {
    performAction(): void {
      this.$emit("performAction", {
        ...this.editedItem,
        customerId: this.customer?.id,
      });
    },
    initDialog(): void {
      this.editedItem = {
        ...this.price,
      };
    },
  },
});
