












import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import { PropType } from "vue";

export default Vue.extend(FormElementMixin).extend({
  name: "AppRadioButton",
  props: {
    items: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  computed: {
    labels(): string[] {
      const data = [] as string[];
      for (const item in this.items) {
        data.push(
          this.$t(
            this.i18nBase + "." + this.field + "." + this.items[item]
          ) as string
        );
      }
      return data;
    },
  },
});
