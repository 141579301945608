




import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import RepresentativeDetailsCard from "@/components/admin/RepresentativeDetailsCard.vue";

export default Vue.extend(AdminMixin).extend({
  name: "ARepresentativeDetail",
  components: {
    RepresentativeDetailsCard,
  },
});
