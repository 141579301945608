import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AbandonProjectDto = {
  projectId: number;
};

export const ENDPOINT = "/project/abandon";

export default async function AbandonJobCommand(
  dto: AbandonProjectDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
