import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { User } from "@/models/User";

export const ENDPOINT = "/q/user/byname";

export default async function get(name: string): Promise<User> {
  const result = await TranslateClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT).addDirectory(btoa(name)).toString()
  );
  return await result.data;
}
