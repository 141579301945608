
















































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import ManagerClient from "@/client/manager";
import { Job, JobLogMessage } from "@/models/Job";
import { PropType } from "vue";
import * as date_fns from "date-fns";
import AppRow from "@/components/common/AppRow.vue";
import AppCol from "@/components/common/AppCol.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "JobLogMessagesCard",
  props: {
    job: {
      type: Object as PropType<Job>,
    },
  },
  components: {
    AppCol,
    AppRow,
    AppCard,
    AppButton,
  },
  data() {
    return {
      messages: [] as JobLogMessage[],
    };
  },
  methods: {
    unselectJob(): void {
      this.$emit("unselectJob");
    },
    async loadLogMessages(): Promise<void> {
      this.messages = await ManagerClient.JobLogMessagesQuery(this.job.id);
    },
    createdLabel(message: JobLogMessage): string {
      return date_fns.format(
        new Date(message.created),
        this.$store.state.datetimeFormat,
        {
          locale: this.$store.state.locale,
        }
      );
    },
    createContent(message: JobLogMessage): string {
      return this.$t(
        "jobLogMessageType." + message.type,
        message.parsedContent
      ) as string;
    },
  },
  watch: {
    job: {
      immediate: true,
      async handler() {
        this.messages = [];
      },
    },
  },
});
