import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type ResetPasswordDto = {
  login: string;
  code: string;
  password: string;
};

export const ENDPOINT = "/reset-password";

export default async function ResetPasswordCommand(
  dto: ResetPasswordDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.uc(ENDPOINT).toString(), dto);
}
