import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateJobRequirementsDto = {
  jobId: number;
  selectedIdentifiers: string[];
};

export const ENDPOINT = "/job/update-requirements";

export default async function UpdateJobRequirementsCommand(
  dto: UpdateJobRequirementsDto
): Promise<void> {
  return await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
