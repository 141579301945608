export default {
  props: {
    value: {
      type: [String, Number, Boolean],
    },
    id: {
      type: Number,
      default: undefined,
    },
    i18nBase: {
      type: String,
    },
    i18nField: {
      type: String,
    },
    field: {
      type: String,
    },
    changedField: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errors(this: { $store: any }): Record<string, string> {
      return this.$store.state.errors;
    },
    error(this: {
      $t: (key: string) => string;
      showError: boolean;
      errors: Record<string, string>;
      field: string;
    }): string | undefined {
      if (this.showError) {
        return this.$t("common.validation." + this.errors[this.field]);
      } else {
        return undefined;
      }
    },
    showError(this: {
      errors: Record<string, string>;
      field: string;
    }): boolean {
      return this.errors[this.field] !== undefined;
    },
    label(this: {
      $t: (key: string) => string;
      errors: Record<string, string>;
      field: string;
      i18nBase: string;
      i18nField: string;
      required: boolean;
    }): string | undefined {
      if (this.i18nBase && (this.field || this.i18nField)) {
        const relevant = this.i18nField ? this.i18nField : this.field;
        return (
          this.$t(this.i18nBase + "." + relevant) + (this.required ? " *" : "")
        );
      }
      return undefined;
    },
    original(this: {
      id: number;
      i18nBase: string;
      field: string;
      changedField: string;
      $store: any;
    }): string | undefined {
      const relevantField = this.changedField ? this.changedField : this.field;
      const key = `${this.i18nBase}.${this.id}.${relevantField}`;
      return this.$store.state.dirtyChecks[key];
    },
    changed(this: {
      i18nBase: string;
      field: string;
      changedField: string;
      original: string | number | boolean;
      value: string | number | boolean;
      $store: any;
    }): boolean {
      const relevantField = this.changedField ? this.changedField : this.field;
      if (this.i18nBase && relevantField) {
        if (this.original === undefined) {
          return false;
        }
        return this.value !== this.original;
      }
      return false;
    },
    hint(this: {
      $t: (key: string) => string;
      changed: boolean;
    }): string | undefined {
      return this.changed
        ? (this.$t("common.validation.NotSaved") as string)
        : undefined;
    },
    color(this: { changed: boolean }): string | undefined {
      return this.changed ? "rgb(161, 191, 247,  0.57)" : undefined;
    },
    appendIcon(this: { changed: boolean }): string | undefined {
      return this.changed ? "mdi-lock-reset" : "mdi-blank";
    },
  },
  methods: {
    resetToOriginal(this: { $emit: any; original: string | undefined }): void {
      this.$emit("input", this.original);
    },
  },
};
