import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { WorkerSelect } from "@/client/manager/PossibleWorkersQuery";

export const ENDPOINT = (): string => `/project/all-workers`;

export default async function AllWorkersQuery(): Promise<WorkerSelect[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT()).toString()
  );
  return await result.data;
}
