




































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import ManagerClient from "@/client/manager";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppSelect from "@/components/common/AppSelect.vue";
import { SelectData } from "@/models/UI";
import AppRadioButton from "@/components/common/AppRadioButton.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MInvoiceCreate",
  data() {
    return {
      selectProject: [] as SelectData[],
      projectId: -1,
      invoiceType: "INVOICE",
      note: "",
    };
  },
  components: {
    AppRadioButton,
    AppSelect,
    AppGrid,
    AppTextArea,
    AppTextField,
    AppCard,
    AppButton,
    AppCustomerSelect,
  },
  async created() {
    const data = await ManagerClient.SelectProjectQuery();
    this.selectProject = data.map((x) => {
      return {
        value: x.id,
        text: x.customerNumber + " / " + x.referenceNumber,
      } as SelectData;
    });
  },
  computed: {
    valid(): boolean {
      return this.projectId !== -1;
    },
  },
  methods: {
    async createInvoice(): Promise<void> {
      const invoice = {
        projectId: this.projectId,
        invoiceType: this.invoiceType,
        note: this.note,
      } as any;
      try {
        const id = await ManagerClient.CreateInvoiceCommand(invoice);
        await this.$router.push({
          name: "MInvoiceDetail",
          params: { invoiceId: id },
        });
      } catch (e: any) {
        // TODO: handle error
      }
    },
  },
});
