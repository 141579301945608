









































































import Vue from "@/translate";
import AppCard from "@/components/common/AppCard.vue";
import { PropType } from "vue";
import { ColorSchemeState } from "@/store/types";
import AppButton from "@/components/common/AppButton.vue";
import { CHANGE_COLOR_SCHEME, TEST_COLOR_SCHEME } from "@/store/actions";
import AppSeparator from "@/components/common/AppSeparator.vue";
import AppRadioButton from "@/components/common/AppRadioButton.vue";

export default Vue.extend({
  name: "AppColorSelectCard",
  components: { AppRadioButton, AppSeparator, AppButton, AppCard },
  props: {
    schema: {
      type: String,
      required: true,
    },
    fallback: {
      type: Object as PropType<ColorSchemeState>,
      required: true,
    },
  },
  data() {
    return {
      mainColor: "",
      sideColor: "",
      dark: false,
      localStore: {
        mainColor: "" as string | null,
        sideColor: "" as string | null,
        dark: null as string | null,
      },
    };
  },
  mounted() {
    this.loadColors();
  },
  computed: {},
  methods: {
    testColors(): void {
      this.$store.dispatch(TEST_COLOR_SCHEME, {
        mainColor: this.mainColor,
        sideColor: this.sideColor,
        dark: this.dark,
      });
    },
    saveColors(): void {
      localStorage.setItem(this.schema + ".mainColor", this.mainColor);
      localStorage.setItem(this.schema + ".sideColor", this.sideColor);
      localStorage.setItem(this.schema + ".dark", this.dark ? "true" : "false");
      this.$store.dispatch(CHANGE_COLOR_SCHEME, this.schema);
      this.loadColors();
    },
    loadColors(): void {
      this.localStore.mainColor = localStorage.getItem(
        this.schema + ".mainColor"
      );
      this.localStore.sideColor = localStorage.getItem(
        this.schema + ".sideColor"
      );
      this.localStore.dark = localStorage.getItem(this.schema + ".dark");
      this.mainColor = this.localStore.mainColor ?? this.fallback.mainColor;
      this.sideColor = this.localStore.sideColor ?? this.fallback.sideColor;
      this.dark = this.localStore.dark
        ? this.localStore.dark === "true"
        : this.fallback.dark;
    },
    resetToCurrent(): void {
      this.$store.dispatch(CHANGE_COLOR_SCHEME, this.schema);
    },
    resetToDefaults(): void {
      localStorage.removeItem(this.schema + ".mainColor");
      localStorage.removeItem(this.schema + ".sideColor");
      localStorage.removeItem(this.schema + ".dark");
      this.$store.dispatch(CHANGE_COLOR_SCHEME, this.schema);
      this.loadColors();
    },
  },
});
