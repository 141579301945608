import { JobRequirement } from "@/models/Job";

export default {
  data() {
    return {
      showRequiredOnly: false,
      jobRequirements: [] as JobRequirement[],
    };
  },
  computed: {
    categories(this: {
      jobRequirements: JobRequirement[];
      showRequiredOnly: boolean;
    }): string[] {
      return this.jobRequirements
        .filter((x) => !this.showRequiredOnly || x.required)
        .map((x) => x.category)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
  },
  methods: {
    identifiers(
      this: { jobRequirements: JobRequirement[] },
      category: string
    ): string[] {
      return this.jobRequirements
        .filter((x) => x.category === category)
        .map((x) => x.identifier);
    },
    requirements(
      this: { jobRequirements: JobRequirement[] },
      category: string
    ): JobRequirement[] {
      return this.jobRequirements.filter((x) => x.category === category);
    },
    requirement(
      this: { jobRequirements: JobRequirement[] },
      identifier: string
    ): JobRequirement | undefined {
      return this.jobRequirements.find((x) => x.identifier === identifier);
    },
  },
};
