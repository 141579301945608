import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Filter } from "@/models/UI";
import { Invoice } from "@/models/Invoice";

export const ENDPOINT = (): string => `/invoice/`;

export default async function managerInvoicesQuery(
  filters: Filter[]
): Promise<Invoice[]> {
  const builder = UrlBuilder.mq(ENDPOINT());
  filters.forEach((x) => {
    builder.addParams(x.name, x.value);
  });
  const result = await TranslateClient.get(builder.toString());
  return result.data.content;
}
