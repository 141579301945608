import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { AffiliationListItem } from "@/models/Affiliation";

export const ENDPOINT = (): string => `/affiliation/`;

export default async function affiliationsQuery(
  includeDisabled = false
): Promise<AffiliationListItem[]> {
  const builder = UrlBuilder.aq(ENDPOINT()).addParams(
    "includeDisabled",
    includeDisabled
  );
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
