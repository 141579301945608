import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Dispute } from "@/models/Job";

export const ENDPOINT = (jobId: number): string => `/job/${jobId}/disputes`;

export default async function DisputesQuery(jobId: number): Promise<Dispute[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(jobId)).toString()
  );
  return result.data;
}
