













import Vue from "@/translate";
import AppFooter from "@/components/common/AppFooter.vue";
import AppError from "@/components/common/AppError.vue";

export default Vue.extend({
  name: "AppMain",
  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes(): string {
      return this.dense ? "xs12 sm8 md4" : "xs12 sm12 md12 lg11";
    },
  },
  components: {
    AppError,
    AppFooter,
  },
});
