




























import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import { Filter } from "@/models/UI";
import ReceiptFilterCard from "@/components/manager/ReceiptFilterCard.vue";
import ReceiptsTable from "@/components/manager/ReceiptsTable.vue";
import { Affiliation } from "@/models/Affiliation";

export default Vue.extend(ManagerMixin).extend({
  name: "MInvoiceList",
  data() {
    return {
      filters: [] as Filter[],
      affiliation: undefined as Affiliation | undefined,
    };
  },
  components: {
    ReceiptsTable,
    ReceiptFilterCard,
    AppButton,
    AppCard,
  },
  methods: {
    filterChanged(event: Filter[]): void {
      this.filters = event;
    },
    affiliationSelected(event: Affiliation | undefined): void {
      this.affiliation = event;
    },
  },
});
