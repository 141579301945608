



















import Vue from "@/translate";
import SimpleLayout from "@/layouts/SimpleLayout.vue";

export default Vue.extend({
  name: "Info",
  components: {
    SimpleLayout,
  },
  data() {
    return {
      topics: [
        {
          title: "Offen",
          values: [
            "Bestellungen: Rechnungslauf (Nummern, PDFs, Mailversand)",
            "Admin: Worker verwalten",
            "Admin: Protokoll für Worker einführen",
            "Statistiken: Statistiken umsetzen",
            "Admin: Archivieren (Binärdaten löschen)",
            "Admin: Mailinhalte übersetzen / templates",
          ],
        },
        {
          title: "Erledigt",
          values: [
            "Bestellungen: EDI-Import automatisiert einbauen",
            "Bestellungen: Anzeige von EDI-Daten bei Bestellungen",
            "Bestellungen: Manuelles Erfassen von Bestellungen",
            "Übersetzer: Rechnungen der Übersetzer erfassen",
            "Projekte: Filter , Übersicht und Details",
            "Projekte: Paginierung incl. Statistik (Neu, Laufend, insgesamt)",
            "Jobs: Übersicht und Details",
            "Jobs: Job in anderen Sprachen erstellen, Proofread-Job erstellen",
            "Jobs: Anforderungen Kurzwahlen anbieten",
            "Allgemein: Veränderte Felder visuell hervorheben und anbieten, den Wert zurückzusetzen",
            "Projekte: aufgeben ermöglichen, auch bei Jobs",
            "Jobs: Reklamationen ermöglichen",
            "Jobs: Kommentare (nur intern) im Job erfassen/löschen",
            "Jobs: Nachrichten an Übersetzer (auch für alle Jobs im Projekt sichtbar schalten)",
            "Jobs: Protokoll einführen",
            "Admin: Einstellungen zu Auslastung (WordValPerDay, GreenThreshold)",
            "Allgemein: Nachfragen bei Aktionen bei wichtigen Veränderungen zwischenschalten",
            "Allgemein: Mailversand überprüfen (Use-Cases, Inhalte)",
            "Projekte: Kommentare in Liste nur als Symbol anzeigen",
            "Projekte: Sprachen in Liste als Kürzel und Voller Name als Tooltip",
            "Worker: Expertise, Qualification, Level (Inaktiv, Klasse A/B/C)",
            "Affiliations: Adressen, Prices, Rechnungen",
            "Admin: Email-Einstellungen verwalten",
          ],
        },
      ],
    };
  },
  methods: {
    head(value: string): string {
      return value.substring(0, value.indexOf(":"));
    },
    tail(value: string): string {
      return value.substring(value.indexOf(":") + 1);
    },
  },
});
