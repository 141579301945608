





















import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import { PropType } from "vue";

export default Vue.extend(FormElementMixin).extend({
  name: "AppSelect",
  props: {
    items: {
      type: Array as PropType<unknown[]>,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    combobox: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    renderAs(): string {
      if (this.autocomplete) {
        return "v-autocomplete";
      } else if (this.combobox) {
        return "v-combobox";
      }
      return "v-select";
    },
  },
});
