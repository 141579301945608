





















import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import ColorsMixin from "@/mixins/color-scheme";

export default Vue.extend(FormElementMixin)
  .extend(ColorsMixin)
  .extend({
    name: "AppCenterToggle",
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        model: false,
      };
    },
    methods: {
      expandFilter(event: any): void {
        this.$emit("expand", event === true);
      },
      collapseFilter(event: any): void {
        this.$emit("collapse", event === true);
      },
    },
  });
