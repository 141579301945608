import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateEmailSettingDto = {
  active: boolean;
  email: string;
  eventType: string;
};

export const ENDPOINT = "/email-settings/create";

export default async function CreateEmailSettingCommand(
  dto: CreateEmailSettingDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.ac(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
