


















































import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import AppCard from "@/components/common/AppCard.vue";
import { JobStatesColors } from "@/basic/jobstates";
import { WorkerJob } from "@/models/Job";
import { TableColData } from "@/models/UI";
import JobMixin from "@/mixins/job";
import { PropType } from "vue";

export default Vue.extend(WorkerMixin)
  .extend(JobMixin)
  .extend({
    name: "WorkerJobTable",
    props: {
      jobs: {
        type: Array as PropType<WorkerJob[]>,
        required: true,
      },
      disablePagination: {
        type: Boolean,
        default: false,
      },
      disableColors: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        colors: JobStatesColors,
        selected: [],
      };
    },
    computed: {
      loading(): boolean {
        return this.$store.state.loading;
      },
      headers(): TableColData[] {
        return [
          {
            text: this.$t("job.jobType") as string,
            value: "jobType",
            align: "left",
          },
          {
            text: this.$t("job.referenceNumber") as string,
            value: "referenceNumber",
            align: "left",
          },
          { text: this.$t("job.due") as string, value: "due", align: "left" },
          {
            text: this.$t("job.source") as string,
            value: "source",
            align: "left",
          },
          {
            text: this.$t("job.target") as string,
            value: "target",
            align: "left",
          },
          {
            text: this.$t("job.amount") as string,
            value: "amount",
            align: "left",
          },
          {
            text: this.$t("job.actions") as string,
            value: "actions",
            align: "left",
          },
        ];
      },
    },
    methods: {
      colorValue(job: WorkerJob): string {
        if (this.disableColors) {
          return "inherit";
        } else {
          return this.colors[job.jobState];
        }
      },
    },
    components: { AppCard },
  });
