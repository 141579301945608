import VueI18n from "@/i18n";

export enum DisputeTypes {
  DEADLINE,
  QUALITY,
  MISC,
}

type DisputeTypesSelectData = {
  text: string;
  value: string;
};

const DisputeTypesSelect = [] as DisputeTypesSelectData[];
for (const state in DisputeTypes) {
  const isValueProperty = parseInt(state, 10) >= 0;
  if (isValueProperty) {
    DisputeTypesSelect.push({
      value: DisputeTypes[state],
      text: VueI18n.t("disputeType." + DisputeTypes[state]) as string,
    });
  }
}
export { DisputeTypesSelect };
