import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type EnableRepresentativeDto = {
  representativeId: number;
};

export const ENDPOINT = "/representative/enable";

export default async function EnableRepresentativeCommand(
  dto: EnableRepresentativeDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
