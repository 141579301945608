import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateReceiptDto = {
  receiptId: number;
  receiptDate: string;
  receiptNumber: string;
};

export const ENDPOINT = "/affiliations/update-receipt";

export default async function UpdateReceiptCommand(
  dto: UpdateReceiptDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
