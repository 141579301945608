import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { CustomerPrice } from "@/models/Customer";

export const ENDPOINT = (customerId: number): string =>
  `/customer/${customerId}/prices`;

export default async function customerPriceListQuery(
  customerId: number
): Promise<CustomerPrice[]> {
  const builder = UrlBuilder.aq(ENDPOINT(customerId));
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
