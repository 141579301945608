


















import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import InvoiceMixin from "@/mixins/invoice";
import ManagerClient from "@/client/manager";
import { DIRTY_DATA } from "@/store/actions";
import { DirtyData } from "@/store/types";
import { Invoice } from "@/models/Invoice";
import InvoiceDetailsCard from "@/components/manager/InvoiceDetailsCard.vue";
import InvoicePositionsCard from "@/components/manager/InvoicePositionsCard.vue";
import AddInvoicePositionCard from "@/components/manager/AddInvoicePositionCard.vue";
import EdiOrderCard from "@/components/manager/EdiOrderCard.vue";

export default Vue.extend(ManagerMixin)
  .extend(InvoiceMixin)
  .extend({
    name: "MInvoiceDetail",
    data() {
      return {
        loading: false,
        invoice: {} as Invoice,
      };
    },
    async mounted() {
      await this.loadInvoice();
    },
    computed: {
      isInvoiceLoaded(): boolean {
        return this.invoice?.id !== undefined;
      },
      isEdiOrder(): boolean {
        return this.isInvoiceLoaded && this.invoice.ediSum != undefined;
      },
    },
    methods: {
      async loadInvoice(): Promise<void> {
        this.loading = true;
        try {
          const invoiceId = Number.parseInt(this.$route.params.invoiceId);
          this.invoice = await ManagerClient.ManagerInvoiceQuery(invoiceId);
          await this.$store.dispatch(DIRTY_DATA, {
            base: "invoice",
            data: this.invoice,
          } as DirtyData);
          this.invoice.positions.forEach((x) => {
            this.$store.dispatch(DIRTY_DATA, {
              base: "invoice.position",
              data: x,
            } as DirtyData);
          });
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      },
    },
    watch: {
      "$route.params.invoiceId": {
        handler: function (invoiceId: number): void {
          if (this.invoice.id !== invoiceId) {
            this.loadInvoice();
          }
        },
        deep: true,
      },
    },
    components: {
      EdiOrderCard,
      InvoiceDetailsCard,
      InvoicePositionsCard,
      AddInvoicePositionCard,
    },
  });
