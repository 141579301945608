







































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import JobMixin from "@/mixins/job";
import AppCard from "@/components/common/AppCard.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppButton from "@/components/common/AppButton.vue";
import { PropType } from "vue";
import { Comment, Project } from "@/models/Project";
import ManagerClient from "@/client/manager";
import * as date_fns from "date-fns";
import UpdateCommentDialog from "@/components/manager/UpdateCommentDialog.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import AddCommentDialog from "@/components/manager/AddCommentDialog.vue";

export default Vue.extend(ManagerMixin)
  .extend(JobMixin)
  .extend({
    name: "ProjectCommentsCard",
    props: {
      project: {
        type: Object as PropType<Project>,
      },
    },
    components: {
      AddCommentDialog,
      AppConfirmDialog,
      UpdateCommentDialog,
      AppCard,
      AppIcon,
      AppButton,
    },
    data() {
      return {
        comments: [] as Comment[],
      };
    },
    computed: {},
    methods: {
      async loadComments(): Promise<void> {
        this.comments = await ManagerClient.CommentsQuery(this.project.id);
      },
      enteredLabel(comment: Comment): string {
        return date_fns.format(
          new Date(comment.entered),
          this.$store.state.datetimeFormat,
          {
            locale: this.$store.state.locale,
          }
        );
      },
      async deleteComment(comment: Comment): Promise<void> {
        await ManagerClient.DeleteCommentCommand({
          projectId: this.project.id,
          commentId: comment.id,
        });
        await this.loadComments();
      },
    },
    watch: {
      project: {
        immediate: true,
        async handler(current) {
          if (current?.id) {
            await this.loadComments();
          }
        },
      },
    },
  });
