









import Vue from "@/translate";
import ColorsMixin from "@/mixins/color-scheme";
import { toLocal } from "@/utils/dates";

export default Vue.extend(ColorsMixin).extend({
  name: "AppDateLabel",
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    local(): string {
      return toLocal(this.date) || "";
    },
  },
});
