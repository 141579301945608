
























import Vue from "@/translate";
import ColorsMixin from "@/mixins/color-scheme";
import ActionableMixin from "@/mixins/actionable";
import { RawLocation } from "vue-router";
import { PropType } from "vue";

export default Vue.extend(ActionableMixin)
  .extend(ColorsMixin)
  .extend({
    name: "AppButton",
    props: {
      i18nLabel: {
        type: String,
        required: true,
      },
      altLabel: {
        type: String,
        default: "",
      },
      icon: {
        type: String,
        required: true,
      },
      color: {
        type: String,
      },
      asIcon: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      to: {
        type: Object as PropType<RawLocation>,
      },
      emptyIf: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      label(): string {
        return this.$t(this.i18nLabel) + this.altLabel;
      },
    },
  });
