



























import Vue from "@/translate";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";

export default Vue.extend({
  name: "AppColorDemoCard",
  components: { AppButton, AppCard },
  props: {
    shade: {
      type: Number,
      default: 0,
    },
  },
});
