import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store";
import { SHOW_ERROR, RESET_ERROR, CHANGE_LOADING } from "@/store/actions";
import app from "@/main";
import { AppError } from "@/store/types";

function getBaseUrl(suffixOrCompleteUrl: string) {
  if (suffixOrCompleteUrl.startsWith("http")) {
    return suffixOrCompleteUrl;
  } else {
    return `${window.location.protocol}//${window.location.host}/${suffixOrCompleteUrl}`;
  }
}

const baseUrl = getBaseUrl(
  `${process.env.VUE_APP_BACKEND_HOST ? process.env.VUE_APP_BACKEND_HOST : ""}${
    process.env.VUE_APP_BACKEND_URL ? process.env.VUE_APP_BACKEND_URL : ""
  }`
);

export enum DownloadJobType {
  INPUT = "input",
  RESULT = "result",
}

export const downloadJobFile = (
  jobId: number,
  fileId: number,
  type: DownloadJobType | string,
  workerId: number | undefined
): string => {
  return (
    baseUrl +
    "/file/q/job/" +
    jobId +
    "/" +
    type +
    "/" +
    fileId +
    "?token=Bearer " +
    store.state.token +
    (workerId ? "&workerId=" + workerId : "")
  );
};

export const downloadInvoiceFile = (invoiceId: number): string => {
  return (
    baseUrl +
    "/file/q/invoice/" +
    invoiceId +
    "/receipt" +
    "?token=Bearer " +
    store.state.token
  );
};

export enum DownloadEdiType {
  DATA = "data",
  SKIP = "skip",
  ORDER = "order",
}

export const downloadEdiFile = (
  ediId: string,
  type: DownloadEdiType
): string => {
  return (
    baseUrl +
    "/file/q/edi/" +
    ediId +
    "/" +
    type +
    "?token=Bearer " +
    store.state.token
  );
};

export const performDownload = (name: string, uri: string): void => {
  const link = document.createElement("a");
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute("download", name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const TranslateClient = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
  },
});

TranslateClient.interceptors.request.use(
  async (req: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const token = store.state.token;
    if (token !== "") {
      req.headers = req.headers || {};
      req.headers.Authorization = "Bearer " + token;
    }
    await store.dispatch(RESET_ERROR);
    await store.dispatch(CHANGE_LOADING, true);
    return req;
  }
);

TranslateClient.interceptors.response.use(
  async (res: AxiosResponse): Promise<AxiosResponse> => {
    await store.dispatch(CHANGE_LOADING, false);
    return res;
  },
  async (error: AxiosError): Promise<AxiosError> => {
    const errorKey = error.response?.data?.errorKey || "UNTITLED_ERROR";
    const appError = {
      errorKey,
      errorMessage:
        (app.$i18n?.t("common.errors." + errorKey) as string) || errorKey,
      errors: error.response?.data?.errors,
    } as AppError;
    await store.dispatch(SHOW_ERROR, appError);
    await store.dispatch(CHANGE_LOADING, false);
    return Promise.reject(error.response?.data);
  }
);

console.log("Server-URL: " + baseUrl);
export { TranslateClient };
