var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredPrices,"item-key":"id","data-cy":"ADMIN_CUSTOMER_PRICES_TABLE"},scopedSlots:_vm._u([{key:"header.jobType",fn:function(){return [_c('AppJobTypeSelect',{attrs:{"i18n-base":"job","field":"jobType"},on:{"change":_vm.applyFilters},model:{value:(_vm.jobType),callback:function ($$v) {_vm.jobType=$$v},expression:"jobType"}})]},proxy:true},{key:"item.jobType",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t("common.jobTypes." + item.jobType)))]}},{key:"header.orderUnit",fn:function(){return [_c('AppOrderUnitSelect',{attrs:{"i18n-base":"job","field":"orderUnit"},on:{"change":_vm.applyFilters},model:{value:(_vm.orderUnit),callback:function ($$v) {_vm.orderUnit=$$v},expression:"orderUnit"}})]},proxy:true},{key:"item.orderUnit",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t("common.orderUnits." + item.orderUnit)))]}},{key:"header.source",fn:function(){return [_c('AppLangSelect',{attrs:{"i18n-base":"job","field":"source"},on:{"change":_vm.applyFilters},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}})]},proxy:true},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t("common.languages." + item.source)))]}},{key:"header.target",fn:function(){return [_c('AppLangSelect',{attrs:{"i18n-base":"job","field":"target"},on:{"change":_vm.applyFilters},model:{value:(_vm.target),callback:function ($$v) {_vm.target=$$v},expression:"target"}})]},proxy:true},{key:"item.target",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t("common.languages." + item.target)))]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.price)+" €")]}},{key:"header.actions",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[(_vm.hasFilter)?_c('AppButton',{attrs:{"i18n-label":"common.filter.clear","icon":"mdi-close"},on:{"click":_vm.clearFilter}}):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('CustomerPriceDialog',{attrs:{"icon":"mdi-pencil","label":"actions.updateCustomerPrice","color":"green","action":"UpdateCustomerPriceAction","actionable":item,"customer":_vm.customer,"price":item},on:{"performAction":_vm.savePrice}}),_c('AppConfirmDialog',{attrs:{"label":"actions.deleteCustomerPrice","icon":"mdi-delete","color":"red","action":"DeleteCustomerPriceAction","actionable":item},on:{"performAction":function($event){return _vm.deletePrice(item)}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }