










































import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import AppCard from "@/components/common/AppCard.vue";
import WorkerJobTable from "@/components/worker/WorkerJobTable.vue";
import { WorkerJob } from "@/models/Job";
import WorkerClient from "@/client/worker";
import { SHOW_ERROR } from "@/store/actions";
import JobMixin from "@/mixins/job";

export default Vue.extend(WorkerMixin)
  .extend(JobMixin)
  .extend({
    name: "WorkerActiveCard",
    props: ["externalLoading"],
    data() {
      return {
        jobs: [] as WorkerJob[],
        loading: false,
        open: false,
      };
    },
    async mounted() {
      await this.loadJobs();
    },
    computed: {
      isLoading(): boolean {
        return this.loading || this.externalLoading;
      },
    },
    methods: {
      async loadJobs(): Promise<void> {
        this.loading = true;
        try {
          this.jobs = await WorkerClient.WorkerJobsQuery();
        } catch (e) {
          await this.$store.dispatch(SHOW_ERROR, "Konnte Jobs nicht laden");
        } finally {
          this.loading = false;
        }
      },
    },
    components: { AppCard, WorkerJobTable },
  });
