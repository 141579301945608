import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateInvoiceDto = {
  id: number;
  projectRef: string;
  customerRef: string;
  managerRef: string;
  note: string;
};

export const ENDPOINT = "/invoice/update";

export default async function UpdateInvoiceCommand(
  dto: UpdateInvoiceDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
