import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteAffiliationDto = {
  affiliationId: number;
};

export const ENDPOINT = "/affiliation/delete";

export default async function DeleteAffiliationCommand(
  dto: DeleteAffiliationDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
