import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateProjectDto = {
  id: number;
  referenceNumber: string;
  customerNumber: string;
  description: string;
  representativeId: number;
};

export const ENDPOINT = "/project/update";

export default async function CreateProject(
  dto: UpdateProjectDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
