




















import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import ManagerClient from "@/client/manager";
import AppDialog from "@/components/common/AppDialog.vue";
import AppRadioButton from "@/components/common/AppRadioButton.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "CloseDisputeDialog",
  props: ["dispute"],
  components: {
    AppRadioButton,
    AppDialog,
  },
  data() {
    return {
      justlyDisputed: "",
    };
  },
  computed: {
    valid(): boolean {
      return this.justlyDisputed !== "";
    },
  },
  methods: {
    async closeDispute(): Promise<void> {
      const justly = this.justlyDisputed === "yes";
      try {
        await ManagerClient.CloseDisputeCommand({
          disputeId: this.dispute.id,
          justlyDisputed: justly,
        });
        await this.$router.push({
          name: "MProjectDetail",
          params: {
            projectId: this.dispute.projectId,
            jobId: this.dispute.jobId,
          },
        });
      } catch (e: any) {
        // TODO: handle error
        console.log(e);
      }
    },
  },
});
