import VueI18n from "@/i18n";

export enum Languages {
  DE_DE,
  EN_US,
  EN_GB,
  FR_FR,
  RU_RU,
  ES_EM,
  PT_PT,
  ET_EE,
  FI_FI,
  EL_GR,
  LV_VL,
  LT_LT,
  MT_MT,
  RO_RO,
  CS_CZ,
  HU_HU,
  SK_SK,
  SL_SI,
  BG_BG,
  SR_RS,
  DA_DK,
  TR_TR,
  AR_SA,
  NL_NL,
  ZH_CH,
  SV_SE,
  JA_JP,
  PL_PL,
  PT_BR,
  IS_IS,
  NB_NO,
  KO_KR,
  ZH_TW,
  UK_UA,
  EN_IE,
  HR_HR,
  GA_IE,
  ES_MX,
  SQ_AL,
  BS_BA,
  HE_IL,
  MK_MK,
  VI_VN,
  FA_IR,
  SR,
  TH_TH,
}

type LanguagesSelectData = {
  text: string;
  value: string;
};

const LanguagesSelect = [] as LanguagesSelectData[];
for (const lang in Languages) {
  const isValueProperty = parseInt(lang, 10) >= 0;
  if (isValueProperty) {
    LanguagesSelect.push({
      value: Languages[lang],
      text: VueI18n.t("common.languages." + Languages[lang]) as string,
    });
  }
}
export { LanguagesSelect };
