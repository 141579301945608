










import Vue from "@/translate";
import AppButton from "@/components/common/AppButton.vue";

export default Vue.extend({
  name: "AppDetailsButton",
  components: { AppButton },
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
});
