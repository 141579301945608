

















import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import JobMixin from "@/mixins/job";
import AppCard from "@/components/common/AppCard.vue";
import WorkerClient from "@/client/worker";
import { JobMessage } from "@/models/Job";

export default Vue.extend(WorkerMixin)
  .extend(JobMixin)
  .extend({
    name: "WorkerJobInfoCard",
    props: ["job"],
    data() {
      return {
        messages: [] as JobMessage[],
      };
    },
    methods: {
      async loadMessages(): Promise<void> {
        if (this.job !== undefined && this.job.id !== undefined) {
          this.messages = await WorkerClient.JobMessagesQuery(this.job.id);
        } else {
          this.messages = [];
        }
      },
    },
    created() {
      this.loadMessages();
    },
    watch: {
      job() {
        this.loadMessages();
      },
    },
    components: {
      AppCard,
    },
  });
