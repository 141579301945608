import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { toUTC } from "@/utils/dates";

export type CreateVacationDto = {
  workerId: number;
  start: string;
  stop: string;
  reporter: string;
};

export const ENDPOINT = "/vacation/create";

export default async function CreateVacationCommand(
  dto: CreateVacationDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.wc(ENDPOINT).toString(), {
    ...dto,
    start: toUTC(dto.start),
    stop: toUTC(dto.stop),
  });
}
