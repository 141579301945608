import { Pagination } from "@/models/Query";

const USER_COMMAND_BASE = "/user/c";

const ADMIN_BASE = "/admin";
const ADMIN_COMMAND_BASE = ADMIN_BASE + "/c";
const ADMIN_QUERY_BASE = ADMIN_BASE + "/q";

const MANAGER_BASE = "/manager";
const MANAGER_COMMAND_BASE = MANAGER_BASE + "/c";
const MANAGER_QUERY_BASE = MANAGER_BASE + "/q";

const WORKER_COMMAND_BASE = "/worker/c";
const WORKER_QUERY_BASE = "/worker/q";

export default class UrlBuilder {
  private queryParams = new URLSearchParams();
  private result = "";
  constructor(private base: string) {
    this.result = base;
  }

  reset(): void {
    this.queryParams = new URLSearchParams();
    this.result = this.base;
  }

  static uc(base: string): UrlBuilder {
    return this.fromBaseUrl(USER_COMMAND_BASE + base);
  }

  static a(base: string): UrlBuilder {
    return this.fromBaseUrl(ADMIN_BASE + base);
  }
  static aq(base: string): UrlBuilder {
    return this.fromBaseUrl(ADMIN_QUERY_BASE + base);
  }
  static ac(base: string): UrlBuilder {
    return this.fromBaseUrl(ADMIN_COMMAND_BASE + base);
  }

  static m(base: string): UrlBuilder {
    return this.fromBaseUrl(MANAGER_BASE + base);
  }
  static mq(base: string): UrlBuilder {
    return this.fromBaseUrl(MANAGER_QUERY_BASE + base);
  }
  static mc(base: string): UrlBuilder {
    return this.fromBaseUrl(MANAGER_COMMAND_BASE + base);
  }

  static wq(base: string): UrlBuilder {
    return this.fromBaseUrl(WORKER_QUERY_BASE + base);
  }
  static wc(base: string): UrlBuilder {
    return this.fromBaseUrl(WORKER_COMMAND_BASE + base);
  }

  static fromBaseUrl(base: string): UrlBuilder {
    return new UrlBuilder(base);
  }

  addDirectory(name: string): UrlBuilder {
    this.result += `/${name}`;
    return this;
  }

  addParams(key: string, value: string | number | boolean): UrlBuilder {
    if (typeof value === "number") {
      this.queryParams.append(key, value.toString());
    } else if (typeof value === "boolean") {
      this.queryParams.append(key, value.toString());
    } else {
      this.queryParams.append(key, value);
    }
    return this;
  }

  addParamsList(list: Record<string, string> = {}): UrlBuilder {
    Object.keys(list).forEach((X) => {
      this.addParams(X, list[X]);
    });
    return this;
  }

  private getParams(): string {
    if (!this.queryParams.keys().next().done) {
      return "?" + this.queryParams.toString();
    } else {
      return "";
    }
  }

  public addPagination(pagination: Pagination): UrlBuilder {
    this.addParams("page", pagination.page - 1);
    this.addParams("size", pagination.elementsPerPage);
    return this;
  }

  valueOf(): string {
    return this.toString();
  }

  toString(): string {
    return encodeURI(this.result) + this.getParams();
  }
}
