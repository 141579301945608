

































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import { WorkerSelect } from "@/client/manager/PossibleWorkersQuery";
import AppButton from "@/components/common/AppButton.vue";
import AppWorkerSelect from "@/components/common/AppWorkerSelect.vue";
import AppDialog from "@/components/common/AppDialog.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import { PropType } from "vue";
import { Job } from "@/models/Job";

export default Vue.extend(ManagerMixin).extend({
  name: "AssignJobDialog",
  props: {
    job: {
      type: Object as PropType<Job>,
    },
    ignoreDark: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppCheckbox,
    AppButton,
    AppDialog,
    AppGrid,
    AppWorkerSelect,
    AppCard,
  },
  data() {
    return {
      workers: [] as WorkerSelect[],
      workerId: -1,
      inquire: false,
    };
  },
  computed: {
    canInquire(): boolean {
      return this.job?.input > 0;
    },
  },
  methods: {
    async resetDialog(): Promise<void> {
      await this.loadMatchingWorkers();
      this.inquire = this.canInquire;
      this.workerId = this.job?.worker?.id || -1;
    },
    async loadMatchingWorkers(): Promise<void> {
      if (this.job?.id) {
        this.workers = await ManagerClient.PossibleWorkersQuery(this.job.id);
      } else {
        this.workers = [];
      }
    },
    async loadAllWorkers(): Promise<void> {
      if (this.job?.id) {
        this.workers = await ManagerClient.AllWorkersQuery();
      } else {
        this.workers = [];
      }
    },
    async assignWorker(): Promise<void> {
      if (this.job.id) {
        await ManagerClient.AssignWorkerCommand({
          jobId: this.job.id,
          workerId: this.workerId,
          inquire: this.inquire,
        });
        this.$emit("projectChanged");
      }
    },
  },
});
