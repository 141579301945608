export type Pagination = {
  page: number;
  elementsPerPage: number;
  totalElements: number;
  totalPages: number;
};

export const DefaultPagination: Pagination = {
  page: 1,
  elementsPerPage: 200,
  totalElements: 0,
  totalPages: 0,
};

export type PagedResult<T> = {
  content: T[];
  totalPages: number;
  totalElements: number;
};

export type DeleteDto = {
  id: number;
};
