




































import Vue from "@/translate";
import AppGrid from "@/components/common/AppGrid.vue";
import AdminMixin from "@/mixins/admin";
import AdminClient from "@/client/admin";
import { Customer } from "@/models/Customer";
import { Address } from "@/models/Common";
import CustomerDetailsCard from "@/components/admin/CustomerDetailsCard.vue";
import AddressDetailsCard from "@/components/admin/AddressDetailsCard.vue";
import RepresentativeListCard from "@/components/admin/RepresentativeListCard.vue";
import CustomerPriceListCard from "@/components/admin/CustomerPriceListCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import { CreateReceiptAddressDto } from "@/client/admin/CreateReceiptAddressCommand";

export default Vue.extend(AdminMixin).extend({
  name: "ACustomerDetail",
  data() {
    return {
      customer: {} as Customer,
      address: {} as Address,
      receiptAddress: {} as Address,
    };
  },
  components: {
    AppButton,
    CustomerPriceListCard,
    CustomerDetailsCard,
    AddressDetailsCard,
    RepresentativeListCard,
    AppGrid,
  },
  async mounted() {
    await this.loadCustomer();
  },
  computed: {
    isCustomerLoaded(): boolean {
      return this.customer?.id !== undefined;
    },
    hasAddress(): boolean {
      return this.address?.id !== undefined;
    },
    hasReceiptAddress(): boolean {
      return this.receiptAddress?.id !== undefined;
    },
  },
  methods: {
    async createReceiptAddress(): Promise<void> {
      await AdminClient.CreateReceiptAddressCommand({
        customerId: this.customer.id,
      } as CreateReceiptAddressDto);
      await this.loadCustomer();
    },
    async addressChanged(): Promise<void> {
      try {
        this.address = await AdminClient.AddressQuery(this.customer.addressId);
        if (this.customer.receiptAddressId) {
          this.receiptAddress = await AdminClient.AddressQuery(
            this.customer.receiptAddressId
          );
        } else {
          this.receiptAddress = {} as Address;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async loadCustomer(): Promise<void> {
      try {
        const customerId = Number.parseInt(this.$route.params.customerId);
        this.customer = await AdminClient.CustomerQuery(customerId);
        await this.addressChanged();
      } catch (e) {
        console.log(e);
      }
    },
  },
});
