




















import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import { JobStateSelect } from "@/basic/jobstates";

export default Vue.extend(FormElementMixin).extend({
  name: "AppJobStateSelect",
  data() {
    return {
      jobStates: JobStateSelect,
    };
  },
});
