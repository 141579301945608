import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Admin } from "@/models/Admin";

export const ENDPOINT = (adminId: number): string => `/settings/${adminId}`;

export default async function AdminQuery(adminId: number): Promise<Admin> {
  const result = await TranslateClient.get(
    UrlBuilder.aq(ENDPOINT(adminId)).toString()
  );
  return result.data;
}
