import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AddResultDto = {
  jobId: number;
  file: File;
};

export const ENDPOINT = "/job/add-result";

export default async function AddResultCommand(
  dto: AddResultDto
): Promise<void> {
  const formData = new FormData();
  formData.append("jobId", dto.jobId.toString());
  formData.append("file", dto.file);
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
