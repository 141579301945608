import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateSendMailsDto = {
  sendMails: boolean;
};

export const ENDPOINT = "/email-settings/change";

export default async function UpdateSendMailsCommand(
  dto: UpdateSendMailsDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
