




















import Vue from "@/translate";
import { DefaultPagination, Pagination } from "@/models/Query";
import { PropType } from "vue";

export default Vue.extend({
  name: "AppPagination",
  props: {
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => DefaultPagination,
    },
  },
  computed: {},
  methods: {
    changePage(event: number): void {
      const pagination = this.pagination;
      pagination.page = event;
      this.$emit("pagination", pagination);
    },
    changeElementsPerPage(event: number): void {
      const pagination = this.pagination;
      pagination.elementsPerPage = event;
      pagination.page = 1;
      this.$emit("pagination", pagination);
    },
  },
});
