import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AbandonJobDto = {
  jobId: number;
};

export const ENDPOINT = "/job/abandon";

export default async function AbandonJobCommand(
  dto: AbandonJobDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
