import VueI18n from "@/i18n";

export enum OrderUnits {
  WORDS,
  LINES,
  HOURS,
  DAYS,
  PAGES,
  FLATCHARGE,
}

type OrderUnitSelectData = {
  text: string;
  value: string;
};

const OrderUnitSelect = [] as OrderUnitSelectData[];
for (const state in OrderUnits) {
  const isValueProperty = parseInt(state, 10) >= 0;
  if (isValueProperty) {
    OrderUnitSelect.push({
      value: OrderUnits[state],
      text: VueI18n.t("common.orderUnits." + OrderUnits[state]) as string,
    });
  }
}
export { OrderUnitSelect };
