var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.jobs,"item-key":"id","disable-pagination":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{staticClass:"table__dense",class:{
        abandoned: props.item.abandoned,
      }},[_c('td',{staticClass:"text-xs-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.jobTypeLabel(props.item))+" ("+_vm._s(_vm.effortLabel(props.item))+") ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.id))])])],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.sourceLabel(props.item)))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.targetLabel(props.item)))]),(!props.item.abandoned)?_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(_vm.jobStateLabel(props.item))+" ")]):_c('td',{staticClass:"text-xs-left"},[_c('AppIcon',{attrs:{"i18nLabel":"job.abandoned","icon":"mdi-cancel","color":"#a8a8a8"}})],1),_c('td',{staticClass:"text-xs-left"},[(props.item.worker)?_c('span',[_vm._v(_vm._s(props.item.worker.lastname)+" "+_vm._s(props.item.worker.firstname))]):_c('span',[_vm._v("-")])]),_c('td',{staticClass:"text-xs-left"},[(props.item.openDisputes)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-flash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("job.openDisputes")))])]):_vm._e()],1),_c('td',{staticClass:"text-xs-left"},[_c('div',{staticClass:"d-flex"},[_vm._t("actions",null,{"item":props.item})],2)])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }