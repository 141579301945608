











import Vue from "@/translate";
import { Job } from "@/models/Job";
import { SHOW_ERROR } from "@/store/actions";
import WorkerMixin from "@/mixins/worker";
import WorkerClient from "@/client/worker";
import WorkerJobDetailsCard from "@/components/worker/WorkerJobDetailsCard.vue";
import WorkerJobInfoCard from "@/components/worker/WorkerJobInfoCard.vue";
import WorkerJobDeliveryCard from "@/components/worker/WorkerJobDeliveryCard.vue";

export default Vue.extend(WorkerMixin).extend({
  name: "WJobDetail",
  data() {
    return {
      job: {} as Job,
    };
  },
  methods: {
    async loadJob(): Promise<void> {
      try {
        const jobId = Number.parseInt(this.$route.params.jobId);
        this.job = await WorkerClient.WorkerJobQuery(jobId);
      } catch (e) {
        await this.$store.dispatch(
          SHOW_ERROR,
          "Konnte Job-Details nicht laden"
        );
      }
    },
  },
  created() {
    this.loadJob();
  },
  components: {
    WorkerJobDetailsCard,
    WorkerJobInfoCard,
    WorkerJobDeliveryCard,
  },
});
