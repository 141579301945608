























import Vue from "@/translate";
import FormElementMixin from "@/mixins/form-element";
import { JobTypeSelect } from "@/basic/jobtypes";

export default Vue.extend(FormElementMixin).extend({
  name: "AppJobTypeSelect",
  props: ["asAction"],
  data() {
    return {
      jobTypes: JobTypeSelect,
    };
  },
});
