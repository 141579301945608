




































































import Vue from "@/translate";
import ColorsMixin from "@/mixins/color-scheme";
import SimpleLayout from "@/layouts/SimpleLayout.vue";
import LanguageSwitch from "@/components/LanguageSwitch.vue";
import { LOGIN } from "@/store/actions";
import { LoginData } from "@/client/auth/Login";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AuthClient from "@/client";

export default Vue.extend(ColorsMixin).extend({
  name: "ResetPassword",
  data() {
    return {
      autologin: true,
      username: "",
      code: "",
      password: "",
      password2: "",
    };
  },
  created() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.username = this.$route.query.login || "";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.code = this.$route.query.code || "";
  },
  computed: {
    valid(): boolean {
      return (
        this.username !== "" &&
        this.code !== "" &&
        this.password !== "" &&
        this.password2 !== "" &&
        this.password === this.password2
      );
    },
    passwordsDontMatch(): boolean {
      return this.password !== this.password2;
    },
    redirectTo(): string | undefined {
      if (this.$route.params.redirectTo) {
        return this.$route.params.redirectTo;
      }
      return undefined;
    },
  },
  methods: {
    async resetPassword(): Promise<void> {
      await AuthClient.ResetPasswordCommand({
        login: this.username,
        code: this.code,
        password: this.password,
      });
      await this.$router.push({ name: "Home" });
    },
  },
  components: {
    AppTextField,
    AppButton,
    LanguageSwitch,
    SimpleLayout,
  },
});
