import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Affiliation } from "@/models/Affiliation";

export const ENDPOINT = (affiliationId: number): string =>
  `/affiliation/${affiliationId}`;

export default async function affiliationQuery(
  affiliationId: number
): Promise<Affiliation> {
  const builder = UrlBuilder.aq(ENDPOINT(affiliationId));
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
