import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteReceiptPositionDto = {
  positionId: number;
};

export const ENDPOINT = "/affiliations/delete-receipt-position";

export default async function DeleteReceiptPositionCommand(
  dto: DeleteReceiptPositionDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
