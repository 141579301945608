import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { WorkerJob } from "@/models/Job";
import { toLocal } from "@/utils/dates";

export const ENDPOINT = (jobId: number): string => `/job/${jobId}`;

export default async function WorkerJobQuery(
  jobId: number
): Promise<WorkerJob> {
  const result = await TranslateClient.get(
    UrlBuilder.wq(ENDPOINT(jobId)).toString()
  );
  const job = result.data;
  return {
    ...job,
    due: toLocal(job.due),
  };
}
