import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateWorkerDto = {
  id: number;

  firstname: string;
  lastname: string;

  middlename?: string;

  phone?: string;
  phone2?: string;
  mobile?: string;
  telefax?: string;

  email: string;
  web?: string;
};

export const ENDPOINT = "/settings/update";

export default async function UpdateWorkerCommand(
  dto: UpdateWorkerDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.wc(ENDPOINT).toString(), dto);
}
