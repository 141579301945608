









import Vue from "@/translate";

export default Vue.extend({
  name: "AppLangInfo",
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  computed: {
    tooltip(): string {
      return this.$t("common.languages." + this.lang) as string;
    },
  },
});
