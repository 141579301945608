









import Vue from "@/translate";

export default Vue.extend({
  name: "AppLabeledData",
  props: {
    i18nLabel: {
      type: String,
    },
    i18nDataLabels: {
      type: String,
    },
    data: {
      type: [String, Number, Boolean],
    },
  },
  computed: {
    label(): string {
      return this.$t(this.i18nLabel) as string;
    },
    value(): string {
      if (this.i18nDataLabels) {
        return this.$t(this.i18nDataLabels + "." + this.data) as string;
      }
      if (this.data) {
        return this.data.toString();
      }
      return "";
    },
  },
});
