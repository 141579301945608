var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppCard',{attrs:{"back":"MInvoiceList","titleLocale":_vm.invoiceTypeKey(_vm.invoice),"attachTop":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.invoiceStateLabel(_vm.invoice)))]},proxy:true},{key:"actions",fn:function(){return [_c('AppDownloadButton',{attrs:{"i18n-label":"invoice.downloadPdf","icon":"mdi-file-pdf-box","uri":_vm.downloadUri,"actionable":_vm.invoice,"action":"DownloadInvoiceReceiptAction"}}),_c('AppActionSeparator',{attrs:{"actionable":_vm.invoice,"actions":[
        'BlockCustomerInvoiceAction',
        'UnblockCustomerInvoiceAction',
        'ConfirmCustomerInvoiceAction',
        'FinishCustomerInvoiceAction' ]}}),_c('AppConfirmDialog',{attrs:{"label":"actions.blockReceipt","icon":"mdi-stop-circle-outline","color":"red","action":"BlockCustomerInvoiceAction","actionable":_vm.invoice},on:{"performAction":_vm.blockReceipt}}),_c('AppConfirmDialog',{attrs:{"label":"actions.unblockReceipt","icon":"mdi-arrow-right-drop-circle-outline","color":"green","action":"UnblockCustomerInvoiceAction","actionable":_vm.invoice},on:{"performAction":_vm.unblockReceipt}}),_c('AppConfirmDialog',{attrs:{"label":"actions.confirmReceipt","icon":"mdi-comment-check-outline","color":"green","action":"ConfirmCustomerInvoiceAction","actionable":_vm.invoice},on:{"performAction":_vm.confirmReceipt}}),_c('AppConfirmDialog',{attrs:{"label":"actions.finishReceipt","icon":"mdi-book-multiple","color":"green","action":"FinishCustomerInvoiceAction","actionable":_vm.invoice},on:{"performAction":_vm.finishReceipt}}),_c('AppActionSeparator',{attrs:{"actionable":_vm.invoice,"actions":[
        'DeleteCustomerInvoiceAction',
        'UpdateCustomerInvoiceAction' ]}}),_c('AppConfirmDialog',{attrs:{"label":"actions.deleteInvoice","icon":"mdi-delete","color":"red","action":"DeleteCustomerInvoiceAction","actionable":_vm.invoice},on:{"performAction":_vm.deleteInvoice}}),_c('AppButton',{attrs:{"color":"green","i18n-label":"actions.updateInvoice","icon":"mdi-check","action":"UpdateCustomerInvoiceAction","actionable":_vm.invoice},on:{"click":_vm.saveInvoice}})]},proxy:true}])},[_c('AppGrid',{attrs:{"cols":3}},[_c('AppGrid',{attrs:{"hasAction":""}},[_c('AppTextField',{attrs:{"id":_vm.invoice.id,"i18n-base":"invoice","field":"projectRef","required":""},model:{value:(_vm.invoice.projectRef),callback:function ($$v) {_vm.$set(_vm.invoice, "projectRef", $$v)},expression:"invoice.projectRef"}}),_c('AppButton',{attrs:{"ignore-dark":"","i18n-label":"invoice.toProject","icon":"mdi-page-next-outline","to":{
          name: 'MProjectDetail',
          params: { projectId: _vm.invoice.projectId },
        }}})],1),_c('AppTextField',{attrs:{"id":_vm.invoice.id,"i18n-base":"invoice","field":"customerRef","required":""},model:{value:(_vm.invoice.customerRef),callback:function ($$v) {_vm.$set(_vm.invoice, "customerRef", $$v)},expression:"invoice.customerRef"}}),_c('AppTextField',{attrs:{"id":_vm.invoice.id,"i18n-base":"invoice","field":"managerRef","required":""},model:{value:(_vm.invoice.managerRef),callback:function ($$v) {_vm.$set(_vm.invoice, "managerRef", $$v)},expression:"invoice.managerRef"}})],1),_c('AppGrid',{attrs:{"cols":4}},[_c('AppTextField',{attrs:{"value":_vm.invoice.invoiceNumber,"i18n-base":"invoice","field":"invoiceNumber","disabled":""}}),_c('AppTextField',{attrs:{"value":_vm.invoice.receiptNumber,"i18n-base":"invoice","field":"receiptNumber","disabled":""}}),_c('AppTextField',{attrs:{"value":_vm.invoice.deliveryDate,"i18n-base":"invoice","field":"deliveryDate","disabled":"","required":""}}),_c('AppTextField',{attrs:{"value":_vm.invoice.receiptDate,"i18n-base":"invoice","field":"receiptDate","disabled":"","required":""}})],1),_c('AppTextArea',{attrs:{"id":_vm.invoice.id,"i18n-base":"invoice","field":"note"},model:{value:(_vm.invoice.note),callback:function ($$v) {_vm.$set(_vm.invoice, "note", $$v)},expression:"invoice.note"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }