

















































































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import RequirementMixin from "@/mixins/requirements";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import { Job } from "@/models/Job";
import { UpdateJobRequirementsDto } from "@/client/manager/UpdateJobRequirementsCommand";
import { PropType } from "vue";
import AppButton from "@/components/common/AppButton.vue";
import AppSeparator from "@/components/common/AppSeparator.vue";
import JobRequirementBlock from "@/components/manager/JobRequirementBlock.vue";
import AppCenterToggle from "@/components/common/AppCenterToggle.vue";

export default Vue.extend(ManagerMixin)
  .extend(RequirementMixin)
  .extend({
    name: "JobRequirementsCard",
    props: {
      job: {
        type: Object as PropType<Job>,
      },
    },
    components: {
      AppCenterToggle,
      JobRequirementBlock,
      AppSeparator,
      AppButton,
      AppCard,
    },
    data() {
      return {
        open: false,
      };
    },
    async mounted() {
      await this.loadRequirements();
    },
    methods: {
      toggleRequiredOnly(): void {
        this.showRequiredOnly = !this.showRequiredOnly;
      },
      expandRequirement(): void {
        this.open = true;
      },
      collapseRequirement(): void {
        this.open = false;
      },
      async modifyRequirements(modification: string): Promise<void> {
        await ManagerClient.ModifyJobRequirementsCommand({
          jobId: this.job.id,
          modification,
        });
        await this.loadRequirements();
      },
      async loadRequirements(): Promise<void> {
        this.jobRequirements = await ManagerClient.JobRequirementsQuery(
          this.job.id
        );
        this.showRequiredOnly = "JOBSTATE_ENTERED" !== this.job.jobState;
      },
      async saveRequirements(): Promise<void> {
        const identifiers = this.jobRequirements
          .filter((x) => x.required)
          .map((x) => x.identifier);
        await ManagerClient.UpdateJobRequirementsCommand({
          jobId: this.job.id,
          selectedIdentifiers: identifiers,
        } as UpdateJobRequirementsDto);
        await this.loadRequirements();
      },
      unselectJob(): void {
        this.$emit("unselectJob");
      },
    },
    watch: {
      async job() {
        await this.loadRequirements();
      },
    },
  });
