//import { RootState } from "./store/types";
import Vue, { VueConstructor } from "vue";
//import { Store } from "vuex";
//import ids from "@/utils/ids";
//import utils from "@/utils/utils";
//import ActionMetaService from "@/service/ActionMetaService";

abstract class TranslateVue extends Vue {
  //public $store!: Store<RootState>;
  //    public $ids = ids;
  //public $utils = utils;
  //public $actionmeta = ActionMetaService;
  //public $Progress: any;
}

//Vue.prototype.$ids = ids;
//Vue.prototype.$utils = utils;
//Vue.prototype.$actionmeta = ActionMetaService;

const translateVue = Vue as VueConstructor<TranslateVue>;

export default translateVue;
