import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type ModifyJobRequirementsDto = {
  jobId: number;
  modification: string;
};

export const ENDPOINT = "/job/modify-requirements";

export default async function ModifyJobRequirementsCommand(
  dto: ModifyJobRequirementsDto
): Promise<void> {
  return await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
