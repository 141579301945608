import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { User } from "@/models/User";

export const ENDPOINT = "/q/user/bytoken";

export default async function get(token: string): Promise<User> {
  const result = await TranslateClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT).addDirectory(token).toString()
  );
  return await result.data;
}
