





























import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import ManagerClient from "@/client/manager";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppTextArea from "@/components/common/AppTextArea.vue";
import { Affiliation } from "@/models/Affiliation";
import AppGrid from "@/components/common/AppGrid.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MReceiptCreate",
  data() {
    return {
      affiliationId: -1,
      affiliation: {} as Affiliation,
      receiptNumber: "",
      receiptDate: "",
    };
  },
  computed: {
    valid(): boolean {
      return (
        this.receiptNumber != "" &&
        this.receiptDate != "" &&
        this.affiliationId != -1
      );
    },
  },
  components: {
    AppDateSelect,
    AppGrid,
    AppTextArea,
    AppTextField,
    AppCard,
    AppButton,
    AppCustomerSelect,
  },
  methods: {
    async loadAffiliation(): Promise<void> {
      this.affiliation = await ManagerClient.AffiliationQuery(
        this.affiliationId
      );
    },
    async createReceipt(): Promise<void> {
      try {
        const id = await ManagerClient.CreateReceiptCommand({
          affiliationId: this.affiliationId,
          receiptNumber: this.receiptNumber,
          receiptDate: this.receiptDate,
        });
        await this.$router.push({
          name: "MReceiptDetail",
          params: { receiptId: id },
        });
      } catch (e: any) {
        // TODO: handle error
      }
    },
  },
  watch: {
    "$route.params.affiliationId": {
      handler: function (affiliationId: number): void {
        this.affiliationId = affiliationId;
        if (this.affiliation.id !== affiliationId && affiliationId != -1) {
          this.loadAffiliation();
        }
      },
      immediate: true,
      deep: true,
    },
  },
});
