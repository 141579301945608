
































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import JobMixin from "@/mixins/job";
import AppCard from "@/components/common/AppCard.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppButton from "@/components/common/AppButton.vue";
import { Job } from "@/models/Job";
import { PropType } from "vue";

export default Vue.extend(ManagerMixin)
  .extend(JobMixin)
  .extend({
    name: "JobTable",
    props: {
      jobs: {
        type: Array as PropType<Job[]>,
      },
    },
    components: {
      AppCard,
      AppIcon,
      AppButton,
    },
    data() {
      return {};
    },
  });
