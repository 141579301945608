






import Vue from "@/translate";

export default Vue.extend({
  name: "AppGrid",
  props: {
    cols: {
      type: Number,
      default: 2,
    },
    hasId: {
      type: Boolean,
      default: false,
    },
    hasAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colClass(): string {
      const cols = Math.min(this.cols, 4);
      const suffix = this.hasId
        ? this.hasAction
          ? "_all"
          : "_id"
        : this.hasAction
        ? "_action"
        : "";
      return `app-grip_${cols}cols${suffix}`;
    },
  },
});
