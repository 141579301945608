import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { JobRequirement } from "@/models/Job";

export const ENDPOINT = (jobId: number): string => `/job/${jobId}/requirements`;

export default async function JobRequirementsQuery(
  jobId: number
): Promise<JobRequirement[]> {
  const result = await TranslateClient.get(
    UrlBuilder.wq(ENDPOINT(jobId)).toString()
  );
  return await result.data;
}
