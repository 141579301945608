import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateInvoicePositionDto = {
  id: number;
  active: boolean;

  position: number;
  type: string;

  worker?: string;
  source: string;
  target: string;

  unitType: string;
  unit: number;

  positionPrice: number;
};

export const ENDPOINT = "/invoice/position/update";

export default async function UpdateJobCommand(
  dto: UpdateInvoicePositionDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
