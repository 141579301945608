import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { BuildDetails } from "@/models/Build";

export const ENDPOINT = "/";

export default async function BuildDetailsQuery(): Promise<BuildDetails> {
  const result = await TranslateClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT).toString()
  );
  return result.data;
}
