import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteDisputeDto = {
  disputeId: number;
};

export const ENDPOINT = "/dispute/delete";

export default async function UpdateDisputeCommand(
  dto: DeleteDisputeDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
