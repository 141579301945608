import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Affiliation } from "@/models/Affiliation";

export const ENDPOINT = (affiliationId: number): string => `/affiliations/${affiliationId}`;

export default async function AffiliationQuery(affiliationId: number): Promise<Affiliation> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(affiliationId)).toString()
  );
  return result.data;
}
