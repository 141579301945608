import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateJobMessageDto = {
  messageId: number;
  content: string;
  global: boolean;
};

export const ENDPOINT = "/job/update-message";

export default async function AddJobCommand(
  dto: UpdateJobMessageDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
