export type Colors = {
  PRIMARY: string;
  //PRIMARY_LIGHT: string,
  PRIMARY_LIGHT: string;
  PRIMARY_DARK: string;
  SECONDARY: string;

  // Auch in App.vue Style ersetzen
  ACCENT: string;

  // Jobs states
  OPEN: string;
  RETURNED: string;
  INQUIRED: string;
  DUE: string;
};

export default {
  PRIMARY: "#001436",
  //PRIMARY_LIGHT: '#2e3960',
  PRIMARY_LIGHT: "#b3b2ff",
  PRIMARY_DARK: "#000012",
  SECONDARY: "#C0BF97",

  // Auch in App.vue Style ersetzen
  ACCENT: "#8083e1",

  // Jobs states
  OPEN: "#229fe1",
  RETURNED: "#60aa47",
  INQUIRED: "#f7c449",
  DUE: "#c40040",
} as Colors;
