


































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppDropzone from "@/components/common/AppDropzone.vue";
import AppButton from "@/components/common/AppButton.vue";
import ManagerClient from "@/client/manager";
import { JobFile, JobFiles } from "@/models/Job";
import { AddInputDto } from "@/client/manager/AddInputCommand";
import { downloadJobFile, DownloadJobType } from "@/client/client";
import AppDownload from "@/components/common/AppDownload.vue";
import { AddResultDto } from "@/client/manager/AddResultCommand";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import CopyFilesDialog from "@/components/manager/CopyFilesDialog.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "JobFilesCard",
  props: ["job"],
  components: {
    CopyFilesDialog,
    AppConfirmDialog,
    AppDownload,
    AppCard,
    AppButton,
    AppDropzone,
  },
  data() {
    return {
      download: downloadJobFile,
      files: {
        input: [] as JobFile[],
        result: [] as JobFile[],
      } as JobFiles,
      inputType: DownloadJobType.INPUT,
      resultType: DownloadJobType.RESULT,
    };
  },
  methods: {
    async loadFiles(): Promise<void> {
      this.files = await ManagerClient.JobFilesQuery(this.job.id);
    },
    selectJob(event: number): void {
      this.$emit("selectJob", event);
    },
    unselectJob(): void {
      this.$emit("unselectJob");
    },
    async inputFileSelected(fileList: FileList): Promise<void> {
      const file = fileList.item(0);
      if (file) {
        await this.uploadInput(file);
        this.selectJob(this.job.id);
      }
    },
    async resultFileSelected(fileList: FileList): Promise<void> {
      const file = fileList.item(0);
      if (file) {
        await this.uploadResult(file);
        this.selectJob(this.job.id);
      }
    },
    async uploadInput(file: File): Promise<void> {
      await ManagerClient.AddInputCommand({
        jobId: this.job.id,
        file,
      } as AddInputDto);
    },
    async uploadResult(file: File): Promise<void> {
      await ManagerClient.AddResultCommand({
        jobId: this.job.id,
        file,
      } as AddResultDto);
    },
  },
  watch: {
    job: {
      immediate: true,
      async handler() {
        await this.loadFiles();
      },
    },
  },
});
