import { Store } from "vuex";
import { RootState } from "@/store/types";
import { Actionable } from "@/models/Action";
import VueI18n from "vue-i18n";
import { CHANGE_LANGUAGE } from "@/store/actions";

const mainColor = "#ff8080";
const sideColor = "#ffccdd";
const dark = false;
export const adminScheme = { mainColor, sideColor, dark };

export default {
  methods: {
    async switchLang(this: { $store: Store<RootState> }): Promise<void> {
      await this.$store.dispatch(CHANGE_LANGUAGE);
    },
  },
  computed: {
    currentLanguage(this: { $i18n: VueI18n }): string {
      return this.$i18n.locale;
    },
    timezone(): string {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    mainColor(this: { $store: Store<RootState> }): string {
      return this.$store.state.colorScheme.mainColor;
    },
    sideColor(this: { $store: Store<RootState> }): string {
      return this.$store.state.colorScheme.sideColor;
    },
    dark(this: { $store: Store<RootState> }): boolean {
      return this.$store.state.colorScheme.dark;
    },
    userId(this: { $store: Store<RootState> }): number {
      return this.$store.state.user?.id || -1;
    },
    username(this: { $store: Store<RootState> }): string {
      return this.$store.state.user?.login || "";
    },
    adminId(this: { $store: Store<RootState> }): number {
      return this.$store.state.user?.role.adminId || -1;
    },
    root(this: { $store: Store<RootState> }): Actionable {
      return {
        actions: this.$store.state.root,
      };
    },
  },
};
