import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { EffortFactors } from "@/models/EffortFactors";

export const ENDPOINT = (): string => `/data/effort-factors-select`;

export default async function selectEffortFactorsQuery(): Promise<
  EffortFactors[]
> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT()).toString()
  );
  return await result.data;
}
