import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateAddressDto = {
  id: number;
  name: string;
  additional?: string;
  street: string;
  zip: string;
  city: string;
  country: string;
};

export const ENDPOINT = "/address/update";

export default async function UpdateAddressCommand(
  dto: UpdateAddressDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
