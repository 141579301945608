











































import Vue from "@/translate";
import ActionableMixin from "@/mixins/actionable";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";

export default Vue.extend(ActionableMixin).extend({
  name: "AppDialog",
  props: {
    ignoreDark: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AppButton,
    AppCard,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    showDialog(): void {
      this.dialog = true;
      this.$emit("openDialog");
    },
    closeDialog(): void {
      this.dialog = false;
      this.$emit("closeDialog");
    },
    performAction(): void {
      this.dialog = false;
      this.$emit("performAction");
    },
  },
});
