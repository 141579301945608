














import Vue from "@/translate";
import AppCard from "@/components/common/AppCard.vue";
import AppMain from "@/components/common/AppMain.vue";

export default Vue.extend({
  name: "SimpleLayout",
  props: {
    titleLocale: {
      type: String,
    },
    back: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppCard,
    AppMain,
  },
});
