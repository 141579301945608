





export default {
  name: "app",
  computed: {
    isManager(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$route.path.startsWith("/manager");
    },
    isWorker(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$route.path.startsWith("/worker");
    },
  },
};
