import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Job } from "@/models/Job";

export const ENDPOINT = (affiliationId: number): string =>
  `/affiliations/${affiliationId}/unassigned-jobs`;

export default async function unassignedAffiliationJobsQuery(affiliationId: number): Promise<Job[]> {
  const builder = UrlBuilder.mq(ENDPOINT(affiliationId));
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
