


























































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppJobStateSelect from "@/components/common/AppJobStateSelect.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppTimeSelect from "@/components/common/AppTimeSelect.vue";
import ManagerClient from "@/client/manager";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import { PropType } from "vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import DatesMixin from "@/mixins/dates";
import { SHOW_ERROR } from "@/store/actions";
import { Receipt } from "@/models/Affiliation";
import AppCheckbox from "@/components/common/AppCheckbox.vue";

export default Vue.extend(ManagerMixin)
  .extend(DatesMixin)
  .extend({
    name: "ReceiptDetailsCard",
    props: {
      receipt: {
        type: Object as PropType<Receipt>,
        default: () => {
          return {} as Receipt;
        },
      },
      dense: {
        type: Boolean,
        default: false,
      },
      externalLoading: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      AppCheckbox,
      AppButton,
      AppCard,
      AppGrid,
      AppDateSelect,
      AppTimeSelect,
      AppTextField,
      AppCustomerSelect,
      AppJobStateSelect,
      AppConfirmDialog,
    },
    data() {
      return {};
    },
    computed: {
      hasAddress(): boolean {
        return (
          this.receipt.address !== undefined && this.receipt?.address !== null
        );
      },
    },
    methods: {
      async saveReceipt(): Promise<void> {
        try {
          await ManagerClient.UpdateReceiptCommand({
            receiptId: this.receipt?.id || 0,
            receiptNumber: this.receipt?.receiptNumber || "",
            receiptDate: this.receipt?.receiptDate || "",
          });
          this.$emit("receiptChanged");
        } catch (e: any) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Bestellung nicht ändern"
          );
        }
      },
      async deleteReceipt(): Promise<void> {
        try {
          await ManagerClient.DeleteReceiptCommand({
            id: this.receipt.id,
          });
          await this.$router.push({ name: "MReceiptList" });
        } catch (e: any) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Lieferantenrechnung nicht löschen"
          );
        }
      },
      async confirmReceipt(): Promise<void> {
        try {
          await ManagerClient.ConfirmReceiptCommand({
            receiptId: this.receipt.id,
          });
          this.$emit("receiptChanged");
        } catch (e: any) {
          await this.$store.dispatch(
            SHOW_ERROR,
            "Konnte Lieferantenrechnung nicht abschließen"
          );
        }
      },
    },
  });
