






import Vue from "@/translate";

export default Vue.extend({
  name: "AppRow",
  props: {
    first: {
      type: Boolean,
      default: false,
    },
  },
});
