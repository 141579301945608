




































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import ManagerClient from "@/client/manager";
import { CreateProjectDto } from "@/client/manager/CreateProjectCommand";
import { SelectCustomer } from "@/models/Customer";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppTextArea from "@/components/common/AppTextArea.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MProjectCreate",
  data() {
    return {
      selectCustomer: [] as SelectCustomer[],
      project: {
        referenceNumber: "",
        customerNumber: "",
        description: "",
      } as CreateProjectDto,
    };
  },
  components: {
    AppTextArea,
    AppTextField,
    AppCard,
    AppButton,
    AppCustomerSelect,
  },
  async created() {
    this.selectCustomer = await ManagerClient.SelectCustomerQuery();
    this.project.referenceNumber = new Date().getTime().toString();
  },
  methods: {
    async createProject(): Promise<void> {
      const project = {
        referenceNumber: this.project.referenceNumber,
        customerNumber: this.project.customerNumber,
        description: this.project.description,
        representativeId:
          this.project.representativeId != -1
            ? this.project.representativeId
            : null,
      } as CreateProjectDto;
      try {
        const id = await ManagerClient.CreateProjectCommand(project);
        await this.$router.push({
          name: "MProjectDetail",
          params: { projectId: id },
        });
      } catch (e: any) {
        // TODO: handle error
      }
    },
  },
});
