import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateReceiptDto = {
  affiliationId: number;
  receiptNumber: string;
  receiptDate: string;
};

export const ENDPOINT = "/affiliations/create-receipt";

export default async function CreateReceiptCommand(
  dto: CreateReceiptDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return result.data.id;
}
