



















































































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppButton from "@/components/common/AppButton.vue";
import { EdiInvoice, EdiPreview, EdiProject } from "@/models/Edi";
import AppFileSize from "@/components/common/AppFileSize.vue";
import AppDateLabel from "@/components/common/AppDateLabel.vue";
import AppToggle from "@/components/common/AppToggle.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import AppLabeledData from "@/components/common/AppLabeledData.vue";
import { PropType } from "vue";
import ManagerClient from "@/client/manager";
import AppTextField from "@/components/common/AppTextField.vue";
import { TableColData } from "@/models/UI";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppRadioButton from "@/components/common/AppRadioButton.vue";
import { SHOW_ERROR } from "@/store/actions";

export default Vue.extend(ManagerMixin).extend({
  name: "EdiProjectCard",
  props: {
    filename: {
      type: String,
      required: true,
    },
    preview: {
      type: Object as PropType<EdiPreview>,
    },
  },
  components: {
    AppRadioButton,
    AppCheckbox,
    AppTextField,
    AppLabeledData,
    AppConfirmDialog,
    AppToggle,
    AppDateLabel,
    AppFileSize,
    AppCard,
    AppIcon,
    AppButton,
  },
  data: function () {
    return {
      searchString: "",
      projects: [] as EdiProject[],
      project: 0,
      invoices: [] as EdiInvoice[],
      invoice: 0,
    };
  },
  computed: {
    projectHeaders(): TableColData[] {
      return [
        {
          text: "#",
          value: "id",
          align: "left",
        },
        {
          text: this.$t("edi.project.description") as string,
          value: "description",
          align: "left",
        },
        {
          text: "",
          value: "description",
          align: "left",
          sortable: false,
        },
        {
          text: "",
          value: "description",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("edi.project.representative") as string,
          value: "representative",
          align: "left",
        },
        {
          text: this.$t("edi.project.ordered") as string,
          value: "ordered",
          align: "left",
        },
        {
          text: this.$t("edi.preview.selection") as string,
          value: "",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("edi.actions") as string,
          value: "",
          align: "left",
          sortable: false,
        },
      ];
    },
    orderHeaders(): TableColData[] {
      return [
        {
          text: "#",
          value: "id",
          align: "left",
        },
        {
          text: this.$t("edi.invoice.invoiceNumber") as string,
          value: "invoiceNumber",
          align: "left",
        },
        {
          text: this.$t("edi.invoice.projectRef") as string,
          value: "projectRef",
          align: "left",
        },
        {
          text: "",
          value: "description",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("edi.preview.project") as string,
          value: "representative",
          align: "left",
        },
        {
          text: this.$t("edi.invoice.charged") as string,
          value: "charged",
          align: "left",
        },
        {
          text: this.$t("edi.preview.selection") as string,
          value: "",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("edi.actions") as string,
          value: "",
          align: "left",
          sortable: false,
        },
      ];
    },
    assignable(): boolean {
      if (this.isORDERS) {
        return this.project > 0;
      } else if (this.isORDCHG) {
        return this.invoice > 0;
      } else {
        return false;
      }
    },
    foundProjects(): boolean {
      return this.projects.length > 0;
    },
    foundInvoices(): boolean {
      return this.invoices.length > 0;
    },
    isORDERS(): boolean {
      return this.preview.type === "ORDERS";
    },
    isORDCHG(): boolean {
      return this.preview.type === "ORDCHG";
    },
  },
  methods: {
    typeLabel(type: string): string {
      return this.$t("common.jobTypes." + type) as string;
    },
    unitLabel(type: string): string {
      return this.$t("common.orderUnits." + type) as string;
    },
    sourceLabel(source: string): string {
      return this.$t("common.languages." + source?.replace("-", "_")) as string;
    },
    targetLabel(target: string): string {
      return this.$t("common.languages." + target?.replace("-", "_")) as string;
    },
    async search(): Promise<void> {
      const result = await ManagerClient.EDISearchQuery(
        this.searchString,
        this.preview?.docId
      );
      this.searchString = result.searchString;
      this.projects = result.projects;
      this.invoices = result.invoices;
    },
    async assign(): Promise<void> {
      try {
        const id = await ManagerClient.EDIAssignCommand({
          filename: this.filename,
          projectId: this.project,
          invoiceId: this.invoice,
        });
        await this.$router.push({
          name: "MInvoiceDetail",
          params: { invoiceId: id },
        });
      } catch (e: any) {
        await this.$store.dispatch(SHOW_ERROR, e.message);
      }
    },
  },
  watch: {
    preview: {
      async handler(val) {
        const search = val.searchStrings;
        if (search && search.length > 0) {
          this.searchString = search[0];
        } else {
          this.searchString = "";
        }
        this.search();
      },
      immediate: true,
    },
  },
});
