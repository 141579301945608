












































































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppJobStateSelect from "@/components/common/AppJobStateSelect.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppTimeSelect from "@/components/common/AppTimeSelect.vue";
import { SelectCustomer } from "@/models/Customer";
import { Project } from "@/models/Project";
import ManagerClient from "@/client/manager";
import { UpdateProjectDto } from "@/client/manager/UpdateProjectCommand";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import { PropType } from "vue";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import DatesMixin from "@/mixins/dates";
import UpdateCommentDialog from "@/components/manager/UpdateCommentDialog.vue";
import AppSeparator from "@/components/common/AppSeparator.vue";
import AppRow from "@/components/common/AppRow.vue";
import AppCol from "@/components/common/AppCol.vue";

export default Vue.extend(ManagerMixin)
  .extend(DatesMixin)
  .extend({
    name: "ProjectDetails",
    props: {
      project: {
        type: Object as PropType<Project>,
        default: () => {
          return {} as Project;
        },
      },
      externalLoading: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      AppCol,
      AppRow,
      AppSeparator,
      UpdateCommentDialog,
      AppTextArea,
      AppButton,
      AppCard,
      AppGrid,
      AppDateSelect,
      AppTimeSelect,
      AppTextField,
      AppCustomerSelect,
      AppJobStateSelect,
      AppConfirmDialog,
    },
    data() {
      return {
        selectCustomer: [] as SelectCustomer[],
      };
    },
    computed: {
      isLoading(): boolean {
        return this.$store.state.loading || this.externalLoading;
      },
    },
    async mounted() {
      this.selectCustomer = await ManagerClient.SelectCustomerQuery();
    },
    methods: {
      async saveProject(): Promise<void> {
        try {
          await ManagerClient.UpdateProjectCommand({
            id: this.project.id,
            referenceNumber: this.project.referenceNumber,
            customerNumber: this.project.customerNumber,
            description: this.project.description,
            representativeId: this.project.representativeId,
          } as UpdateProjectDto);
          this.$emit("projectChanged");
        } catch (e: any) {
          // TODO: handle error
        }
      },
      async abandonProject(): Promise<void> {
        await ManagerClient.AbandonProjectCommand({
          projectId: this.project.id,
        });
        await this.$router.push({ name: "MProjectList" });
      },
      async deleteProject(): Promise<void> {
        await ManagerClient.DeleteProjectCommand({
          projectId: this.project.id,
        });
        await this.$router.push({ name: "MProjectList" });
      },
      async createInvoice(): Promise<void> {
        const id = await ManagerClient.CreateInvoiceCommand({
          projectId: this.project.id,
          invoiceType: "INVOICE",
        });
        await this.$router.push({
          name: "MInvoiceDetail",
          params: { invoiceId: id },
        });
      },
      async createCredit(): Promise<void> {
        const id = await ManagerClient.CreateInvoiceCommand({
          projectId: this.project.id,
          invoiceType: "CREDIT",
        });
        await this.$router.push({
          name: "MInvoiceDetail",
          params: { invoiceId: id },
        });
      },
    },
  });
