















































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppButton from "@/components/common/AppButton.vue";
import { TableColData } from "@/models/UI";
import ManagerClient from "@/client/manager";
import { EdiFile } from "@/models/Edi";
import {
  performDownload,
  downloadEdiFile,
  DownloadEdiType,
} from "@/client/client";
import AppFileSize from "@/components/common/AppFileSize.vue";
import AppDateLabel from "@/components/common/AppDateLabel.vue";
import AppToggle from "@/components/common/AppToggle.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "EdiListCard",
  props: {},
  components: {
    AppConfirmDialog,
    AppToggle,
    AppDateLabel,
    AppFileSize,
    AppCard,
    AppIcon,
    AppButton,
  },
  data() {
    return {
      automaticImport: false,
      ediList: [] as EdiFile[],
    };
  },
  computed: {
    headers(): TableColData[] {
      return [
        {
          text: this.$t("edi.filename") as string,
          value: "filename",
          align: "left",
        },
        {
          text: this.$t("edi.size") as string,
          value: "size",
          align: "left",
        },
        {
          text: this.$t("edi.date") as string,
          value: "date",
          align: "left",
        },
        {
          text: this.$t("edi.automatic") as string,
          value: "",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("edi.actions") as string,
          value: "",
          align: "left",
          sortable: false,
        },
      ];
    },
  },
  async mounted() {
    await this.loadList();
  },
  methods: {
    async loadList(): Promise<void> {
      const result = await ManagerClient.EDIJobsQuery();
      this.ediList = result.files;
      this.automaticImport = result.automaticImport;
    },
    async changeSetting(value: boolean): Promise<void> {
      this.automaticImport = value;
      await ManagerClient.UpdateEdiSettingsCommand({
        automaticImport: this.automaticImport,
      });
      await this.loadList();
    },
    downloadEdiFileURL(filename: string): string {
      return downloadEdiFile(filename, DownloadEdiType.DATA);
    },
    downloadFile(filename: string): void {
      performDownload("edi", downloadEdiFile(filename, DownloadEdiType.DATA));
    },
    downloadSkipFile(filename: string): void {
      performDownload(
        "edit-skip",
        downloadEdiFile(filename, DownloadEdiType.SKIP)
      );
    },
    async retry(filename: string): Promise<void> {
      await ManagerClient.RetryEDIJobCommand({
        filename: filename,
      });
      await this.loadList();
    },
    async deleteFile(filename: string): Promise<void> {
      await ManagerClient.DeleteEDIJobCommand({
        filename: filename,
      });
      await this.loadList();
    },
  },
});
