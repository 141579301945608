

















import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import ManagerClient from "@/client/manager";
import { DIRTY_DATA } from "@/store/actions";
import { DirtyData } from "@/store/types";
import { Receipt } from "@/models/Affiliation";
import ReceiptDetailsCard from "@/components/manager/ReceiptDetailsCard.vue";
import ReceiptPositionsCard from "@/components/manager/ReceiptPositionsCard.vue";
import AddReceiptPositionCard from "@/components/manager/AddReceiptPositionCard.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MReceiptDetail",
  data() {
    return {
      loading: false,
      receipt: {} as Receipt,
    };
  },
  async mounted() {
    await this.loadReceipt();
  },
  methods: {
    async loadReceipt(): Promise<void> {
      this.loading = true;
      try {
        const receiptId = Number.parseInt(this.$route.params.receiptId);
        this.receipt = await ManagerClient.ReceiptQuery(receiptId);
        await this.$store.dispatch(DIRTY_DATA, {
          base: "receipt",
          data: this.receipt,
        } as DirtyData);
        this.receipt.positions.forEach((x) => {
          this.$store.dispatch(DIRTY_DATA, {
            base: "receipt.position",
            data: x,
          } as DirtyData);
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "$route.params.receiptId": {
      handler: function (receiptId: number): void {
        if (this.receipt.id !== receiptId) {
          this.loadReceipt();
        }
      },
      deep: true,
    },
  },
  components: {
    AddReceiptPositionCard,
    ReceiptPositionsCard,
    ReceiptDetailsCard,
  },
});
