

































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import AppButton from "@/components/common/AppButton.vue";
import AppJobTypeSelect from "@/components/common/AppJobTypeSelect.vue";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppDialog from "@/components/common/AppDialog.vue";
import AppRow from "@/components/common/AppRow.vue";
import AppCol from "@/components/common/AppCol.vue";
import AppSelect from "@/components/common/AppSelect.vue";
import { SelectData } from "@/models/UI";

export default Vue.extend(ManagerMixin).extend({
  name: "CopyFilesDialog",
  props: ["job"],
  components: {
    AppSelect,
    AppCol,
    AppRow,
    AppCheckbox,
    AppLangSelect,
    AppJobTypeSelect,
    AppButton,
    AppCard,
    AppDialog,
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          text: this.$t("common.files.input") as string,
          value: "INPUT",
        },
        {
          text: this.$t("common.files.result") as string,
          value: "RESULT",
        },
      ] as SelectData[],
      source: "",
      target: "",
    };
  },
  computed: {
    hasResult(): boolean {
      return this.job.result !== null && this.job.result !== "";
    },
  },
  methods: {
    async showDialog(): Promise<void> {
      this.dialog = true;
    },
    closeDialog(): void {
      this.dialog = false;
    },
    initDialog(): void {
      this.source = "";
      this.target = "";
    },
    async copyFiles(): Promise<void> {
      const jobId = await ManagerClient.CopyFilesCommand({
        jobId: this.job.id,
        source: this.source,
        target: this.target,
      });
      this.dialog = false;
      this.$emit("selectJob", jobId);
    },
  },
});
