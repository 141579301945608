import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Project } from "@/models/Project";
import { toLocal } from "@/utils/dates";

export const ENDPOINT = (projectId: number): string => `/project/${projectId}`;

export default async function managerProjectQuery(
  projectId: number
): Promise<Project> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(projectId)).toString()
  );
  const project = (await result.data) as Project;
  project.entered = toLocal(project.entered)!;
  project.due = toLocal(project.due)!;
  return project;
}
