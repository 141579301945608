







































































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AdminClient from "@/client/admin";
import AppTextField from "@/components/common/AppTextField.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import { UpdateCustomerDto } from "@/client/admin/UpdateCustomerCommand";
import { PropType } from "vue";
import { Affiliation } from "@/models/Affiliation";
import { UpdateAffiliationDto } from "@/client/admin/UpdateAffiliationCommand";
import CustomerDetailsCard from "@/components/admin/CustomerDetailsCard.vue";

export default Vue.extend(AdminMixin).extend({
  name: "AffiliationDetailsCard",
  props: {
    affiliation: {
      type: Object as PropType<Affiliation>,
    },
  },
  components: {
    CustomerDetailsCard,
    AppGrid,
    AppCheckbox,
    AppTextField,
    AppCard,
    AppButton,
  },
  methods: {
    async updateAffiliation(): Promise<void> {
      await AdminClient.UpdateAffiliationCommand({
        id: this.affiliation.id,
        name: this.affiliation.name,
        phone: this.affiliation.phone,
        phone2: this.affiliation.phone2,
        telefax: this.affiliation.telefax,
      } as UpdateAffiliationDto);
      this.$emit("affiliationChanged");
    },
    async deleteAffiliation(): Promise<void> {
      await AdminClient.DeleteAffiliationCommand({
        affiliationId: this.affiliation.id,
      });
      await this.$router.push({ name: "AAffiliationList" });
    },
    async disableAffiliation(): Promise<void> {
      await AdminClient.DisableAffiliationCommand({
        affiliationId: this.affiliation.id,
      });
      this.$emit("affiliationChanged");
    },
    async enableAffiliation(): Promise<void> {
      await AdminClient.EnableAffiliationCommand({
        affiliationId: this.affiliation.id,
      });
      this.$emit("affiliationChanged");
    },
  },
});
