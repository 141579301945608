import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type UpdateAffiliationDto = {
  id: number;
  name: string;
  phone: string;
  phone2: string;
  telefax: string;
};

export const ENDPOINT = "/affiliation/update";

export default async function UpdateAffiliationCommand(
  dto: UpdateAffiliationDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
