























import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import RepresentativeTable from "@/components/admin/RepresentativeTable.vue";
import { PropType } from "vue";
import { Customer } from "@/models/Customer";

export default Vue.extend(AdminMixin).extend({
  name: "RepresentativeListCard",
  props: {
    customer: {
      type: Object as PropType<Customer>,
    },
  },
  components: {
    AppGrid,
    AppCheckbox,
    AppTextField,
    AppCard,
    AppButton,
    RepresentativeTable,
  },
  data() {
    return {
      includeDisabled: false,
    };
  },
  computed: {},
  methods: {},
});
