import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Address } from "@/models/Common";

export const ENDPOINT = (addressId: number): string => `/address/${addressId}`;

export default async function addressQuery(addressId: number): Promise<Address> {
  const builder = UrlBuilder.aq(ENDPOINT(addressId));
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
