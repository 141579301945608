






















































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import ManagerClient from "@/client/manager";
import { Job, JobMessage } from "@/models/Job";
import { PropType } from "vue";
import * as date_fns from "date-fns";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import UpdateCommentDialog from "@/components/manager/UpdateCommentDialog.vue";
import CreateJobMessageDialog from "@/components/manager/CreateJobMessageDialog.vue";
import UpdateJobMessageDialog from "@/components/manager/UpdateJobMessageDialog.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "JobMessagesCard",
  props: {
    job: {
      type: Object as PropType<Job>,
    },
  },
  components: {
    UpdateJobMessageDialog,
    CreateJobMessageDialog,
    UpdateCommentDialog,
    AppConfirmDialog,
    AppCard,
    AppButton,
  },
  data() {
    return {
      messages: [] as JobMessage[],
    };
  },
  methods: {
    async loadMessages(): Promise<void> {
      this.messages = await ManagerClient.JobMessagesQuery(this.job.id);
    },
    unselectJob(): void {
      this.$emit("unselectJob");
    },
    async deleteMessage(messageId: number): Promise<void> {
      await ManagerClient.DeleteJobMessageCommand({
        messageId,
      });
      await this.loadMessages();
    },
    enteredLabel(message: JobMessage): string {
      return date_fns.format(
        new Date(message.entered),
        this.$store.state.datetimeFormat,
        {
          locale: this.$store.state.locale,
        }
      );
    },
  },
  watch: {
    job: {
      immediate: true,
      async handler() {
        await this.loadMessages();
      },
    },
  },
});
