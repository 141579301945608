

































































import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AdminClient from "@/client/admin";
import AppTextField from "@/components/common/AppTextField.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import { PropType } from "vue";
import { Address } from "@/models/Common";
import { UpdateAddressDto } from "@/client/admin/UpdateAddressCommand";

export default Vue.extend(AdminMixin).extend({
  name: "AddressDetailsCard",
  props: {
    address: {
      type: Object as PropType<Address>,
    },
    i18nTitle: {
      type: String,
      required: true,
    },
    enableDeleteAddress: {
      type: Boolean,
    },
  },
  components: {
    AppGrid,
    AppCheckbox,
    AppTextField,
    AppCard,
    AppButton,
  },
  computed: {
    hasAddress(): boolean {
      return this.address?.id !== undefined;
    },
  },
  methods: {
    async saveAddress(): Promise<void> {
      await AdminClient.UpdateAddressCommand({
        id: this.address.id,
        name: this.address.name,
        additional: this.address.additional,
        street: this.address.street,
        zip: this.address.zip,
        city: this.address.city,
        country: this.address.country,
      } as UpdateAddressDto);
      this.$emit("addressChanged");
    },
    async deleteAddress(): Promise<void> {
      await AdminClient.DeleteAddressCommand({
        addressId: this.address.id,
      });
      this.$emit("addressChanged");
    },
  },
});
