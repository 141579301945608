import { User } from "@/models/User";
import { Action } from "@/models/Action";
import { Idable } from "@/models/Idable";
import { BuildDetails } from "@/models/Build";

export enum ColorSchema {
  ADMIN = "ColorSchema.admin",
  MANAGER = "ColorSchema.manager",
  WORKER = "ColorSchema.worker",
}

export interface RootState {
  user?: User;
  token: string;
  root: Action[];
  newCounter: number;
  openCounter: number;
  allCounter: number;
  abandonedCounter: number;
  dirtyChecks: Record<string, string | number | boolean>;
  splashError: string;
  errors: Record<string, string>;
  loading: boolean;
  languageIndex: number;
  timeFormat: string;
  dateFormat: string;
  datetimeFormat: string;
  locale: Locale;
  colorScheme: ColorSchemeState;
  build: BuildDetails;
}

export interface ColorSchemeState {
  mainColor: string;
  sideColor: string;
  dark: boolean;
}

export interface AppError {
  errorKey: string;
  errorMessage: string;
  errors: Record<string, string>;
}

export interface DirtyField {
  key: string;
  value: string | number | boolean;
}

export interface DirtyData {
  base: string;
  data: Idable;
}
export interface DirtyArray {
  base: string;
  data: Idable[];
}
