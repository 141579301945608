import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AssignEDIJobDto = {
  filename: string;
  projectId?: number;
  invoiceId?: number;
};

export const ENDPOINT = "/edi/import-preview";

export default async function EDIAssignCommand(
  dto: AssignEDIJobDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return result.data.id;
}
