









import Vue from "@/translate";
import ColorsMixin from "@/mixins/color-scheme";

export default Vue.extend(ColorsMixin).extend({
  name: "AppFileSize",
  props: {
    size: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
    },
  },
  computed: {
    tooltip(): string {
      return this.size.toString() + " b";
    },
    short(): string {
      return this.humanFileSize(this.size);
    },
  },
  methods: {
    humanFileSize(size: number): string {
      const power = size <= 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      const calculated = (size / Math.pow(1024, power)).toFixed(2);
      return calculated + " " + ["B", "kB", "MB", "GB", "TB"][power];
    },
  },
});
