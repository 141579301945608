import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Job } from "@/models/Job";
import { toLocal } from "@/utils/dates";

export const ENDPOINT = (projectId: number): string =>
  `/project/${projectId}/jobs`;

export default async function JobsQuery(projectId: number): Promise<Job[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(projectId)).toString()
  );
  return result.data;
}
