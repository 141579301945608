import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteEDIJobDto = {
  filename: string;
};

export const ENDPOINT = "/edi/delete";

export default async function DeleteEDIJobCommand(
  dto: DeleteEDIJobDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
