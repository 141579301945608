import * as date_fns from "date-fns";
import { addMidnight, addMorning, mergeDate, mergeTime } from "@/utils/dates";

export default {
  props: {},
  computed: {
    timezone(): string {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
  },
  methods: {
    getDate(iso: string | undefined): string | undefined {
      if (iso) {
        return date_fns.format(new Date(iso), "yyyy-MM-dd");
      }
      return undefined;
    },
    getTime(iso: string | undefined): string | undefined {
      if (iso) {
        // :TODO: korrekte Locales noch auswerten
        return date_fns.format(new Date(iso), "HH:mm");
      }
      return undefined;
    },
    mergeDate(
      this: { timezone: string },
      iso: string | undefined,
      date: string | undefined
    ): string | undefined {
      return mergeDate(iso, date);
    },
    mergeTime(
      this: { timezone: string },
      date: string | undefined,
      time: string | undefined
    ): string | undefined {
      return mergeTime(date, time);
    },
    addMorning(
      this: { timezone: string },
      local: string | undefined
    ): string | undefined {
      return addMorning(local, this.timezone);
    },
    addMidnight(
      this: { timezone: string },
      local: string | undefined
    ): string | undefined {
      return addMidnight(local, this.timezone);
    },
  },
};
