import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { SelectCustomer } from "@/models/Customer";

export const ENDPOINT = (): string => `/data/customer-select`;

export default async function selectCustomerQuery(): Promise<SelectCustomer[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT()).toString()
  );
  return await result.data;
}
