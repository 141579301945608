


















































import Vue from "@/translate";
import AppButton from "@/components/common/AppButton.vue";
import ColorsMixin from "@/mixins/color-scheme";
import ActionableMixin from "@/mixins/actionable";
import { PropType } from "vue";
import { Dictionary } from "vue-router/types/router";
import { colorShade } from "@/utils/colors";

export default Vue.extend(ActionableMixin)
  .extend(ColorsMixin)
  .extend({
    name: "AppCard",
    props: {
      titleLocale: {
        type: String,
        required: true,
      },
      back: {
        type: [Boolean, String],
        default: false,
      },
      backParams: {
        type: Object as PropType<Dictionary<string>>,
        default: () => {
          return {};
        },
      },
      shade: {
        type: Number,
        default: 0,
      },
      empty: {
        type: Boolean,
      },
      attachTop: {
        type: Boolean,
      },
    },
    computed: {
      realColor(this: { mainColor: string; shade: number }): string {
        return colorShade(this.mainColor, this.shade);
      },
      loading(): boolean {
        return this.$store.state.loading;
      },
      isBackEnabled(): boolean {
        return this.back !== false;
      },
    },
    methods: {
      performBack(): void {
        if (this.back === true) {
          this.$router.back();
        } else {
          this.$router.push({
            name: this.back as string,
            params: this.backParams,
          });
        }
      },
    },
    components: {
      AppButton,
    },
  });
