



































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppWorkerSelect from "@/components/common/AppWorkerSelect.vue";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import AppRadioButton from "@/components/common/AppRadioButton.vue";
import AppSelect from "@/components/common/AppSelect.vue";
import { Affiliation } from "@/models/Affiliation";
import ManagerClient from "@/client/manager";
import { Filter, SelectData } from "@/models/UI";

export default Vue.extend(ManagerMixin).extend({
  name: "ReceiptFilterCard",
  components: {
    AppSelect,
    AppRadioButton,
    AppCustomerSelect,
    AppWorkerSelect,
    AppCheckbox,
    AppGrid,
    AppButton,
    AppCard,
    AppTextField,
    AppLangSelect,
  },
  data() {
    return {
      include: "UNCHECKED_ONLY",
      affiliations: [] as Affiliation[],
      affiliation: undefined as number | undefined,
      filters: [] as Filter[],
    };
  },
  computed: {
    hasFilter(): boolean {
      return (
        this.filters.length > 0 &&
        (this.include !== "UNCHECKED_ONLY" || this.affiliation !== undefined)
      );
    },
    affiliationItems(): SelectData[] {
      return this.affiliations.map((x) => {
        return {
          value: x.id,
          text: x.name,
        };
      });
    },
    selectedAffiliation(): Affiliation | undefined {
      return this.affiliations.find((x) => x.id === this.affiliation);
    },
  },
  mounted() {
    this.loadAffiliations();
  },
  methods: {
    clearFilter(): void {
      this.include = "UNCHECKED_ONLY";
      this.affiliation = undefined;
      this.filterChanged();
    },
    filterChanged(): void {
      this.filters = [];
      this.filters.push({
        name: "include",
        value: this.include,
      });
      if (this.affiliation) {
        this.filters.push({
          name: "affiliation",
          value: this.affiliation,
        });
      }
      this.$emit("affiliationSelected", this.selectedAffiliation);
      this.$emit("filterChanged", this.filters);
    },
    async loadAffiliations(): Promise<void> {
      this.affiliations = await ManagerClient.SelectAffiliationsQuery();
    },
  },
});
