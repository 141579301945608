import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Action } from "@/models/Action";

export const ENDPOINT = "/";

export default async function RootQuery(): Promise<Action[]> {
  const result = await TranslateClient.get(
    UrlBuilder.m(ENDPOINT).toString()
  );
  return result.data;
}
