import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Vacation } from "@/models/Vacation";
import { toLocal } from "@/utils/dates";

export const ENDPOINT = (): string => `/vacation/`;

export default async function VacationsQuery(): Promise<Vacation[]> {
  const result = await TranslateClient.get(
    UrlBuilder.wq(ENDPOINT()).toString()
  );
  const vacations = (await result.data) as Vacation[];
  vacations.forEach((x) => {
    x.start = toLocal(x.start)!;
    x.stop = toLocal(x.stop)!;
  });
  return vacations;
}
