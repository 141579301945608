import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { JobMessage } from "@/models/Job";

export const ENDPOINT = (jobId: number): string => `/job/${jobId}/messages`;

export default async function JobMessagesQuery(
  jobId: number
): Promise<JobMessage[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(jobId)).toString()
  );
  return result.data;
}
