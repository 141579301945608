import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeliverJobDto = {
  jobId: number;
  file: File;
  requirementsChecked: string[];
};

export const ENDPOINT = "/job/deliver";

export default async function DeliverJobCommand(
  dto: DeliverJobDto
): Promise<void> {
  const formData = new FormData();
  formData.append("jobId", dto.jobId.toString());
  formData.append("requirementsChecked", dto.requirementsChecked.toString());
  formData.append("file", dto.file);
  await TranslateClient.post(UrlBuilder.wc(ENDPOINT).toString(), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
