
























































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import AppDateSelect from "@/components/common/AppDateSelect.vue";
import AppTimeSelect from "@/components/common/AppTimeSelect.vue";
import AppSelect from "@/components/common/AppSelect.vue";
import { DisputeTypesSelect } from "@/basic/disputetypes";
import AppTextArea from "@/components/common/AppTextArea.vue";
import AppButton from "@/components/common/AppButton.vue";
import ManagerClient from "@/client/manager";
import { UpdateDisputeDto } from "@/client/manager/UpdateDisputeCommand";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppConfirmDialog from "@/components/common/AppConfirmDialog.vue";
import CloseDisputeDialog from "@/components/manager/CloseDisputeDialog.vue";

export default Vue.extend(ManagerMixin).extend({
  props: ["dispute"],
  data() {
    return {
      items: DisputeTypesSelect,
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.loading;
    },
  },
  methods: {
    async saveDispute(): Promise<void> {
      try {
        await ManagerClient.UpdateDisputeCommand({
          id: this.dispute.id,
          entered: this.dispute.entered,
          description: this.dispute.description,

          disputeType: this.dispute.disputeType,
          cause: this.dispute.cause,

          shortMeasure: this.dispute.shortMeasure,
          longMeasure: this.dispute.longMeasure,

          measureDone: this.dispute.measureDone,
          justlyDisputed: this.dispute.justlyDisputed,
        } as UpdateDisputeDto);
        this.$emit("disputeChanged");
      } catch (e: any) {
        // TODO: handle error
      }
    },
    async deleteDispute(): Promise<void> {
      try {
        await ManagerClient.DeleteDisputeCommand({
          disputeId: this.dispute.id,
        });
        await this.$router.push({
          name: "MProjectDetail",
          params: {
            projectId: this.dispute.projectId,
            jobId: this.dispute.jobId,
          },
        });
      } catch (e: any) {
        // TODO: handle error
        console.log(e);
      }
    },
  },
  components: {
    CloseDisputeDialog,
    AppConfirmDialog,
    AppCheckbox,
    AppButton,
    AppTextArea,
    AppSelect,
    AppTimeSelect,
    AppDateSelect,
    AppGrid,
    AppCard,
  },
});
