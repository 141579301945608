










































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import ManagerClient from "@/client/manager";
import AppButton from "@/components/common/AppButton.vue";
import AppJobTypeSelect from "@/components/common/AppJobTypeSelect.vue";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppDialog from "@/components/common/AppDialog.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "DuplicateJobDialog",
  props: ["project", "job"],
  components: {
    AppCheckbox,
    AppLangSelect,
    AppJobTypeSelect,
    AppButton,
    AppCard,
    AppDialog,
  },
  data() {
    return {
      dialog: false,
      targets: [] as string[],
      target: undefined as string | undefined,
      useInput: false,
    };
  },
  computed: {
    hasInput(): boolean {
      return this.job.input !== null && this.job.input !== "";
    },
  },
  methods: {
    addTarget(event: string): void {
      this.targets.push(event);
      this.$nextTick(() => {
        this.target = undefined;
      });
    },
    removeTarget(index: number): void {
      this.targets.splice(index, 1);
    },
    reset(): void {
      this.$nextTick(() => {
        this.targets = [];
        this.target = undefined;
      });
    },
    async showDialog(): Promise<void> {
      this.dialog = true;
      this.reset();
    },
    closeDialog(): void {
      this.dialog = false;
      this.reset();
    },
    async duplicateJob(): Promise<void> {
      try {
        const jobId = await ManagerClient.DuplicateJobCommand({
          projectId: this.project.id,
          jobId: this.job.id,
          targets: this.targets,
          useInput: this.useInput,
        });
        this.dialog = false;
        this.$emit("projectChanged");
        this.$emit("selectJob", jobId);
      } catch (e) {
        console.log(e);
      }
    },
  },
});
