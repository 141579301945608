
















import Vue from "@/translate";
import ManagerInvoiceTable from "@/components/manager/ManagerInvoiceTable.vue";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import InvoiceFilterCard from "@/components/manager/InvoiceFilterCard.vue";
import { Filter } from "@/models/UI";
import MultiProductionDialog from "@/components/manager/BatchConfirmDialog.vue";
import BatchConfirmDialog from "@/components/manager/BatchConfirmDialog.vue";

export default Vue.extend(ManagerMixin).extend({
  name: "MInvoiceProduction",
  data() {
    return {
      filters: [
        {
          name: "state",
          value: "CHARGEABLE",
        },
      ] as Filter[],
      selection: [] as number[],
    };
  },
  components: {
    BatchConfirmDialog,
    MultiProductionDialog,
    AppCard,
    ManagerInvoiceTable,
    InvoiceFilterCard,
  },
  methods: {
    filterChanged(event: Filter[]): void {
      this.filters = event;
    },
    changeSelection(event: number[]): void {
      this.selection = event;
    },
  },
});
