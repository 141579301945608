




























import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import JobMixin from "@/mixins/job";
import AppCard from "@/components/common/AppCard.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppButton from "@/components/common/AppButton.vue";
import { Job } from "@/models/Job";
import { PropType } from "vue";
import { Project } from "@/models/Project";
import ManagerClient from "@/client/manager";
import JobTable from "@/components/manager/JobTable.vue";

export default Vue.extend(ManagerMixin)
  .extend(JobMixin)
  .extend({
    name: "JobListCard",
    props: {
      project: {
        type: Object as PropType<Project>,
      },
      jobs: {
        type: Array as PropType<Job[]>,
      },
      selected: {
        type: Object as PropType<Job>,
      },
    },
    components: {
      JobTable,
      AppCard,
      AppIcon,
      AppButton,
    },
    data() {
      return {};
    },
    computed: {},
    methods: {
      async selectJob(job: Job): Promise<void> {
        this.$emit("selectJob", job.id);
      },
      unselectJob(): void {
        this.$emit("unselectJob");
      },
      async addJob(): Promise<void> {
        const jobId = await ManagerClient.AddJobCommand({
          projectId: this.project.id,
        });
        this.$emit("projectChanged");
        this.$emit("selectJob", jobId);
      },
    },
    created() {
      const jobId = this.$route.params.jobId;
      if (jobId) {
        const current = this.jobs.find((x) => x.id === Number.parseInt(jobId));
        if (current) {
          this.selectJob(current);
        }
      }
    },
  });
