import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { EdiSettings } from "@/models/Edi";

export const ENDPOINT = (): string => `/edi/`;

export default async function EDIJobsQuery(): Promise<EdiSettings> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT()).toString()
  );
  return result.data;
}
