import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type DeleteRepresentativeDto = {
  representativeId: number;
};

export const ENDPOINT = "/representative/delete";

export default async function DeleteRepresentativeCommand(
  dto: DeleteRepresentativeDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.ac(ENDPOINT).toString(), dto);
}
