import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AddCommentDto = {
  projectId: number;
  content: string;
};

export const ENDPOINT = "/project/add-comment";

export default async function AddCommentCommand(
  dto: AddCommentDto
): Promise<void> {
  await TranslateClient.post(UrlBuilder.mc(ENDPOINT).toString(), dto);
}
