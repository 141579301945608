import { Job } from "@/models/Job";
import { Project } from "@/models/Project";
import * as date_fns from "date-fns";

export default {
  methods: {
    jobTypeLabel(this: Vue, job: Job): string {
      return this.$t("common.jobTypes." + job.jobType) as string;
    },
    jobStateLabel(this: Vue, job: Job): string {
      return this.$t("common.jobStates." + job.jobState) as string;
    },
    sourceLabel(this: Vue, job: Job): string {
      return this.$t("common.languages." + job.source) as string;
    },
    targetLabel(this: Vue, job: Job): string {
      return this.$t("common.languages." + job.target) as string;
    },
    effortLabel(this: Vue, job: Job): string {
      return (job.effort +
        " " +
        this.$t("common.orderUnits." + job.orderUnit)) as string;
    },
    dueLabel(this: any, job: Job): string {
      return this.dateLabel(job.due);
    },
    enteredLabel(this: any, job: Project): string {
      return this.dateLabel(job.entered);
    },
    dateLabel(this: Vue, date: string): string {
      return date_fns
        .format(new Date(date), this.$store.state.datetimeFormat, {
          locale: this.$store.state.locale,
        })
        .replaceAll(" ", "\u00A0");
    },
  },
};
