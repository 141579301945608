import Vue from "vue";
import Vuetify from "vuetify";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
});

Vue.config.productionTip = false;
Vue.use(Vuetify);

const app = new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App),
});
app.$mount("#app");

export default app;
