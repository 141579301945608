


















































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import InvoiceMixin from "@/mixins/invoice";
import ManagerClient from "@/client/manager";
import { SHOW_ERROR } from "@/store/actions";
import { Filter, TableColData } from "@/models/UI";
import { PropType } from "vue";
import { Invoice } from "@/models/Invoice";
import { AppError } from "@/store/types";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppDetailsButton from "@/components/common/AppDetailsButton.vue";

export default Vue.extend(ManagerMixin)
  .extend(InvoiceMixin)
  .extend({
    name: "ManagerInvoiceTable",
    components: { AppDetailsButton, AppCheckbox },
    props: {
      filters: {
        type: Array as PropType<Filter[]>,
        default: () => [],
      },
      showSelect: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selected: [] as Invoice[],
        invoices: [] as Invoice[],
      };
    },
    mounted() {
      this.loadInvoices();
    },
    computed: {
      loading(): boolean {
        return this.$store.state.loading;
      },
      headers(): TableColData[] {
        return [
          {
            text: this.$t("invoice.type") as string,
            value: "type",
            align: "left",
          },
          {
            text: this.$t("invoice.projectRef") as string,
            value: "projectRef",
            align: "left",
          },
          {
            text: this.$t("invoice.invoiceNumber") as string,
            value: "invoiceNumber",
            align: "left",
          },
          {
            text: this.$t("invoice.receiptNumber") as string,
            value: "receiptNumber",
            align: "left",
          },
          {
            text: this.$t("invoice.state") as string,
            value: "chargeable",
            align: "left",
          },
          {
            text: this.$t("invoice.positions") as string,
            value: "positions",
            align: "left",
          },
          {
            text: this.$t("invoice.actions") as string,
            value: "actions",
            align: "left",
          },
        ];
      },
    },
    methods: {
      async loadInvoices(): Promise<void> {
        try {
          this.invoices = await ManagerClient.ManagerInvoicesQuery(
            this.filters
          );
        } catch (e) {
          await this.$store.dispatch(SHOW_ERROR, e as AppError);
        }
      },
    },
    watch: {
      filters: {
        immediate: true,
        handler() {
          this.loadInvoices();
        },
      },
      selected: {
        handler() {
          this.$emit(
            "selected",
            this.selected.map((x) => x.id)
          );
        },
      },
    },
  });
