import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type CreateCustomerDto = {
  name: string;
  noVAT: boolean;
  additional?: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  email?: string;
};

export const ENDPOINT = "/customer/create";

export default async function CreateCustomerCommand(
  dto: CreateCustomerDto
): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.ac(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
