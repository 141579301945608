import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { EmailSettings } from "@/models/EmailSettings";

export const ENDPOINT = (): string => `/email-settings/`;

export default async function emailSettingQuery(): Promise<EmailSettings> {
  const builder = UrlBuilder.aq(ENDPOINT());
  const result = await TranslateClient.get(builder.toString());
  return result.data;
}
