import Login from "@/client/auth/Login";
import UserByNameQuery from "@/client/auth/UserByNameQuery";
import UserByTokenQuery from "@/client/auth/UserByTokenQuery";

import UpdateWorkerCommand from "@/client/worker/UpdateWorkerCommand";
import WorkerQuery from "@/client/worker/WorkerQuery";
import WorkerInquiriesQuery from "@/client/worker/WorkerInquiriesQuery";
import WorkerArchiveQuery from "@/client/worker/WorkerArchiveQuery";
import WorkerJobsQuery from "@/client/worker/WorkerJobsQuery";
import WorkerJobRequirementsQuery from "@/client/worker/JobRequirementsQuery";
import WorkerJobQuery from "@/client/worker/WorkerJobQuery";
import JobMessagesQuery from "@/client/worker/JobMessagesQuery";
import VacationsQuery from "@/client/worker/VacationsQuery";

import AcceptJobCommand from "@/client/worker/AcceptJobCommand";
import DeclineJobCommand from "@/client/worker/DeclineJobCommand";
import DeliverJobCommand from "@/client/worker/DeliverJobCommand";
import CreateVacationCommand from "@/client/worker/CreateVacationCommand";
import UpdateVacationCommand from "@/client/worker/UpdateVacationCommand";
import DeleteVacationCommand from "@/client/worker/DeleteVacationCommand";

export default {
  // Auth
  Login,
  UserByNameQuery,
  UserByTokenQuery,

  // Command
  UpdateWorkerCommand,
  AcceptJobCommand,
  DeclineJobCommand,
  DeliverJobCommand,
  CreateVacationCommand,
  UpdateVacationCommand,
  DeleteVacationCommand,

  // Query
  WorkerQuery,
  WorkerInquiriesQuery,
  WorkerJobsQuery,
  WorkerJobQuery,
  WorkerArchiveQuery,
  WorkerJobRequirementsQuery,
  JobMessagesQuery,
  VacationsQuery,
};
