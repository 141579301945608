import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";

export type AddReceiptPositionDto = {
  receiptId: number;
  active: boolean;
  jobId?: number;

  position: number;
  type: string;

  project?: string;
  source: string;
  target: string;

  unitType: string;
  unit: number;

  positionPrice: number;
};

export const ENDPOINT = "/affiliations/add-receipt-position";

export default async function AddReceiptPositionCommand(dto: AddReceiptPositionDto): Promise<string> {
  const result = await TranslateClient.post(
    UrlBuilder.mc(ENDPOINT).toString(),
    dto
  );
  return await result.data.id;
}
