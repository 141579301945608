





















import Vue from "@/translate";
import AdminMixin from "@/mixins/admin";
import AppCard from "@/components/common/AppCard.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppGrid from "@/components/common/AppGrid.vue";
import { PropType } from "vue";
import { Customer, CustomerPrice } from "@/models/Customer";
import AdminClient from "@/client/admin";
import AppOrderUnitSelect from "@/components/common/AppOrderUnitSelect.vue";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppJobTypeSelect from "@/components/common/AppJobTypeSelect.vue";
import CustomerPriceTable from "@/components/admin/CustomerPriceTable.vue";
import CustomerPriceDialog from "@/components/admin/CustomerPriceDialog.vue";

export default Vue.extend(AdminMixin).extend({
  name: "CustomerPriceListCard",
  props: {
    customer: {
      type: Object as PropType<Customer>,
    },
  },
  components: {
    CustomerPriceDialog,
    CustomerPriceTable,
    AppJobTypeSelect,
    AppLangSelect,
    AppOrderUnitSelect,
    AppGrid,
    AppCheckbox,
    AppTextField,
    AppCard,
    AppButton,
  },
  data() {
    return {
      prices: [] as CustomerPrice[],
    };
  },
  methods: {
    async loadData(): Promise<void> {
      this.prices = await AdminClient.CustomerPriceListQuery(this.customer.id);
    },
    async createPrice(price: CustomerPrice): Promise<void> {
      await AdminClient.CreateCustomerPriceCommand(price);
      await this.loadData();
    },
  },
  watch: {
    customer: {
      handler(val) {
        if (val && val.id) {
          this.loadData();
        }
      },
    },
  },
});
