import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { JobLogMessage } from "@/models/Job";

export const ENDPOINT = (jobId: number): string => `/job/${jobId}/log`;

export default async function JobLogMessagesQuery(
  jobId: number
): Promise<JobLogMessage[]> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(jobId)).toString()
  );
  const data = result.data;
  data.forEach((x: JobLogMessage) => (x.parsedContent = JSON.parse(x.content)));
  return data;
}
