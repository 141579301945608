import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { Manager } from "@/models/Manager";

export const ENDPOINT = (managerId: number): string => `/settings/${managerId}`;

export default async function ManagerQuery(managerId: number): Promise<Manager> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(managerId)).toString()
  );
  return result.data;
}
