import { TranslateClient } from "@/client/client";
import UrlBuilder from "@/client/urlBuilder";
import { JobFiles } from "@/models/Job";

export const ENDPOINT = (jobId: number): string => `/job/${jobId}/files`;

export default async function JobFilesQuery(jobId: number): Promise<JobFiles> {
  const result = await TranslateClient.get(
    UrlBuilder.mq(ENDPOINT(jobId)).toString()
  );
  return result.data;
}
