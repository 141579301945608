






import Vue from "@/translate";
import WorkerMixin from "@/mixins/worker";
import WorkerArchiveCard from "@/components/worker/WorkerArchiveCard.vue";

export default Vue.extend(WorkerMixin).extend({
  name: "WJobArchive",
  components: {
    WorkerArchiveCard,
  },
});
